'use strict';

var m = require('mithril');
var button = require('polythene/button/button');
var list = require('polythene/list/list');
var listTile = require('polythene/list-tile/list-tile');
var tour = require('../lib/tour.js');
var Cart = require('../models/cart.js');
var Subscription = require('../models/subscription.js');
var screen = require('../shared/screen.js');
var tabbedLayout = require('../layouts/tabbed.js');
var message = require('../components/message.js');
var fullMessage = require('../components/full-message.js');
var asyncButton = require('../components/async-button.js');

var Home = module.exports = screen.create({
  controller: function(args) {
    var ctrl = this;

    ctrl.hasSubscription = m.prop(null);
    if (ctrl.vmApp.userIsNoob()) {
      // Subscription.find(ctrl.vmApp.SUBSCRIPTION_PREFIX).then(function(subscription) {
      //   if (!subscription || !subscription.id()) {
      //     ctrl.hasSubscription(false);
      //   }
      //   else {
      //     ctrl.hasSubscription(true);
      //   }
      // });
    }

    tour.emitDelayed('home:first-run:show');
  },
  view: tabbedLayout(function(ctrl, args) {
    var vmApp = ctrl.vmApp;
    var content = [];
    var mightNeedSubscription = ctrl.vmApp.userIsNoob() && null === ctrl.hasSubscription();
    var needsSubscription = ctrl.vmApp.userIsNoob() && false === ctrl.hasSubscription();

    /*
    if (mightNeedSubscription) {
      content.push(m(message, {
        outline: true,
        content: [
          m('p', 'Please wait...'),
        ],
      }));
    }
    else if (needsSubscription) {
      content.push(m(message, {
        type: 'info',
        outline: true,
        content: [
          m('h5', 'Hey there!'),
          m('p', 'Welcome to Stampr Passenger!  Before you can get started, you\'ll need to visit the Settings page and choose either a Free or Paid subscription.'),
          m('p', m.trust('You can learn more about the various subscriptions by visiting the <a href="https://www.stam.pr/passenger#pricing" target="_blank">pricing page</a>.')),
          m('div', { style: 'text-align:right;' }, [
            m(button, {
              raised: true,
              label: 'Go to settings',
              url: {
                href: '/settings',
                config: m.route,
              },
            }),
          ]),
        ],
      }));
    }
    else*/ if (vmApp.isPickupActive()) {
      content.push(m(fullMessage, {
        class: 'embiggen',
        image: '/passenger-premier.svg',
        content: [
          m('b', 'Courier on the way!'),
          m('div.lead', 'There is a courier currently on their way to you.'),
          m('div', { style: 'text-align:right;margin-top:15px;' }, [
            m(button, {
              raised: true,
              label: 'Track Courier Arrival',
              url: {
                href: '/dispatched',
                config: m.route,
              },
            }),
          ]),
        ],
      }));
    }
    else {
      content.push(m(fullMessage, {
        class: 'embiggen',
        image: '/airplane-guy.svg',
        content: [
          m('b', 'Welcome!'),
          m('div.lead', 'Schedule a courier pickup to have one of our drivers dispatched to your location to get your stuff!'),
        ],
      }));
    }
    return m('div.pe-fit.layout.vertical', content);
  }),
});
