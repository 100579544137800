'use strict';

var m = require('mithril');
var async = require('async');
var auth0 = require('./lib/auth0.js');
var theme = require('./lib/theme.js');
var Login = require('./routes/login.js');

function StamprApp(target, config) {
  this.target = target;
  this.config = config;
  this.auth = null;
  this._initialized = false;
}

StamprApp.prototype.init = function(callback) {
  var _this = this;
  auth0.init(this.config.auth0, this.config.cognito, function(err, auth) {
    if (err) return callback(err);
    _this.auth = auth;
    _this.auth.client.on('ready', _this._onReady.bind(_this));
    _this.auth.client.on('authenticated', _this._onAuth.bind(_this));
    _this.auth.client.on('deauthenticated', _this._onDeauth.bind(_this));
    _this.auth.client.init();
    setTimeout(callback, 0);
  });
};

StamprApp.prototype._onReady = function() {
  global.logger.info('auth ready');
  this._initialized = true;
};

StamprApp.prototype._onAuth = function() {
  global.logger.info('authenticated');
  this.target.className = this.target.className + ' authenticated';
  this._setUserProfileInfo();
};

StamprApp.prototype._onDeauth = function(err) {
  global.logger.info('deauthenticated');
  if (err) {
    global.logger.warn('auth error', err);
    // this.auth.client.close();
  }
  this.target.className = this.target.className.replace(/\bauthenticated\b/g, ' ');
  this._clearUserProfileInfo();
  this._showPrompt();
};

StamprApp.prototype._setUserProfileInfo = function() {
  var auth = this.auth;
  Array.prototype.slice.call(this.target.querySelectorAll('.user-profile-info')).forEach(function(el) {
    var key = el.getAttribute('data-key');
    var target = el.getAttribute('data-target');
    var val = eval([
      '(function(profile, store) {',
      'return ' + key + ';',
      '}).call(null, auth.client.session.profile, auth.client.session.store)'
    ].join('\n'));
    switch (target) {
      case '.':
        el.innerHTML = val;
      break;
      default:
        el.setAttribute(target, val);
      break;
    }
  });
};

StamprApp.prototype._clearUserProfileInfo = function() {
  var auth = this.auth;
  Array.prototype.slice.call(this.target.querySelectorAll('.user-profile-info')).forEach(function(el) {
    var key = el.getAttribute('data-key');
    var target = el.getAttribute('data-target');
    var val = '';
    switch (target) {
      case '.':
        el.innerHTML = val;
      break;
      default:
        el.setAttribute(target, val);
      break;
    }
  });
};

StamprApp.prototype._showPrompt = function() {
  auth0.prompt(this.config.lock, function(err) {
    if (err) {
      global.logger.warn('Problem logging in', err);
      return;
    }
    global.logger.debug('auth0 login successful');
  });
};

module.exports = StamprApp;
