'use strict';

var m = require('mithril');
m.deferred.onerror = function(err) {
  global.logger.error('Unhandled error', err);
};

var promiseErrorHandler = function genericErrorHandler(fn) {
  return function(err) {
    global.logger.error(err);
    if (err.message && /missing\s+credentials\s+in\s+config/i.test(err.message)) { // if aws fails to auth
      global.StamprPassenger.auth.client.close();
    }
    if (!fn) {
      alert('An error occurred and you will be returned to the home page.  If this error persists, please try refreshing the page or emailing us at help@stam.pr for assistance.');
      if (m.route() != '/') m.route('/');
    }
    else {
      fn(err);
    }
  };
};

promiseErrorHandler.fatal = function(fn) {
  return function(err) {
    fn && fn.apply(this, arguments);
    global.StamprPassenger.auth.client.close();
    global.location.reload();
  };
};

module.exports = promiseErrorHandler;
