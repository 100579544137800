'use strict';

var m = require('mithril');
var dialog = require('polythene/dialog/dialog');
var headerPanel = require('polythene/header-panel/header-panel');
var button = require('polythene/button/button');
var iconButton = require('polythene/icon-button/icon-button');

var iconClear = require('mmsvg/google/msvg/content/clear');

var templates = {
  selectPayment: require('../dialogs/select-payment.js'),
  addPayment: require('../dialogs/add-payment.js'),
  editPickupAddress: require('../dialogs/edit-pickup-address.js'),
  confirmCancelPickup: require('../dialogs/confirm-cancel-pickup.js'),
  editShipment: require('../dialogs/edit-shipment.js'),
  createShipmentItem: require('../dialogs/create-shipment-item.js'),
  createAddress: require('../dialogs/create-address.js'),
  listPicker: require('../dialogs/list-picker.js'),
};

var getCloseDialogIcon = function(opts) {
  opts = opts || {};
  opts.icon = {
    msvg: opts.msvg,
  };
  delete opts.msvg;
  return m(iconButton, opts);
};

var getSaveDialogButton = function(label_or_opts) {
  var opts = (typeof label_or_opts === 'string' ? { label: label_or_opts } : label_or_opts) || {};
  opts.class = (opts.class || '') + ' dialog-action-button';
  return m(button, opts);
};

var makeFullscreenDialog = function(title, content, fullscreenOptions) {
  return m(headerPanel, {
    mode: 'seamed',
    class: 'header-panel--fit passenger-theme passenger-theme-dialog ' + (fullscreenOptions.class || ''),
    fixed: true,
    header: {
      toolbar: {
        middleBar: [
          (fullscreenOptions.icon ? getCloseDialogIcon(fullscreenOptions.icon) : null) || m.trust('&nbsp;&nbsp;'),
          m('div.flex.brand.page-layout-title', title),
          fullscreenOptions.action ? getSaveDialogButton(fullscreenOptions.action) : null,
        ],
      }
    },
    content: content,
  });
};

var dialogs = module.exports = {
  show: function(type, ctrl, args, options, callback) {
    options = options || {};
    var callbackCalled = false;
    var dialogOptions = templates[type].call(dialogs, ctrl, args, options, function(err, res) {
      callbackCalled = true;
      if (err) {
        callback && callback(err);
        return;
      }
      if (!callback || false !== callback(null, res)) {
        dialogs.hide();
      }
    });
    dialogOptions.didHide = function() {
      if (!callbackCalled) {
        global.logger.warn('dialog hidden without action');
        callback(null, null);
      }
    };
    if (dialogOptions.fullscreen) {
      var fullscreenOptions = dialogOptions.fullscreen;
      dialogOptions.fullscreen = true;
      dialogOptions.body = makeFullscreenDialog(dialogOptions.title, dialogOptions.body, fullscreenOptions);
      global.document.body.className = (global.document.body.className || '') + ' fullscreen-dialog';
    }
    dialog.show(dialogOptions);
  },

  hide: function() {
    dialog.hide();
    global.document.body.className = global.document.body.className.replace(/\s*fullscreen\-dialog\s*/g, ' ');
  },
};
