'use strict';

var m = require('mithril');

var stamprSvg = require('../../common/svg.js');
var iconCreditCardVisa = stamprSvg.visa_logo_card_flat;
var iconCreditCardMastercard = stamprSvg.mastercard_logo_card_flat;
var iconCreditCardAmex = stamprSvg.amex_logo_card_flat;

var iconCreditCard = require('mmsvg/google/msvg/action/credit-card');

var Stripe = global.Stripe;

var checkout = module.exports = {
  validateCardNumber: function(number) {
    return Stripe.card.validateCardNumber(number);
  },

  validateExpiry: function(exp_month, exp_year) {
    return Stripe.card.validateExpiry(exp_month, exp_year);
  },

  validateCVC: function(cvc) {
    return Stripe.card.validateCVC(cvc);
  },

  cardTypeFromNumber: function(number) {
    switch (Stripe.card.cardType(number)) {
      default:
        return 'unknown';
      case 'Visa':
        return 'visa';
      case 'MasterCard':
        return 'mastercard';
      case 'American Express':
        return 'amex';
      case 'Discover':
        return 'discover';
      case 'Diners Club':
        return 'diners';
      case 'JCB':
        return 'jcb';
    }
  },

  cardTypeFromBrand: function(brand) {
    switch (brand) {
      default:
        return 'unknown';
      case 'Visa':
        return 'visa';
      case 'MasterCard':
        return 'mastercard';
      case 'American Express':
        return 'amex';
      case 'Discover':
        return 'discover';
      case 'Diners Club':
        return 'diners';
      case 'JCB':
        return 'jcb';
    }
  },

  cardTypeToSvg: function(cardType) {
    switch (cardType) {
      default:
        return iconCreditCard;
      case 'visa':
      case 'Visa':
        return iconCreditCardVisa;
      case 'mastercard':
      case 'MasterCard':
        return iconCreditCardMastercard;
      case 'amex':
      case 'American Express':
        return iconCreditCardAmex;
    }
  },

  cardNumberToTypeSvg: function(number) {
    var type = checkout.cardTypeFromNumber(number);
    return checkout.cardTypeToSvg(type);
  },

  // callback(err, tokenObject)
  createToken: function(data, callback) {
    // {
    //   number: $('.card-number').val(),
    //   cvc: $('.card-cvc').val(),
    //   exp_month: $('.card-expiry-month').val(),
    //   exp_year: $('.card-expiry-year').val(),
    //   address_zip: $('.address_zip').val(),
    //   name: cardholder name,
    //   address_line1: billing address line 1,
    //   address_line2: billing address line 2,
    //   address_city: billing address city,
    //   address_state: billing address state,
    //   address_zip: billing postal code as a string (e.g., "94301"),
    //   address_country: billing address country,
    // }
    Stripe.card.createToken(data, function(status, response) {
      if (response.error) {
        return callback(response.error);
      }
      callback(null, response);
    });
  },
};
