'use strict';

var m = require('mithril');
var spinner = require('polythene/spinner/indeterminate-spinner');
var screen = require('../shared/screen.js');
var tabbedLayout = require('../layouts/tabbed.js');

var Welcome = module.exports = screen.create({
  controller: function(args) {
    var ctrl = this;
  },
  view: tabbedLayout(function(ctrl, args) {
    return m('div.pe-fit.layout.center-center', [
      m('div.block', [
        m(spinner, {
          type: 'large',
          show: true,
        }),
      ]),
    ]);
  }),
});
