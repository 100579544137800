'use strict';

var m = require('mithril');

var visibility = module.exports = {
  _init: false,
  handler: function(){},

  init: function initLocation() {
    if (visibility._init) {
      global.logger.debug('visibility watcher already init');
      return;
    }
    visibility._init = true;
    var hidden, visibilityChange;
    if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    }
    else if (typeof document.mozHidden !== 'undefined') {
      hidden = 'mozHidden';
      visibilityChange = 'mozvisibilitychange';
    }
    else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    }
    else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }
    if (global.document.addEventListener && typeof global.document[hidden] !== 'undefined') {
      global.document.addEventListener(visibilityChange, function() {
        m.startComputation();
        var visible = !global.document[hidden];
        global.logger.info('tab visibility changed', visible);
        visibility.handler(visible);
        m.endComputation();
      }, false);
    }
  },
};
