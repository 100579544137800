'use strict';

var EventEmitter = require('events');
var m = require('mithril');
var async = require('async');
var Shepherd = require('tether-shepherd');

var tourSettingName = 'settings:tourdismissed';
var tourStepSettingName = 'settings:tourstepsstate';
var dismissTrue = 'yes';
var dismissFalse = 'no';

var tour = module.exports = {
  emitter: new EventEmitter(),
  client: null,
  session: null,

  prevented: false,

  _readSession: function(key) {
    try {
      return JSON.parse(tour.session.getItem(key) || 'null');
    }
    catch(err) {
      return null;
    }
  },

  _writeSession: function(key, value) {
    tour.session.setItem(key, JSON.stringify(value, null, 2));
  },

  _getTourClient: function() {
    return new Shepherd.Tour({
      defaults: {
        classes: 'shepherd-theme-arrows',
      },
    });
  },

  _getScreenTourClient: function() {
    var screenTour = tour._getTourClient();
    tour.prevented = true;
    screenTour.on('hide', function() {
      tour.prevented = false;
    });
    return screenTour;
  },

  // id format is [screen]:[id]
  _addStep: function(screen, id, options) {
    var stepId = [ screen, id ].join(':');
    options.showCancelLink = 'showCancelLink' in options ? options.showCancelLink : true; // default true
    options.buttons = options.buttons || [ { text: 'CLOSE', action: tour.emitter.emit.bind(tour.emitter, stepId + ':hide', true) } ];
    var step = tour.client.addStep(stepId, options).getById(stepId);
    step.on('cancel', function() {
      tour._dismissStep(stepId);
    });
    step.on('complete', function() {
      tour._dismissStep(stepId);
    });
    tour.emitter.on(stepId + ':show', function() {
      if (!tour.prevented && !step.isOpen() && !tour.isStepDismissed(stepId)) {
        tour.client.show(stepId);
      }
    });
    tour.emitter.on(stepId + ':hide', function(shouldCompleteStep) {
      if (step.isOpen()) {
        tour.client.hide();
        if (shouldCompleteStep) {
          step.complete();
        }
      }
    });
    return step;
  },

  _getTourState: function() {
    return tour._readSession(tourSettingName) || dismissFalse;
  },

  _getStepState: function() {
    return tour._readSession(tourStepSettingName) || {};
  },

  // _dismissTour: function() {
  //   global.logger.info('tour dismissed: ');
  //   tour._writeSession(tourSettingName, dismissTrue);
  // },

  _dismissStep: function(stepId) {
    global.logger.info('step dismissed: ', stepId);
    var tourStepStates = tour._getStepState();
    tourStepStates[stepId] = dismissTrue;
    tour._writeSession(tourStepSettingName, tourStepStates);
  },

  reset: function() {
    tour._writeSession(tourSettingName, 'no');
    tour._writeSession(tourStepSettingName, {});
  },

  isTourDismissed: function() {
    return tour._getTourState() === dismissTrue;
  },

  isStepDismissed: function(stepId) {
    var tourStepStates = tour._getStepState();
    return tourStepStates[stepId] === dismissTrue;
  },

  emit: function() {
    return tour.emitter.emit.apply(tour.emitter, arguments);
  },

  emitDelayed: function(name, delay) {
    var _this = this;
    _this.delayed = _this.delayed || {};
    if (name in _this.delayed) return; // prevent double calls
    _this.delayed[name] = setTimeout(function() {
      tour.emit(name);
      delete _this.delayed[name];
    }, delay || 1500);
  },

  init: function(session) {
    tour.session = session;
    if (tour.isTourDismissed()) {
      global.logger.debug('skipping tour because it has been dismissed');
      return;
    }
    tour.client = tour._getTourClient();
    tour._addSteps();
  },

  destroy: function() {
    tour.session = null
    if (tour.client) {
      try {
        tour.client.hide();
      }
      catch (err) {
        global.logger.debug('hiding tour failed during destruction', err);
      }
    }
    tour.client = null;
    try {
      tour.emitter.removeAllListeners();
    }
    catch (err) {
      global.logger.debug('removing event listeners failed during destruction', err);
    }
  },


  /////////////////
  // steps
  /////////////////

  _doHomeScreenTour: function() {
    var screenTour = tour._getScreenTourClient();
    screenTour.addStep({
      attachTo: '.passenger-main-tabs > .pe-tabs__row > a:nth-child(2) top',
      title: 'Track shipments',
      text: [
        '<p>Each time we come pick up items from you it\'s known as a "pickup".</p>',
        '<p>A pickup can be contain multiple shipments, which can be thought of as single orders or boxes that need to be shipped.</p>',
        '<p>You can track the status of all your shipments here.</p>',
      ],
      tetherOptions: {
        // attachment: 'bottom left',
        // targetAttachment: 'top center',
        offset: '0px 0px',
        constraints: null,
      },
    });
    screenTour.addStep({
      attachTo: '.passenger-main-tabs > .pe-tabs__row > a:nth-child(4) top',
      title: 'Request a pickup',
      text: [
        '<p>Click on Pickup to start the process of requesting a pickup.</p>',
        '<p>Once requested, a courier will be dispatched to your location to pick up your items.</p>',
      ],
      tetherOptions: {
        attachment: 'top left',
        targetAttachment: 'bottom center',
        offset: '0px 0px',
      },
    });
    screenTour.addStep({
      attachTo: '.passenger-toolbar-cart bottom',
      title: 'Incoming order cart',
      text: [
        '<p>You can connect your account to other services like an online store to have shipments automatically import.</p>',
        '<p>Imported shipments will get added to your cart so that you can request a pickup when you\'re ready.</p>',
      ],
      tetherOptions: {
        attachment: 'bottom left',
        targetAttachment: 'top center',
        offset: '0px 6px',
      },
    });
    screenTour.start();
  },

  _doPickupScreenTour: function() {
    var screenTour = tour._getScreenTourClient();
    screenTour.addStep({
      attachTo: '.pickup-map-center-overlay top',
      title: 'Where should we meet you?',
      text: [
        '<p>After you\'re done dragging the map to the location where the driver should meet you, you can click this button to proceed to the next step.</p>',
      ],
      buttons: [
        {
          text: 'Next',
          action: function() {
            async.waterfall([
              function(next) {
                screenTour.getCurrentStep().hide();
                setTimeout(next, 500);
              },
              function(next) {
                global.document.querySelector('.pickup-map-center-overlay').click();
                setTimeout(next, 1000);
              },
            ], function() {
              screenTour.next();
            });
          },
        },
      ],
    });
    screenTour.addStep({
      attachTo: '.pickup-search-overlay bottom',
      title: 'Nearest address is detected',
      text: [
        '<p>The street address for the location will be automatically detected if you gave the app permission to access your location.</p>',
      ],
    });
    screenTour.addStep({
      attachTo: '.pickup-search-overlay a.passenger-link-button bottom',
      title: 'Not the right address?',
      text: [
        '<p>If the displayed address is not correct you can change it by clicking Edit and fixing it.</p>',
      ],
    });
    screenTour.addStep({
      attachTo: '.pickup-selected-bottom-sheet top',
      title: 'Enter payment info',
      text: [
        '<p>The credit card you enter will be used to pay the pickup fee as well as any shipping or handling fees.</p>',
        '<p>We use <a target="_blank" href="https://www.stripe.com/">Stripe.com</a> to charge your account and keep your information safe and secure!</p>',
      ],
    });
    screenTour.addStep({
      attachTo: '.pickup-selected-bottom-sheet a.pickup-primary-action-button top',
      title: 'Request the pickup',
      text: [
        '<p>After you request a pickup you\'ll be charged the non-refundable pickup fee and a courier will immediately be notified.</p>',
        '<p>You will be able to track the arrival of the driver and tell us what you want shipped on the next page.</p>',
      ],
    });
    screenTour.start();
  },

  _addSteps: function() {
    tour._addStep('home', 'first-run', {
      title: 'Interested in a quick tour?',
      text: [
        '<p>Hey there!  Let give you a quick tour of this screen so you can find your way around.</p>',
      ],
      buttons: [
        {
          text: 'Start Tour',
          action: function() {
            tour.client.getCurrentStep().complete();
            tour._doHomeScreenTour();
          },
        },
      ],
    });

    tour._addStep('pickup', 'first-run', {
      title: 'Interested in a quick tour?',
      text: [
        '<p>This screen is where you come when you have something you\'d like us to come pickup and ship.</p>',
        '<p>There are a few things to know about this screen.</p>',
      ],
      buttons: [
        {
          text: 'Start Tour',
          action: function() {
            tour.client.getCurrentStep().complete();
            tour._doPickupScreenTour();
          },
        },
      ],
    });
  },

};


global.tour = tour;



