'use strict';

var m = require('mithril');
var db = require('../lib/db.js');
var Base = require('./base.js');

function Cart(data) {
  data = data || {};
  Base.call(this, data);
  this.cartId = m.prop(data.cartId || null);
  this.items = m.prop(data.items || []);
  this.transaction = m.prop(data.transaction || {});
}

Base.inheritedBy(Cart);

Cart.prototype.remove = function(itemId) {
  var items = this.items();
  this.items(items.filter(function(item) {
    return itemId !== item.itemId;
  }));
};

Cart.prototype.save = function() {
  var _this = this;
  var changedCart = Cart.write(this.items());
  changedCart.then(function(cart) {
    _this.import(cart);
    return cart;
  });
  return changedCart;
};

Cart.prototype.import = function(model) {
  Base.prototype.import.call(this, model);
  this.cartId(model.cartId());
  this.items(model.items());
  this.transaction(model.transaction());
};

Cart.prototype.toJSON = function() {
  var data = Base.prototype.toJSON.call(this);
  data.cartId = this.cartId();
  data.items = this.items();
  data.transaction = this.transaction();
  return data;
};

Cart.read = function() {
  var data = db.createPayload('read-cart');
  return db.request({ function: config.lambda.orders, data: data, type: Cart });
};

Cart.write = function(items) {
  var data = db.createPayload('update-cart', {
    items: items,
  });
  return db.request({ function: config.lambda.orders, data: data, type: Cart });
};

module.exports = Cart;
