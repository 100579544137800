'use strict';

var m = require('mithril');
var headerPanel = require('polythene/header-panel/header-panel');
var tabs = require('polythene/tabs/tabs');
var svg = require('polythene/svg/svg');
var icon = require('polythene/icon/icon');
var button = require('polythene/button/button');
var iconButton = require('polythene/icon-button/icon-button');
var dialog = require('polythene/dialog/dialog');

var iconHome = require('mmsvg/google/msvg/action/home');
var iconLocalShipping = require('mmsvg/google/msvg/maps/local-shipping');
var iconWork = require('mmsvg/google/msvg/action/work');
var iconBell = require('mmsvg/templarian/msvg/bell');
var iconCog = require('mmsvg/google/msvg/action/settings');
var iconNoteAdd = require('mmsvg/google/msvg/action/note-add');
var iconSettings = require('mmsvg/google/msvg/action/settings');
var iconArrowBack = require('mmsvg/google/msvg/navigation/arrow-back');
var iconShoppingCart = require('mmsvg/google/msvg/action/shopping-cart');
var iconDownload = require('mmsvg/google/msvg/file/cloud-download');
var iconWhatsHot = require('mmsvg/google/msvg/social/whatshot');

var stamprSvg = require('../../common/svg.js');
var logoPassenger = stamprSvg.passenger_logo_horizontal_white;
var logoAirplane = stamprSvg.stampr_logo_airplane_flat;

const menu = [
  {
      label: 'Home',
      // class: 'contains-badge',
      icon: {
        content: [
          m('div.pe-icon.pe-icon--regular', {
            // class: 'mdl-badge mdl-badge--overlap',
            // 'data-badge': 13,
          }, [
            m(svg, {
              content: iconHome,
            }),
          ]),
        ],
      },
      url: {
        href: '/',
        config: m.route
      }
  },
  {
      label: 'Settings',
      icon: {
        msvg: iconSettings,
      },
      url: {
          href: '/settings',
          config: m.route
      }
  },
  {
      label: 'Shipments',
      icon: {
        msvg: iconWork,
      },
      url: {
          href: '/shipments',
          config: m.route
      }
  },
  {
      label: 'Schedule',
      icon: {
        msvg: iconLocalShipping,
      },
      url: {
          href: '/pickup',
          config: m.route
      }
  },
];

const indexForRoute = (route) => {
    return menu.reduce((previousValue, tab, index) => {
        if (route === tab.url.href) {
            return index;
        }
        else {
            return previousValue;
        }
    }, 0);
};

const mainTabs = {
  controller: function(args) {
    m.redraw.strategy('diff');
    return {
      selectedTabIndex: -1,
      previousSelectedTab: -1
    };
  },
  view: function(ctrl, args) {
    const selectedTabIndex = indexForRoute(m.route());
    let resetContent = false;
    if (ctrl.selectedTabIndex !== selectedTabIndex) {
      ctrl.selectedTabIndex = selectedTabIndex;
      resetContent = true;
    }
    return m('div.screen.layout.vertical.pe-fit', [
      args.content,
      m('div.block', [
        m.component(tabs, {
          class: args.class || null,
          buttons: menu,
          autofit: true,
          hideIndicator: true,
          selectedTab: selectedTabIndex
        }),
      ]),
    ]);
  },
}

const toolbarRow = (opts, ctrl, args) => {
  var vmApp = ctrl.vmApp;
  return [
    // m.component(iconButton, {
    //     icon: {
    //         msvg: iconArrowBack
    //     }
    // }),
    opts.icon || (opts.title ? m(svg, {
      tag: 'a',
      class: 'logo-icon',
      content: logoAirplane,
      href: '/',
      config: m.route
    }) : ''),
    m('div.flex.brand.tabbed-layout-title', [
      opts.title || m(svg, {
        class: 'logo',
        content: logoPassenger,
      }),
    ]),
    m(iconButton, {
      class: 'passenger-toolbar-cart',
      url: {
        href: '/cart',
        config: m.route,
      },
      content: [
        m('div.pe-icon.pe-icon--regular', {
          class: 'mdl-badge mdl-badge--overlap',
          'data-badge': vmApp.cartItemCount(),
        }, [
          m(svg, {
            content: iconDownload,
          }),
        ]),
      ],
    }),
  ];
};

module.exports = function(content, opts) {
  opts = opts || {};
  return function(ctrl, args) {
    var vmApp = ctrl.vmApp;
    var currentRoute = m.route();
    return [
      // m('div.navbar.navbar-default.navbar-fixed-top', [
      //   m('div.container-fluid', [
      //     m('ul.nav.navbar-nav.navbar-right', [
      //       m('li', [
      //         m('a[href="javascript:;"]', 'User'),
      //       ]),
      //       m('li', [
      //         m('a[href="javascript:;"]', 'Cart (' + vmApp.cart().length + ')'),
      //       ]),
      //     ]),
      //     m('a.navbar-brand[href="javascript:;"]', ' | Passenger'),
      //   ]),
      // ]),
      m(mainTabs, {
        class: 'passenger-main-tabs',
        content: [
          m(headerPanel, {
            mode: opts.headerMode || 'seamed',
            class: 'header-panel--fit passenger-theme',
            fixed: true,
            header: {
              toolbar: {
                topBar: opts.toolbarMiddleBar || null,
                middleBar: opts.toolbarMiddleBar || toolbarRow(opts, ctrl, args),
                bottomBar: opts.toolbarBottomBar || null,
              }
            },
            content: [
              content(ctrl, args),
            ],
          }),
        ],
      }),
      m(dialog),
      // m('div#dashboard-main.container-fluid', [
      //   m('div.row', [
      //     m('div.col-lg-12', [
      //       m('div.full-scrolls', content(ctrl, args)),
      //     ]),
      //     m('div.col-lg-12.bg-primary', { style: 'position:fixed;bottom:0;left:0;width:100%;height:56px;margin:-56px 0 0;padding:0;' }, [
      //       m('ul.nav.nav-pills', [
      //         m('li[role="presentation"]', { class: currentRoute === '/' ? 'active' : '' }, [
      //           m('a[href="/"]', { config: m.route }, 'Home'),
      //         ]),
      //         m('li[role="presentation"]', { class: currentRoute === '/unapproved' ? 'active' : '' }, [
      //           m('a[href="/unapproved"]', { config: m.route }, 'Incoming'),
      //         ]),
      //         m('li[role="presentation"]', { class: currentRoute === '/history' ? 'active' : '' }, [
      //           m('a[href="/history"]', { config: m.route }, 'History'),
      //         ]),
      //         m('li[role="presentation"]', { class: currentRoute === '/create' ? 'active' : '' }, [
      //           m('a[href="/create"]', { config: m.route }, 'Create'),
      //         ]),
      //       ]),
      //     ]),
      //   ]),
      // ]),
    ];
  };
};
