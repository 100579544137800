'use strict';

var m = require('mithril');

var Message = module.exports = {
  vm: function(data) {
    data = data || {};
    var vm = {};
    return vm;
  },

  controller: function(args) {
  },
  view: function(ctrl, args) {
    return m('div', {
      class: [
        'passenger-alert ' + (args.type ? 'passenger-alert-' + args.type : ''),
        args.class,
        args.outline ? 'outline' : 'no-outline',
      ].join(' '),
    }, args.content);
  },
};

