'use strict';

var m = require('mithril');
var headerPanel = require('polythene/header-panel/header-panel');
var button = require('polythene/button/button');
var tabs = require('polythene/tabs/tabs');
var list = require('polythene/list/list');
var icon = require('polythene/icon/icon');
var listTile = require('polythene/list-tile/list-tile');
var card = require('polythene/card/card');
var iconInfoOutline = require('mmsvg/google/msvg/action/info-outline');
var screen = require('../shared/screen.js');
var tabbedLayout = require('../layouts/tabbed.js');
var message = require('../components/message.js');
var shipmentCard = require('../components/shipment-card.js');
var asyncButton = require('../components/async-button.js');

var Shipment = require('../models/shipment.js');
var Payment = require('../models/payment.js');

var shipmentOptions = require('../shared/shipment-options.js');
var promiseErrorHandler = require('../shared/promise-error-handler.js');

const TABS = [
  { label: 'Waiting', }, // shipment.status = pending
  { label: 'Picked up', }, // shipment.status = possessed
  { label: 'Packing', }, // shipment.status = received, packed
  { label: 'Shipped', }, // shipment.status = shipped
];

const tabNavigation = (currentTabFn) => {
    return m.component(tabs, {
      // menu: true,
      buttons: TABS,
      autofit: true,
      selectedTab: currentTabFn(),
      activeSelected: true,
      getState: (state) => {
        currentTabFn(state.index);
      },
    });
};

var Shipments = module.exports = screen.create({
  controller: function(args) {
    var ctrl = this;
    ctrl.currentTab = m.prop(0);

    ctrl.pickup = m.prop([]);
    process.nextTick(function() { Shipment.find('pending').then(ctrl.pickup, promiseErrorHandler()); }); // HACK: don't block

    ctrl.possessed = m.prop([]);
    process.nextTick(function() { Shipment.find('possessed').then(ctrl.possessed, promiseErrorHandler()); }); // HACK: don't block

    ctrl.shipping = m.prop([]);
    process.nextTick(function() {
      m.sync([
        Shipment.find('received'),
        Shipment.find('packed'),
      ]).then(function(result) {
        var shipping = ctrl.shipping();
        shipping.concat.apply(shipping, result);
      }, promiseErrorHandler());
    }); // HACK: don't block

    ctrl.shipped = m.prop([]);
    process.nextTick(function() { Shipment.find('shipped').then(ctrl.shipped, promiseErrorHandler()); }); // HACK: don't block
  },
  view: tabbedLayout(function(ctrl, args) {
    const currentTabIndex = ctrl.currentTab();
    const nextTabIndex = (currentTabIndex + 1) % TABS.length;
    var shipments = [];
    var description;
    switch (currentTabIndex) {
      case 0:
        shipments = ctrl.pickup() || [];
        description = 'These shipments are still in your possession.  They have been created, but have not yet been picked up by a courier.';
      break;
      case 1:
        shipments = ctrl.possessed() || [];
        description = 'A courier has taken possession of the shipment and it is making its way to our packing facility.';
      break;
      case 2:
        shipments = ctrl.shipping() || [];
        description = 'We\'ve received your shipment and it is being processed at our facility.';
      break;
      case 3:
        shipments = ctrl.shipped() || [];
        description = 'The shipment is on its way to the destination address.  You should receive a tracking code shortly if you haven\'t already.';
      break;
    }
    var emptyMessage = m(message, {
      outline: true,
      content: [
        m('h5', 'No shipments in ' + TABS[currentTabIndex].label.toLowerCase()),
        m('p', 'There are no shipments to display.'),
      ],
    });
    var activePickupMessage = null;
    // if (ctrl.vmApp.isPickupActive() && currentTabIndex === 0) {
    //   activePickupMessage = m(message, {
    //     type: 'info',
    //     outline: false,
    //     content: [
    //       m('h5', 'Courier on the way!'),
    //       m('p', 'There is a courier currently on their way to you.'),
    //       m('p', 'You can review or modify the shipments for your current pickup on the tracking page.'),
    //       m('div', { style: 'text-align:right;' }, [
    //         m(button, {
    //           raised: true,
    //           label: 'Track Courier Arrival',
    //           url: {
    //             href: '/dispatched',
    //             config: m.route,
    //           },
    //         }),
    //       ]),
    //     ],
    //   });
    // }
    return m('div.pe-fit', [
      m(headerPanel, {
        mode: 'seamed',
        class: 'header-panel--fit',
        fixed: true,
        header: {
          content: tabNavigation(ctrl.currentTab),
        },
        content: [
          activePickupMessage,
          m('div.block.layout.horizontal', { style: 'margin:10px 0;padding:15px;', }, [
            m('div.block', { style: 'margin-right:8px;', }, [
              m(icon, {
                type: 'large',
                msvg: iconInfoOutline,
              }),
            ]),
            m('b.block', description),
          ]),
          !shipments.length ? emptyMessage : m('div.layout.wrap.dispatched-shipment-cards', shipments.map(function(shipment) {
            var actions = null;
            if (global.StamprEnv.ENV === 'development') {
              actions = [
                m('div.flex', [ shipment.pickupId().split('-').shift(), ' : ', shipment.itemId().split('-').shift() ]),
                m(asyncButton, {
                  class: 'button-danger-text',
                  label: 'Cancel',
                  action: function(deferred, eventArgs) {
                    m.startComputation();
                    shipment.cancel().then(function(shipment) {
                      ctrl.pickup(ctrl.pickup().filter(function(s) {
                        return s.itemId() !== shipment.itemId();
                      }));
                      deferred.resolve();
                      m.endComputation();
                      return shipment;
                    }, function(err) {
                      deferred.reject(err);
                      m.endComputation();
                    });
                  },
                }),
              ];
            }
            var cardArgs = shipmentCard.vm({
              shipment: shipment,
              actions: actions,
              displayTotal: true,
              usePaymentDetails: true,
              displayTimestamp: true,
              statusTheme: currentTabIndex !== 0 ? 'dark-theme' : null,
            });
            return m(shipmentCard, cardArgs);
          })),
        ],
      }),
    ]);
  }, {
    title: m('span.tabbed-layout-text-title', 'Review shipments'),
  }),
});
