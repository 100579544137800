'use strict';

var m = require('mithril');
var db = require('../lib/db.js');
var Base = require('./base.js');

var Payment = require('./payment.js');

function Subscription(data) {
  data = data || {};
  Base.call(this, data);
  this.id = m.prop(data.id || null);
  this.cancel_at_period_end = m.prop(data.cancel_at_period_end || null);
  this.canceled_at = m.prop(data.canceled_at || null);
  this.current_period_end = m.prop(data.current_period_end || null);
  this.current_period_start = m.prop(data.current_period_start || null);
  this.customer = m.prop(data.customer || null);
  this.discount = m.prop(data.discount || null);
  this.ended_at = m.prop(data.ended_at || null);
  this.metadata = m.prop(data.metadata || {});
  this.plan = m.prop(data.plan || {});
  this.quantity = m.prop(data.quantity || null);
  this.start = m.prop(data.start || null);
  this.status = m.prop(data.status || null);
  this.tax_percent = m.prop(data.tax_percent || null);
  this.trial_end = m.prop(data.trial_end || null);
  this.trial_start = m.prop(data.trial_start || null);
}

Base.inheritedBy(Subscription);

Subscription.prototype.update = function(planId) {
  var _this = this;
  var data = db.createPayload('update-subscription', {
    subscriptionId: this.id(),
    planId: planId,
  });
  var changedSubscription = db.request({ function: config.lambda.payments, data: data, type: Subscription });
  changedSubscription.then(function(subscription) {
    _this.import(subscription);
    return subscription;
  });
  return changedSubscription;
};

Subscription.prototype.getPlanSummary = function() {
  var plan = this.plan();
  return Subscription.planToSummary(plan);
};

Subscription.prototype.getPlanSetting = function(key) {
  var metadata = (this.plan() || {}).metadata || {};
  return metadata[key];
};

Subscription.prototype.import = function(model) {
  Base.prototype.import.call(this, model);
  this.id(model.id());
  this.cancel_at_period_end(model.cancel_at_period_end());
  this.canceled_at(model.canceled_at());
  this.current_period_end(model.current_period_end());
  this.current_period_start(model.current_period_start());
  this.customer(model.customer());
  this.discount(model.discount());
  this.ended_at(model.ended_at());
  this.metadata(model.metadata());
  this.plan(model.plan());
  this.quantity(model.quantity());
  this.start(model.start());
  this.status(model.status());
  this.tax_percent(model.tax_percent());
  this.trial_end(model.trial_end());
  this.trial_start(model.trial_start());
};

Subscription.prototype.toJSON = function() {
  var data = Base.prototype.toJSON.call(this);
  data.id = this.id();
  data.cancel_at_period_end = this.cancel_at_period_end();
  data.canceled_at = this.canceled_at();
  data.current_period_end = this.current_period_end();
  data.current_period_start = this.current_period_start();
  data.customer = this.customer();
  data.discount = this.discount();
  data.ended_at = this.ended_at();
  data.metadata = this.metadata();
  data.plan = this.plan();
  data.quantity = this.quantity();
  data.start = this.start();
  data.status = this.status();
  data.tax_percent = this.tax_percent();
  data.trial_end = this.trial_end();
  data.trial_start = this.trial_start();
  return data;
};


Subscription.create = function(planId) {
  var data = db.createPayload('create-subscription', {
    planId: planId,
  });
  return db.request({ function: config.lambda.payments, data: data, type: Subscription });
};

Subscription.read = function(subscriptionId) {
  var data = db.createPayload('read-subscription', {
    subscriptionId: subscriptionId,
  });
  return db.request({ function: config.lambda.payments, data: data, type: Subscription });
};

Subscription.remove = function(subscriptionId) {
  var data = db.createPayload('remove-subscription', {
    subscriptionId: subscriptionId,
  });
  return db.request({ function: config.lambda.payments, data: data, type: Subscription });
};

Subscription.find = function(search) {
  var data = db.createPayload('find-subscription', {
    search: search,
  });
  return db.request({ function: config.lambda.payments, data: data, type: Subscription });
};

Subscription.list = function() {
  var data = db.createPayload('list-subscriptions');
  return db.request({ function: config.lambda.payments, data: data, type: Subscription });
};

Subscription.availablePlans = function() { // returns raw data; does not return Subscription object
  var data = db.createPayload('available-subscriptions');
  return db.request({ function: config.lambda.payments, data: data });
};

Subscription.planNameToLocale = function(planName) {
  return ((planName || '').split('Stampr Passenger - ')[1] || '').trim();
};

Subscription.planToSummary = function(plan) {
  if (plan.name) {
    var planName = Subscription.planNameToLocale(plan.name);
    return planName + ' - ' + Payment.amountToLocale(plan.amount, plan.currency) + ' / ' + plan.interval;
  }
  else {
    return '';
  }
};

module.exports = Subscription;
