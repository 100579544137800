'use strict';

var shipmentOptions = module.exports = {
  isFreeShippingEligible: false,

  getAttributeByName: function(namespace, value, key) {
    var items = shipmentOptions[namespace];
    for (var i=0; i < items.length; i++) {
      if (items[i].value === value) {
        return items[i][key];
      }
    }
  },

  getName: function(namespace, value) {
    return shipmentOptions.getAttributeByName(namespace, value, 'name');
  },

  getPrice: function(namespace, value, size) {
    var price = shipmentOptions.getAttributeByName(namespace, value, 'price');
    return shipmentOptions.findSizePrice(price, size);
  },

  getShippingPrice: function(options) {
    var isFreeShippingEligible = shipmentOptions.isFreeShippingEligible;
    var thisDisablesFreeShipping = false; // false means disable
    if (thisDisablesFreeShipping && isFreeShippingEligible && shipmentOptions.isFreeShipping(options)) {
      return 'FREE!';
    }
    else {
      return 'Variable';
    }
  },

  findSizePrice: function(price, size) {
    if (void 0 === price) return price;
    return typeof price === 'number' ? price : price[size || price['_default']];
  },

  isFreeShipping: function(options) {
    return false; // disable free shipping
    options = options || null;
    var freeShipping = true;
    global.logger.debug('isFreeShipping', options);
    freeShipping = freeShipping && [ 'simple', 'sm' ].indexOf(options.size) > -1;
    freeShipping = freeShipping && 'standard' === options.packing;
    freeShipping = freeShipping && 'standard' === options.handling;
    freeShipping = freeShipping && 'standard' === options.shipping;
    // freeShipping = freeShipping && (!options.insurance || '100' === options.insurance);
    // freeShipping = freeShipping && (!options.gift_wrap || 'no_gift_wrap' === options.gift_wrap);
    return freeShipping;
  },

  size: [
    {
      name: 'Simple',
      description: 'Fits in a padded envelope, polymer bag, or jewelry box. Max. weight: 1 lb.',
      value: 'simple',
    },
    {
      name: 'Small',
      description: 'Fits in a shoebox. Max. weight: 10 lbs.',
      value: 'sm',
    },
    {
      name: 'Medium',
      description: 'Fits in a backpack or grocery bag.  Max. weight: 20 lbs.',
      value: 'md',
    },
    {
      name: 'Large',
      description: 'Fits in the overhead storage of an airplane.  Max. weight: 30 lbs.',
      value: 'lg',
    },
    {
      name: 'Extra Large',
      description: 'Fits in the backseat of your car.  Max. weight: 50 lbs.',
      value: 'xl',
    },
  ],

  packing: [
    // {
    //   name: 'Already packed',
    //   description: 'You will have your shipment already packaged before we arrive',
    //   value: 'prepacked',
    //   price: 0,
    //   currency: 'usd',
    // },
    {
      name: 'Not fragile',
      description: 'Suitable for items not requiring special fragile packaging',
      value: 'standard',
      price: { _default: 'simple', simple: 200, sm: 400, md: 600, lg: 1000, xl: 2500 },
      currency: 'usd',
    },
    {
      name: 'Fragile',
      description: 'For when you\'re shipping very fragile items -- or just paranoid',
      value: 'fragile',
      price: { _default: 'simple', simple: 500, sm: 1000, md: 1500, lg: 3000, xl: 7500 },
      currency: 'usd',
    },
  ],

  handling: [
    {
      name: 'Standard',
      description: 'Usually ships in one or two days depending on availability',
      value: 'standard',
      price: 0,
      currency: 'usd',
    },
    {
      name: 'Expedited',
      description: 'Guaranteed to ship in one day or less',
      value: 'expedited',
      price: 200,
      currency: 'usd',
    },
    {
      name: 'Urgent',
      description: 'Immediately packed and shipped after pickup',
      value: 'urgent',
      price: 1000,
      currency: 'usd',
    },
  ],

  shipping: [
    // {
    //   name: 'Pre-paid label',
    //   description: 'You already have a pre-paid label that you would like used.',
    //   value: 'prepaid',
    //   price: 0,
    //   currency: 'usd',
    // },
    {
      name: 'Ground',
      description: 'Arrives in 2-10 days depending on overall travel distance.',
      value: 'standard',
      price: { _default: 'simple', simple: 100, sm: 300, md: 1000, lg: 2500, xl: 4000 },
      currency: 'usd',
    },
    {
      name: 'Express',
      description: 'Arrives in 2-3 business days.',
      value: 'twoday',
      price: { _default: 'simple', simple: 200, sm: 550, md: 1500, lg: 3000, xl: 5000 },
      currency: 'usd',
    },
    {
      name: 'Overnight',
      description: 'Arrives next business day.',
      value: 'overnight',
      price: { _default: 'simple', simple: 2500, sm: 4000, md: 6500, lg: 8500, xl: 12500 },
      currency: 'usd',
    },
    // {
    //   name: 'Urgent',
    //   description: 'The quickest option available.',
    //   value: 'asap',
    //   price: 0,
    //   currency: 'usd',
    // },
  ],

  insurance: [
    {
      name: 'Up to $100 covered',
      value: '100',
      price: 0,
      currency: 'usd',
    },
    {
      name: 'Up to $500 covered',
      value: '500',
      price: 400,
      currency: 'usd',
    },
    {
      name: 'Up to $1000 covered',
      value: '1000',
      price: 800,
      currency: 'usd',
    },
  ],

  gift_wrap: [
    {
      name: 'None',
      description: 'Item will be packed normally and not gift wrapped',
      value: 'no_gift_wrap',
      price: 0,
      currency: 'usd',
    },
    {
      name: 'Gift wrap',
      description: 'Wrapping paper suitable for any event or holiday',
      value: 'gift_wrap_generic',
      price: { _default: 'simple', simple: 0, sm: 0, md: 400, lg: 800, xl: 1000 },
      // price: { _default: 'simple', simple: 400, sm: 400, md: 400, lg: 600, xl: 1000 },
      currency: 'usd',
    },
  ],

};
