module.exports = {
  sortByUpdate: function(arr) {
    return arr.sort(function(a, b) {
      var keyA = new Date(a.updated()),
          keyB = new Date(b.updated());
      // Compare the 2 dates
      if(keyA > keyB) return -1;
      if(keyA < keyB) return 1;
      return 0;
    });
  },
  sortByCreate: function(arr) {
    return arr.sort(function(a, b) {
      var keyA = new Date(a.created()),
          keyB = new Date(b.created());
      // Compare the 2 dates
      if(keyA > keyB) return -1;
      if(keyA < keyB) return 1;
      return 0;
    });
  }
};
