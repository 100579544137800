'use strict';

var m = require('mithril');
var async = require('async');

var icon = require('polythene/icon/icon');
var iconButton = require('polythene/icon-button/icon-button');
var button = require('polythene/button/button');
var list = require('polythene/list/list');
var listTile = require('polythene/list-tile/list-tile');
var shadow = require('polythene/shadow/shadow');
var spinner = require('polythene/spinner/indeterminate-spinner');
var radioButton = require('polythene/radio-button/radio-button');
var iconLocalShipping = require('mmsvg/google/msvg/maps/local-shipping');
var iconSchedule = require('mmsvg/google/msvg/action/schedule');
var iconLockOpen = require('mmsvg/google/msvg/action/lock-open');
var iconLockClosed = require('mmsvg/google/msvg/action/lock-outline');
var iconSend = require('mmsvg/google/msvg/content/send');


var Payment = require('../models/payment.js');
var Subscription = require('../models/subscription.js');
var Shipment = require('../models/shipment.js');
var Dispatch = require('../models/dispatch.js');
var PickupModel = require('../models/pickup.js');

var screen = require('../shared/screen.js');
var checkout = require('../shared/checkout.js');
var dialogs = require('../shared/dialogs.js');
var addresses = require('../shared/addresses.js');
var tabbedLayout = require('../layouts/tabbed.js');

var linkButton = require('../components/link-button.js');
var selectLocation = require('../components/select-location.js');
var upload = require('../components/upload.js');
var message = require('../components/message.js');
var asyncButton = require('../components/async-button.js');

// var stamprSvg = require('../../common/svg.js');
// var graphicChick = stamprSvg.animal_chick_dark;
// var graphicCat = stamprSvg.animal_cat_dark;
// var graphicPig = stamprSvg.animal_pig_dark;
// var graphicHorse = stamprSvg.animal_horse_dark;

var promiseErrorHandler = require('../shared/promise-error-handler.js');

// var createPickupSizeSelectionItem = function(selectedValue, opts) {
//   var isSelected = selectedValue() === opts.value;
//   return m('div.block.flex', { class: 'pickup-selection-item ' + (isSelected ? 'selected' : 'unselected') }, [
//     // m(icon, {
//     //   type: 'regular',
//     //   class: 'pickup-selection-item-' + opts.value,
//     //   msvg: opts.graphic,
//     // }),
//     m(radioButton, {
//       name: 'pickup-type',
//       label: opts.label,
//       value: opts.value,
//       checked: isSelected,
//       getState: (state) => (selectedValue(state.value)),
//     }),
//   ]);
// };

var convert24hTo12h = function(time) {
  return time > 12 ? time - 12 : time;
};

var getTimeLabel = function(time) {
  var time12h = convert24hTo12h(time);
  return time12h + (time >= 12 && time < 24 ? 'pm' : 'am');
};

var handleAddPayment = function(ctrl, args) {
  dialogs.show('addPayment', ctrl, args, null, function(err, result) {
    if (err) {
      global.logger.warn(err);
      return;
    }
    else if (!result) {
      global.logger.debug('no payment method saved');
      return;
    }
    global.logger.info('payment data received', JSON.stringify(result));
    checkout.createToken({
      name: result.name(),
      number: result.number(),
      cvc: result.cvc(),
      exp_month: result.exp_month(),
      exp_year: result.exp_year(),
    }, function(err, response) {
      if (err) {
        global.logger.error(err);
        alert('Problem saving your card: ' + err.message);
        return;
      }
      global.logger.debug('stripe create token resposne', response);
      Payment.create(response.id).then(function(payment) {
        ctrl.vm.paymentSourceChanged(true);
        ctrl.setActivePaymentSource(payment);
        return payment;
      }, promiseErrorHandler());
    });
  });
};

var handleEditAddress = function(ctrl, args) {
  var formatted_address = ((ctrl.vm.location.address() || {}).formatted_address || '');
  var address = ctrl.vm.manuallyEnteredAddress() ? ctrl.vm.manuallyEnteredAddress().formatted_address : formatted_address.split(',')[0].trim();
  var model = vm.pickup();
  var pickup_instructions = model.pickup_instructions() || '';
  dialogs.show('editPickupAddress', ctrl, args, {
    formatted_address: address,
    pickup_instructions: pickup_instructions,
  }, function(err, result) {
    if (err) {
      global.logger.warn(err);
      return;
    }
    else if (!result) {
      global.logger.debug('no address saved');
      return;
    }
    global.logger.info('address data received', JSON.stringify(result));
    ctrl.overrideAddress({ formatted_address: result.formatted_address() });
    model.pickup_instructions(result.pickup_instructions());
  });
};

var renderPickupButtons = function(ctrl, args) {
  var vmApp = ctrl.vmApp;
  var openHours = vmApp.hoursOfOperation();
  return [
    m('div.block', [
      m(asyncButton, {
        class: 'pickup-primary-action-button',
        label: 'Now - $5.00',
        raised: true,
        action: function(deferred, eventArgs) {
          if (!vmApp.isOpenForBusiness() && !global.skipOpenForBusinessCheck) {
            deferred.resolve();
            return alert('We cannot come now because we are currently closed!\n\nOur hours are:\n\n' + Object.keys(openHours).map(function(dayOfWeek) {
              var dayName = vmApp.DAY_NAMES[dayOfWeek];
              var hours = openHours[dayOfWeek];
              if (!hours) return;
              var startLabel = getTimeLabel(hours.start);
              var endLabel = getTimeLabel(hours.end);
              return '  - ' + dayName + ': ' + startLabel + ' to ' + endLabel + '\n';
            }).join(''));
          }
          else {
            ctrl.createPickup(deferred, 'schedule_now');
          }
        },
      }),
      m('div', { style: 'padding-bottom:3px;text-transform:uppercase;text-align:center;color:rgba(0,0,0,.35);' }, [
        m('small', 'A courier will arrive in as little as 30 minutes'),
      ]),
    ]),
    m('div.block', [
      m(asyncButton, {
        class: 'pickup-primary-action-button',
        label: 'Tomorrow - $0.00',
        raised: true,
        action: function(deferred, eventArgs) {
          if (!vmApp.isOpenForBusiness()) {
            alert('We are currently closed.\n\nThat\'s fine, but the earliest we can come is the afternoon of the next business day.');
          }
          ctrl.createPickup(deferred, 'schedule_future');
        },
      }),
      m('div', { style: 'padding-bottom:3px;text-transform:uppercase;text-align:center;color:rgba(0,0,0,.35);' }, [
        m('small', 'Schedule a courier to arrive tomorrow'),
      ]),
    ]),
  ];
};

var Pickup = module.exports = screen.create({
  vm: function(ctrl, args) {
    var vm = {};
    vm.pickup = m.prop(new PickupModel({
      region: 'SAN',
      location: {
        coords: null,
        address: null,
      },
      type: 'regular',
    }));
    vm.location = args.location || selectLocation.vm();
    vm.manuallyEnteredAddress = m.prop(args.manuallyEnteredAddress || null);
    vm.lastKnownPosition = m.prop(args.lastKnownPosition || ctrl.vmApp.session.getItem('meta:lastknownposition') || {});
    if (vm.lastKnownPosition() && vm.lastKnownPosition().coords) {
      global.logger.debug('loading lastKnownPosition', vm.lastKnownPosition());
      vm.location.setValue({ lat: vm.lastKnownPosition().coords.latitude, lng: vm.lastKnownPosition().coords.longitude });
    }
    else {
      vm.location.setValue({ lat: 32.7677595, lng: -117.1884547 });
    }
    vm.confirmed = m.prop(false);
    vm.activePaymentSource = m.prop(args.activePaymentSource || null);
    vm.defaultPaymentSource = args.defaultPaymentSource ? m.prop(args.defaultPaymentSource) : Payment.findDefault();
    vm.paymentSourceChanged = m.prop(false);
    return vm;
  },

  controller: function(args) {
    var ctrl = this;
    var vm = ctrl.vm;

    ctrl.vmApp.refreshPickups();

    ctrl.subscription = m.prop(null);
    // Subscription.find(ctrl.vmApp.SUBSCRIPTION_PREFIX).then(ctrl.subscription).then(function(subscription) {
    //   if (!subscription || !subscription.id()) {
    //     alert('Your Stampr Passenger account is incomplete.\n\nYou are being redirected to Settings to choose a subscription level -- either Free or Paid.');
    //     m.route('/settings');
    //     return;
    //   }
    //   return subscription;
    // });

    ctrl.reset = function() {
      m.startComputation();
      ctrl.vm = Pickup.vm();
      m.endComputation();
    };
    ctrl.setPickupLocation = function(coords) {
      m.startComputation();
      vm.pickup().location().coords = coords;
      m.endComputation();
    };
    ctrl.setPickupAddress = function(address) {
      m.startComputation();
      vm.pickup().location().address = address;
      m.endComputation();
    };
    ctrl.overrideAddress = function(address) {
      m.startComputation();
      vm.manuallyEnteredAddress(address);
      vm.pickup().location().address = address;
      m.endComputation();
    };
    ctrl.toggleConfirmed = function() {
      if (!ctrl.isLocationServiced()) {
        alert('This zip code is not currently serviced. Please visit the help pages for a complete list of zip codes that are serviced in your region.');
        return;
      }
      m.startComputation();
      // this clears the manually entered address which is not desirable
      // vm.manuallyEnteredAddress(null); // clear overridden address on map selection change
      if (vm.confirmed()) {
        vm.confirmed(false);
        vm.location.static(false);
        ctrl.setPickupLocation(null);
        ctrl.setPickupAddress(null);
      }
      else {
        vm.confirmed(true);
        vm.location.static(true);
        ctrl.setPickupLocation(vm.location.value());
        ctrl.setPickupAddress(vm.location.address());
      }
      m.endComputation();
    };
    ctrl.createPickup = function(deferredAsyncPromise, when) {
      if (ctrl.vmApp.isPickupActive()) {
        if (!confirm('There is already a pickup scheduled.  You can schedule another pickup, but you will no longer be able to modify your previous pickup shipments.\n\nWould you like to continue?')) {
          return deferredAsyncPromise.resolve();
        }
      }
      var pickupWasCreated = false;
      var model = vm.pickup();
      if (when === 'schedule_future') {
        let scheduleTime = prompt('When would you like us to come? e.g. "tomorrow at 3pm" or "next tuesday at 11am"');
        if (!scheduleTime && !confirm('If you do not give us a time we will come tomorrow at the earliest availability.\n\nIf you are not available we will not try again, and you will need to schedule another pickup.\n\nWould you like to continue without telling us when?')) {
          deferredAsyncPromise.resolve();
          return;
        }
        model.pickup_instructions(model.pickup_instructions() + ' --- Schedule pickup for: ' + scheduleTime);
      }
      async.waterfall([
        function step_collectPayment(next) {
          var pickupFeeAmount = when === 'schedule_now' ? 500 : 0;
          if (pickupFeeAmount > 0) { // if there is a pickup fee
            var payment = vm.activePaymentSource() || vm.defaultPaymentSource();
            if (!payment) {
              setTimeout(function() { handleAddPayment(ctrl, args); }, 0);
              return next(new Error('Immediate pickups require a fee and no payment method exists.\n\nPlease add a credit card to continue with an immediate pickup.'));
            }
            payment.charge(pickupFeeAmount, 'Stampr Passenger pickup fee').then(function(charge) {
              global.logger.info('Charge completed', charge);
              setTimeout(next, 0, null, charge);
              return charge;
            }, next);
          }
          else {
            next(null, null);
          }
        },
        function step_createPickup(charge, next) {
          var data = {
            meta: model.meta(),
            region: model.region(),
            authorization: model.authorization(),
            amount: (charge ? charge.amount : 0) || 0,
            location: model.location(),
            pickup_instructions: model.pickup_instructions(),
            item_count: model.item_count(),
            type: model.type(),
            paid: true,
            authorization: charge ? charge.id : 'unrecorded',
          };
          var pickup = PickupModel.create(data);
          pickup.then(function(pickup) {
            global.logger.info('Pickup created', pickup);
            setTimeout(next, 0, null, charge, pickup);
            pickupWasCreated = true;
            return pickup;
          }, next);
        },
        // function step_createDispatch(charge, pickup, next) {
        //   // return setTimeout(next, 1000, new Error('simulate error'));
        //   var customerName = ctrl.vmApp.session.profile.name;
        //   var customerPhone = ctrl.vmApp.session.profile.phone;
        //   var formatted_address = ((pickup.location().address || {}).formatted_address || '');
        //   var dispatch = Dispatch.create(pickup.pickupId(), customerName, customerPhone, formatted_address, pickup.pickup_instructions());
        //   dispatch.then(function(dispatch) {
        //     global.logger.info('Dispatch created', dispatch);
        //     setTimeout(next, 0, null, charge, pickup, dispatch);
        //     return dispatch;
        //   }, next);
        // },
        // function step_bindDispatchToPickup(charge, pickup, dispatch, next) {
        //   pickup.dispatch(dispatch.dispatchId()).then(function(data) {
        //     global.logger.info('Dispatch bound to pickup', dispatch);
        //     setTimeout(next, 0, null, charge, pickup, dispatch);
        //     return data;
        //   }, next);
        // },
      ], function(err, charge, pickup, dispatch) {
        deferredAsyncPromise.resolve();
        ctrl.vmApp.refreshPickups();
        if (err) {
          alert('Error: ' + err.message);
          if (pickupWasCreated) { // if pickup was created, then still route to dispatched
            m.route('/dispatched');
          }
          return;
        }
        m.route('/dispatched');
      });
    };
    ctrl.setActivePaymentSource = function(payment) {
      m.startComputation();
      vm.activePaymentSource(payment);
      m.endComputation();
    };
    ctrl.getCurrentLocationZip = function() {
      var addressRecord = ctrl.vm.location.address() || {};
      return addresses.getPostalCode(addressRecord);
    };
    ctrl.isLocationServiced = function() {
      var zip = ctrl.getCurrentLocationZip();
      var serviced = ctrl.vmApp.regionZipCodes().indexOf(zip) > -1;
      return serviced;
    };
    vm.location.onMapChanged = function() {
      m.startComputation();
      vm.manuallyEnteredAddress(null);
      m.endComputation();
    };

    // if the user has not changed the payment, then use the default
    if (!vm.paymentSourceChanged()) {
      vm.activePaymentSource = m.prop(new Payment());
      Payment.findDefault().then(vm.activePaymentSource);
    }

    if (ctrl.vmApp.isOpenForBusiness()) {
      tour.emitDelayed('pickup:first-run:show');
    }
  },
  view: tabbedLayout(function(ctrl, args) {
    var vmApp = ctrl.vmApp;
    var vm = ctrl.vm;
    var addressRecord = vm.location.address() || {};
    var formatted_address = (addressRecord.formatted_address || '');
    var isLocationServiced = ctrl.isLocationServiced();
    var address = vm.manuallyEnteredAddress() ? vm.manuallyEnteredAddress().formatted_address : formatted_address.split(',')[0].trim();
    var activePaymentSource = vm.activePaymentSource() || vm.defaultPaymentSource();
    var activePaymentSourceBrand = activePaymentSource ? activePaymentSource.brand() : null;
    var subscription = ctrl.subscription && ctrl.subscription() ? ctrl.subscription() : new Subscription();
    var pickupFee = 0;//parseInt(subscription.getPlanSetting('PICKUP_FEE'), 10) || 0;
    var maxShipments = 0;//parseInt(subscription.getPlanSetting('MAX_SHIPMENTS'), 10) || 0;
    global.logger.debug('activePaymentSourceBrand', activePaymentSourceBrand, checkout.cardTypeFromBrand(activePaymentSourceBrand));
    // if (vmApp.isPickupActive()) {
    //   return m(message, {
    //     type: 'info',
    //     outline: true,
    //     content: [
    //       m('h5', 'Pickup already scheduled'),
    //       m('p', 'You cannot schedule another pickup while one is already active.'),
    //       m('p', 'You can track the progress of your courier as well as add items to be picked up by clicking on the button below.'),
    //       m('div', { style: 'text-align:right;' }, [
    //         m(button, {
    //           raised: true,
    //           label: 'Track Courier Arrival',
    //           url: {
    //             href: '/dispatched',
    //             config: m.route,
    //           },
    //         }),
    //       ]),
    //     ],
    //   });
    // }
    var selectionOverlayLabel = vm.confirmed() ? 'Pickup location selected' : 'Where should we meet you?';
    if (!isLocationServiced) {
      selectionOverlayLabel = 'Location not serviced';
    }
    return m('div.pe-fit', { style:'overflow:hidden;', class: vm.confirmed() ? 'confirmed' : '' }, [
      m('div.pickup-search-overlay.layout.horizontal', {
        onclick: handleEditAddress.bind(null, ctrl, args),
      }, [
        m('div.block.flex.pickup-search-overlay-address', [
          address || m.trust('<div style="text-align:center;">No address found</div>'),
        ]),
        m('div.block', [
          !isLocationServiced ? null : m(linkButton, {
            label: 'Edit',
          }),
        ]),
        m(shadow, {
          z: 1,
        }),
      ]),
      m('div.pe-fit', [
        m('div.pickup-map-center-overlay.layout.horizontal', {
          class: isLocationServiced ? '' : 'location-not-serviced',
          onclick: ctrl.toggleConfirmed.bind(ctrl),
        }, [
          m('span.flex.block', selectionOverlayLabel),
          m(icon, {
            msvg: vm.confirmed() ? iconLockClosed : iconLockOpen,
          }),
        ]),
        m('div.pe-fit.pickup-map-backdrop', ' '),
        m(selectLocation, vm.location),
      ]),
      m('div.pickup-selected-bottom-sheet', [
        m('div.pickup-payment-info.pe-fit.layout.vertical', [
          m('div.block.flex.layout.vertical', [
            // m('div.block.layout.vertical', { style: 'border:1px solid rgba(0,0,0,.15);' }, [
            //   m('div.block.pickup-summary-block', [
            //     m('div.layout.horizontal', [
            //       m('div.block', [
            //         m(icon, {
            //           msvg: checkout.cardTypeToSvg(activePaymentSourceBrand),
            //         }),
            //       ]),
            //       !activePaymentSource ? null : m('div.flex.block.credit-card-last-four', { class: checkout.cardTypeFromBrand(activePaymentSourceBrand), style:'padding-left:8px;' }, activePaymentSource.last4()),
            //       !vm.paymentSourceChanged() ? m(linkButton, {
            //         onclick: handleAddPayment.bind(null, ctrl, args),
            //         label: 'Change',
            //       }) : m(linkButton, {
            //         onclick: function() {
            //           m.startComputation();
            //           vm.activePaymentSource = Payment.findDefault();
            //           vm.paymentSourceChanged(false);
            //           m.endComputation();
            //         },
            //         label: 'Use Default',
            //       }),
            //     ]),
            //   ]),
            //   m('div.block.flex', { style: 'border-top:1px solid rgba(0,0,0,.15);' }, [
            //     m('div.layout.horizontal', [
            //       m('div.flex.block.pickup-summary-block', { style: 'border-right:1px solid rgba(0,0,0,.15);' }, [
            //         m('div.layout.horizontal', [
            //           m('div.block.flex', [
            //             m.trust('<b>Pickup fee</b><br>'),
            //             Payment.amountToLocale(pickupFee, 'usd'),
            //           ]),
            //           // m('div.block', m.trust('<small style="color:rgba(0,0,0,.65);">0 credits available</small>')),
            //           m('div.block', ' '),
            //         ]),
            //       ]),
            //       m('div.flex.block.pickup-summary-block', m.trust('<b>Shipment limit</b><br>' + (0 === maxShipments ? 'Unlimited' : 'Up to ' + maxShipments) + ' regular items')),
            //     ]),
            //   ]),
            //   // m('div.block.pickup-summary-block', { style: 'border-top:1px solid rgba(0,0,0,.15);' }, [
            //   //   m.trust('<b>About how heavy is your pickup?</b><br>'),
            //   //   m('div.layout.horizontal.pickup-type-selection', [
            //   //     createPickupSizeSelectionItem(vm.pickup().type, {
            //   //       label: '10 lbs. or less',
            //   //       value: 'small',
            //   //       graphic: graphicChick,
            //   //     }),
            //   //     createPickupSizeSelectionItem(vm.pickup().type, {
            //   //       label: '11-50 lbs.',
            //   //       value: 'regular',
            //   //       graphic: graphicCat,
            //   //     }),
            //   //     createPickupSizeSelectionItem(vm.pickup().type, {
            //   //       label: 'Oversized or Heavy',
            //   //       value: 'oversized',
            //   //       graphic: graphicHorse,
            //   //     }),
            //   //   ]),
            //   // ]),
            // ]),
          ]),
          m('div.block', [
            m(list, {
              header: {
                title: 'When would you like your items picked up?',
              },
              after: renderPickupButtons(ctrl, args),
            }),
          ]),
        ]),
      ]),
    ]);
  }, {
    title: m('span.tabbed-layout-text-title', 'Request pickup'),
  }),
});
