'use strict';

var m = require('mithril');

var textfield = require('polythene/textfield/textfield');
var iconClear = require('mmsvg/google/msvg/content/clear');

var dialogs = require('../shared/dialogs.js');
var checkout = require('../shared/checkout.js');
var autocomplete = require('../shared/autocomplete.js');

module.exports = function(ctrl, args, opts, callback) {
  var model = {
    name: m.prop(''),
    number: m.prop(''),
    cvc: m.prop(''),
    exp_month: m.prop(''),
    exp_year: m.prop(''),
  };

  var updateState = function(target) {
    return function(state) {
      // FIXME: since icons are wrapped in m() they cannot be used like this.  need access to direct icon
      // switch (target) {
      //   case 'number':
      //     global.logger.debug('number element', state.el);
      //     state.el.style.backgroundImage = 'url("data:image/svg+xml;utf8,' + checkout.cardNumberToTypeSvg(state.value) + '")';
      //   break;
      // }
      model[target](state.value);
    };
  };
  var content = m('form.form.add-payment-dialog[autocomplete="on"]', {
    // XXX: onsubmit wouldn't fire no matter what. hacky workaround that gives us autocomplete
    // for whatever reason triggering submit via js fails.  putting a regular submit button succeeds
    // this exists just-in-case
    config: function(el, isInit) {
      if (!isInit) {
        el.onsubmit = function(event) {
          event.preventDefault();
          global.logger.debug('form submit prevented');
          return false;
        };
      }
    }
  }, [
    m(textfield, {
      type: 'tel',
      label: 'Cardholder',
      name: 'cc-name',
      floatingLabel: true,
      required: true,
      error: 'Please enter a valid name',
      hideRequiredMark: true,
      value: model['name'],
      getState: updateState('name'),
      config: autocomplete.config('cc-name'),
    }),
    m(textfield, {
      type: 'tel',
      label: 'Credit Card Number',
      name: 'cc-number',
      floatingLabel: true,
      error: 'Please enter a valid card number',
      required: true,
      hideRequiredMark: true,
      validate: function(value) {
        return {
          valid: checkout.validateCardNumber(value),
          error: 'Card number is not valid',
        };
      },
      value: model['number'],
      getState: updateState('number'),
      config: autocomplete.config('cc-number'),
    }),
    m('div.layout.horizontal', [
      m('div.block.flex', [
        m(textfield, {
          type: 'tel',
          label: 'Expiration Month',
          name: 'cc-exp-month',
          floatingLabel: true,
          required: true,
          error: 'Please enter a valid month e.g. 1 or 01 for January',
          hideRequiredMark: true,
          value: model['exp_month'],
          getState: updateState('exp_month'),
          validate: function(value) {
            return {
              valid: checkout.validateExpiry(value, '2199'), // far future year
              error: 'Expiration month is not valid',
            };
          },
          config: autocomplete.config('cc-exp-month'),
        }),
      ]),
      m('div.block.flex', [
        m(textfield, {
          type: 'tel',
          label: 'Expiration Year',
          name: 'cc-exp-year',
          floatingLabel: true,
          error: 'Please enter a valid year e.g. 22 or 2022',
          required: true,
          hideRequiredMark: true,
          value: model['exp_year'],
          getState: updateState('exp_year'),
          validate: function(value) {
            return {
              valid: checkout.validateExpiry(1, value),
              error: 'Expiration year is not valid',
            };
          },
          config: autocomplete.config('cc-exp-year'),
        }),
      ]),
    ]),
    m(textfield, {
      type: 'tel',
      label: 'CVC',
      name: 'cc-csc',
      floatingLabel: true,
      required: true,
      error: 'Please enter a valid CVC code',
      hideRequiredMark: true,
      validate: function(value) {
        return {
          valid: checkout.validateCVC(value),
          error: 'CVC is not valid',
        };
      },
      value: model['cvc'],
      getState: updateState('cvc'),
      config: autocomplete.config('off'),
    }),
  ]);
  return {
    title: 'Add payment method',
    body: content,
    fullscreen: {
      icon: {
        msvg: iconClear,
        events: {
          onclick: function() {
            callback(null, null);
          },
        },
      },
      action: opts.hideEdit ? null : {
        label: 'Save',
        events: {
          onclick: function() {
            m.startComputation();
            var valid = true;
            var errors = [];
            if (!checkout.validateCardNumber(model.number())) {
              valid = false;
              errors.push('Card number is not valid');
            }
            if (!checkout.validateExpiry(model.exp_month(), model.exp_year())) {
              valid = false;
              errors.push('Expiration date is not valid');
            }
            if (!checkout.validateCVC(model.cvc())) {
              valid = false;
              errors.push('Card CVC is not valid');
            }
            for (var k in model) {
              var isValValid = (model[k]() || '').trim().length;
              valid = valid && isValValid;
              if (!isValValid) {
                errors.push(k + ' is not valid');
              }
            }
            if (valid) {
              global.logger.debug('card is valid');
              callback(null, model);
            }
            else {
              global.logger.warn('card entered is invalid');
              alert('There were some problems with the values entered:\n\n- ' + errors.join('\n- '));
            }
            m.endComputation();
          },
        },
      },
    },
  };
};
