'use strict';

var m = require('mithril');
var list = require('polythene/list/list');
var listTile = require('polythene/list-tile/list-tile');
var radioButton = require('polythene/radio-button/radio-button');
var button = require('polythene/button/button');

var iconClear = require('mmsvg/google/msvg/content/clear');

var Payment = require('../models/payment.js');
var shipmentOptions = require('../shared/shipment-options.js');

var renderListContent = function(opts) {
  var items = opts.items ? opts.items() : [];
  var selectedValue = opts.selectedValue();
  var displayPrices = opts.displayPrices;
  return items.map(function(item) {
    var isSelected = item.value === selectedValue;
    var name = item.name;
    if (displayPrices) {
      name += ' - ' + Payment.amountToLocale(shipmentOptions.findSizePrice(item.price, opts.size), item.currency);
    }
    return m(listTile, {
      title: name,
      highSubtitle: item.description || null,
      front: m(radioButton, {
        name: 'list-picker-dialog',
        checked: isSelected,
        value: item.value,
        getState: (state) => (opts.selectedValue(state.value)),
      }),
    });
  });
};

module.exports = function(ctrl, args, opts, callback) {
  opts = opts || {};
  var content = renderListContent(opts);
  return {
    title: opts.title || 'Select an item',
    body: content,
    fullscreen: {
      class: 'flush-dialog',
      icon: {
        msvg: iconClear,
        events: {
          onclick: function() {
            callback(null, null);
          },
        },
      },
      action: {
        label: 'Done',
        events: {
          onclick: function() {
            callback(null, opts);
          },
        },
      },
    },
  };
};
