'use strict';

var m = require('mithril');

var Login = module.exports = {
  view: () => {
    return m('div.layout.center-center', { style: 'height:100%;' }, [
      m('div#login-container', ''),
    ]);
  }
};
