'use strict';

var m = require('mithril');

var svg = require('polythene/svg/svg');
var icon = require('polythene/icon/icon');
var list = require('polythene/list/list');
var listTile = require('polythene/list-tile/list-tile');
var iconButton = require('polythene/icon-button/icon-button');
var switchCmp = require('polythene/switch/switch');

var iconArrowBack = require('mmsvg/google/msvg/navigation/arrow-back');
var iconCreditCard = require('mmsvg/google/msvg/action/credit-card');
var iconAutoRenew = require('mmsvg/google/msvg/action/autorenew');

var Payment = require('../models/payment.js');
var Subscription = require('../models/subscription.js');

var screen = require('../shared/screen.js');
var dialogs = require('../shared/dialogs.js');
var checkout = require('../shared/checkout.js');
var shipmentOptions = require('../shared/shipment-options.js');
var tabbedLayout = require('../layouts/tabbed.js');

var stamprSvg = require('../../common/svg.js');
var logoPoweredByStripeOutline = stamprSvg.stripe_logo_poweredby_outline;

var promiseErrorHandler = require('../shared/promise-error-handler.js');

var findConnection = function(name) {
  var search = 'CA-' + name + '-';
  return integrations.getIdentity(search, true);
};

var SettingSection = {
  view: function(ctrl, args) {
    return m(list, {
      header: !args.title ? null : {
        title: args.title,
      },
      borders: args.borders || false,
      tiles: args.tiles || [],
      after: (!args.actions ? null : [
        m('div', { class: args.actionClass || '', style: 'padding-right:15px;padding-left:72px;' + (args.actionStyle || '') }, args.actions || []),
      ]),
    });
  },
};

var handleOptionChange = function(ctrl, args, source, items, after) {
  global.logger.debug('settings: handleOptionChange', source(), items);
  var opts = {
    items: m.prop(items),
    selectedValue: m.prop(source()),
  };
  dialogs.show('listPicker', ctrl, args, opts, function(err, model) {
    if (model) {
      source(model.selectedValue());
      after && after(args);
    }
  });
};

var handleSubscriptionChange = function(ctrl, args) {
  var source = ctrl.activePlanId;
  var items = ctrl.plans();
  var after = ctrl.saveSubscriptionUpdate;
  items = items.map(function(item) {
    return {
      value: item.id,
      name: Subscription.planNameToLocale(item.name),
      description: Subscription.planToSummary(item),
    };
  });
  handleOptionChange(ctrl, args, source, items, after);
};

var handlePaymentChange = function(ctrl, args) {
  var source = ctrl.activeDefaultSource;
  var items = ctrl.paymentSources();
  var after = ctrl.savePaymentUpdate;
  if (items.length) {
    items = items.map(function(payment) {
      var brandIcon = m(icon, {
        msvg: checkout.cardTypeToSvg(payment.brand()),
      });
      return {
        value: payment.id(),
        name: [
          brandIcon,
          ' x' + payment.last4(),
        ],
        description: payment.name() + ' Expires ' + payment.exp_month() + '/' + payment.exp_year(),
      };
    });
    handleOptionChange(ctrl, args, source, items, after);
  }
  else {
    handleAddPayment(ctrl, args);
  }
};

var handleAddPayment = function(ctrl, args) {
  dialogs.show('addPayment', ctrl, args, null, function(err, result) {
    if (err) {
      global.logger.warn('settings: ', err);
      return;
    }
    else if (!result) {
      global.logger.debug('settings: no payment method saved');
      return;
    }
    global.logger.info('settings: payment data received', JSON.stringify(result));
    checkout.createToken({
      name: result.name(),
      number: result.number(),
      cvc: result.cvc(),
      exp_month: result.exp_month(),
      exp_year: result.exp_year(),
    }, function(err, response) {
      if (err) {
        global.logger.error('settings: ', err);
        alert('Problem saving your card: ' + err.message);
        return;
      }
      global.logger.debug('settings: stripe create token resposne', response);
      Payment.create(response.id).then(function(payment) {
        ctrl.defaultPaymentSource = Payment.findDefault();
        return payment;
      }, promiseErrorHandler());
    });
  });
};

/*
personal info - name, email, phone number
payment method
notifications
  arriving
  pickup
  shipped
connected accounts
  ebay
*/

var Settings = module.exports = screen.create({
  controller: function() {
    var ctrl = this;
    ctrl['notifications:arriving'] = m.prop(true);
    ctrl['notifications:pickedup'] = m.prop(true);
    ctrl['notifications:shipped'] = m.prop(true);

    ctrl.integrationState = ctrl.vmApp.integrationState;

    ctrl.paymentSources = m.prop([]);
    process.nextTick(function() { Payment.list().then(ctrl.paymentSources); }); // HACK: don't block
    ctrl.defaultPaymentSource = m.prop(new Payment());
    ctrl.activeDefaultSource = m.prop(null);
    process.nextTick(function() {
      Payment.findDefault().then(ctrl.defaultPaymentSource).then(function(source) {
        ctrl.activeDefaultSource(source.id());
        return source;
      });
    }); // HACK: don't block
    ctrl.plans = m.prop([]);
    // process.nextTick(function() {
    //   Subscription.availablePlans().then(function(plans) {
    //     var filteredPlans = (plans || []).filter(function(plan) {
    //       var isPlanDisplayed;
    //       if (plan) {
    //         var metadata = plan.metadata || {};
    //         isPlanDisplayed = plan.id.indexOf(ctrl.vmApp.SUBSCRIPTION_PREFIX) === 0;
    //         isPlanDisplayed = isPlanDisplayed && !metadata['HIDE_SELECTION']; // allows plans to be hidden from selection list
    //       }
    //       else {
    //         isPlanDisplayed = false;
    //       }
    //       global.logger.debug('settings: filtering plans: %s yes? ', plan.id, isPlanDisplayed);
    //       return isPlanDisplayed;
    //     });
    //     ctrl.plans(filteredPlans);
    //     return plans;
    //   }, promiseErrorHandler.fatal());
    // }); // HACK: don't block
    ctrl.activePlanId = m.prop(null);
    ctrl.activeSubscription = m.prop(null);
    // process.nextTick(function() {
    //   Subscription.find(ctrl.vmApp.SUBSCRIPTION_PREFIX).then(ctrl.activeSubscription).then(function(subscription) {
    //     ctrl.activePlanId(subscription.plan().id);
    //     return subscription;
    //   });
    // }); // HACK: don't block

    ctrl.savePaymentUpdate = function() {
      var sourceId = ctrl.activeDefaultSource();
      Payment.setDefault(sourceId).then(function(customerData) {
        ctrl.defaultPaymentSource = Payment.findDefault();
        return customerData;
      }, promiseErrorHandler(function() {
        alert('There was a problem updating your default payment.  It has not been changed.');
      }));
    };

    ctrl.saveSubscriptionUpdate = function(args) {
      var planId = ctrl.activePlanId();
      var activePlan = ctrl.activeSubscription && ctrl.activeSubscription() ? ctrl.activeSubscription().plan() : null;
      var newPlan = ctrl.plans().filter(function(plan) {
        return plan.id === planId;
      })[0] || null;
      var errorHandler = function(err) {
        if (err.message.indexOf('no attached payment source') > -1) {
          alert('Your subscription could not be updated because you have not entered a payment method.\n\nPlease add a default payment method now.');
          handlePaymentChange(ctrl, args);
        }
        else {
          alert('There was a problem updating your subscription. It has not been changed.');
        }
      };
      var successHandler = function(subscription) {
        var activeSubscription = subscription;
        var activePlan = activeSubscription && activeSubscription.plan() ? activeSubscription.plan() : null;
        var planMetadata = ((activePlan || {}).metadata || {});
        var isBusinessAccount = true; // subscriptions removed.  always business account
        // var isBusinessAccount = 'BUSINESS' in planMetadata;
        shipmentOptions.isFreeShippingEligible = isBusinessAccount;
        return subscription;
      };
      var confirmMessage = [
        'You are about to change your subscription.\n',
        'Current Plan:\t' + (activePlan ? Subscription.planToSummary(activePlan) : 'No plan selected'),
        'New Plan:\t\t' + Subscription.planToSummary(newPlan),
      ];
      if (activePlan) {
        confirmMessage.push('');
        if (activePlan.amount === 0) {
          confirmMessage.push('Your payment method will be charged and your subscription will be immediately available.');
        }
        else {
          confirmMessage.push('Your subscription will be immediately available and you will be billed beginning next period if you have a balance.');
        }
      }
      var ok = confirm(confirmMessage.join('\n'));
      if (ok) {
        if (ctrl.activeSubscription() && ctrl.activeSubscription().id()) {
          ctrl.activeSubscription().update(planId).then(successHandler, errorHandler);
        }
        else {
          ctrl.activeSubscription = Subscription.create(planId).then(successHandler, errorHandler);
        }
      }
      else {
        activePlan && ctrl.activePlanId(activePlan.id);
      }
    };

    ctrl.loggedOut = m.prop(false);
  },
  view: tabbedLayout(function(ctrl, args) {
    var vmApp = ctrl.vmApp;
    var defaultSource = ctrl.defaultPaymentSource();
    var activeSubscription = ctrl.activeSubscription();
    var activePlan = activeSubscription && activeSubscription.plan() ? activeSubscription.plan() : null;
    var planMetadata = ((activePlan || {}).metadata || {});
    var isBusinessAccount = true; // subscriptions removed.  always business account
    // var isBusinessAccount = 'BUSINESS' in planMetadata;
    return ctrl.loggedOut() ? m('div', ' ') : [
      m(SettingSection, {
        title: 'Payments',
        tiles: [
          m(listTile, {
            title: 'Default Method',
            subtitle: defaultSource && defaultSource.brand() ? defaultSource.brand() + ' **** ' + defaultSource.last4() : 'Please enter a payment method',
            selectable: true,
            ink: true,
            front: m(icon, {
              type: 'medium',
              msvg: iconCreditCard,
            }),
            events: {
              onclick: function() {
                handlePaymentChange(ctrl, args);
              },
            },
          }),
        ],
      }),

      // m(SettingSection, {
      //   title: 'Subscription',
      //   tiles: [
      //     m(listTile, {
      //       title: 'Subscribed Plan',
      //       subtitle: activePlan ? activeSubscription.getPlanSummary() : 'Choose a Stampr Passenger subscription to get started.',
      //       selectable: true,
      //       ink: true,
      //       front: m(icon, {
      //         type: 'medium',
      //         msvg: iconAutoRenew,
      //       }),
      //       events: {
      //         onclick: function() {
      //           handleSubscriptionChange(ctrl, args);
      //         },
      //       },
      //     }),
      //   ],
      // }),

      // m(SettingSection, {
      //   title: 'Notifications',
      //   tiles: [
      //     m(listTile, {
      //       title: 'Arriving',
      //       highSubtitle: 'The courier has made it to the pickup location',
      //       secondary: {
      //         content: m(switchCmp, {
      //           checked: ctrl['notifications:arriving'](),
      //           getState: (state) => (ctrl['notifications:arriving'](state.checked)),
      //         }),
      //       },
      //     }),
      //     m(listTile, {
      //       title: 'Picked Up',
      //       highSubtitle: 'All items have been checked in',
      //       secondary: {
      //         content: m(switchCmp, {
      //           checked: ctrl['notifications:arriving'](),
      //           getState: (state) => (ctrl['notifications:arriving'](state.checked)),
      //         }),
      //       },
      //     }),
      //     m(listTile, {
      //       title: 'Shipped',
      //       highSubtitle: 'Item has been mailed and a tracking code has been created',
      //       secondary: {
      //         content: m(switchCmp, {
      //           checked: ctrl['notifications:shipped'](),
      //           getState: (state) => (ctrl['notifications:shipped'](state.checked)),
      //         }),
      //       },
      //     }),
      //   ],
      // }),

      // m(SettingSection, {
      //   title: 'Connected Accounts',
      //   tiles: !isBusinessAccount ?
      //     m('div.block', { style: 'padding:15px;' }, !activePlan ? ' ' : 'A Business Subscription is required in order to connect accounts.') :
      //     Object.keys(vmApp.integrations).map((service) => {
      //       var driver = integrations.getDriver('stampr-' + service);
      //       var schema = vmApp.integrations[service] || {};
      //       var connection = findConnection(driver.name);
      //       var connectionName = connection ? connection.connection : null;
      //       return makeConnectedAccountTile(service, schema, connectionName, ctrl.integrationState());
      //     }),
      // }),

      m(SettingSection, {
        title: 'Security',
        tiles: [
          m(listTile, {
            title: 'Log out',
            subtitle: 'You will immediately be signed out of your account',
            ink: true,
            events: {
              onclick: function() {
                m.startComputation();
                ctrl.loggedOut(true);
                // FIXME: shouldn't call to global scope
                global.StamprPassenger.auth.client.close();
                m.endComputation();
              },
            },
          }),
        ],
      }),

      m('div', { style: 'margin-top: 80px; padding-bottom: 15px; color:rgba(0,0,0,.4); font-style:italic; font-size:12px; text-align:center;', }, 'Stampr Passenger v' + global.StamprEnv.VERSION),
    ];
  }, {
    title: m('span.tabbed-layout-text-title', 'Settings'),
  }),
});
