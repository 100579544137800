'use strict';

var m = require('mithril');
var db = require('../lib/db.js');
var Base = require('./base.js');

function Pickup(data) {
  data = data || {};
  Base.call(this, data);
  this.pickupId = m.prop(data.pickupId || null);
  this.test = m.prop('test' in data ? data.test : false);
  this.region = m.prop(data.region || null);
  this.authorization = m.prop(data.authorization || null);
  this.dispatchId = m.prop(data.dispatchId || null);
  this.item_count = m.prop(data.item_count || 0);
  this.amount = m.prop(data.amount || 0);
  this.location = m.prop(data.location || {});
  this.type = m.prop(data.type || null);
  this.pickup_instructions = m.prop(data.pickup_instructions || null);
  this.paid = m.prop('paid' in data ? data.paid : false);
  this.complete = m.prop('complete' in data ? data.complete : false);
}

Base.inheritedBy(Pickup);

Pickup.prototype.cancel = function() {
  var _this = this;
  var data = db.createPayload('cancel-pickup', {
    pickupId: this.pickupId(),
  });
  var changedPickup = db.request({ function: config.lambda.orders, data: data, type: Pickup });
  changedPickup.then(function(pickup) {
    _this.import(pickup);
    return pickup;
  });
  return changedPickup;
};

Pickup.prototype.dispatch = function(dispatchId) {
  var _this = this;
  var data = db.createPayload('dispatch-pickup', {
    pickupId: this.pickupId(),
    dispatchId: dispatchId,
  });
  var changedPickup = db.request({ function: config.lambda.orders, data: data, type: Pickup });
  changedPickup.then(function(pickup) {
    _this.import(pickup);
    return pickup;
  });
  return changedPickup;
};

Pickup.prototype.import = function(model) {
  Base.prototype.import.call(this, model);
  this.pickupId(model.pickupId());
  this.test(model.test());
  this.region(model.region());
  this.authorization(model.authorization());
  this.dispatchId(model.dispatchId());
  this.item_count(model.item_count());
  this.amount(model.amount());
  this.location(model.location());
  this.type(model.type());
  this.pickup_instructions(model.pickup_instructions());
  this.paid(model.paid());
  this.complete(model.complete());
};

Pickup.prototype.toJSON = function() {
  var data = Base.prototype.toJSON.call(this);
  data.pickupId = this.pickupId();
  data.test = this.test();
  data.region = this.region();
  data.authorization = this.authorization();
  data.dispatchId = this.dispatchId();
  data.item_count = this.item_count();
  data.amount = this.amount();
  data.location = this.location();
  data.type = this.type();
  data.pickup_instructions = this.pickup_instructions();
  data.paid = this.paid();
  data.complete = this.complete();
  return data;
};

Pickup.create = function(data) {
  var data = db.createPayload('create-pickup', data);
  return db.request({ function: config.lambda.orders, data: data, type: Pickup });
};

Pickup.read = function(pickupId) {
  var data = db.createPayload('read-pickup', {
    pickupId: pickupId,
  });
  return db.request({ function: config.lambda.orders, data: data, type: Pickup });
};

Pickup.list = function(complete) {
  var data = db.createPayload('list-pickups', {
    complete: void 0 === complete ? false : complete,
  });
  return db.request({ function: config.lambda.orders, data: data, type: Pickup });
};

Pickup.query = function(status, complete) {
  var data = db.createPayload('list-pickups', {
    status: status,
    complete: void 0 === complete ? false : complete,
  });
  return db.request({ function: config.lambda.orders, data: data, type: Pickup });
};

module.exports = Pickup;
