'use strict';

var m = require('mithril');
var textfield = require('polythene/textfield/textfield');

var iconClear = require('mmsvg/google/msvg/content/clear');

var autocomplete = require('../shared/autocomplete.js');

module.exports = function(ctrl, args, opts, callback) {
  opts = opts || {};
  var model = opts.model || {};
  var updateState = function(target) {
    return function(state) {
      // FIXME: since icons are wrapped in m() they cannot be used like this.  need access to direct icon
      // switch (target) {
      //   case 'number':
      //     global.logger.debug('number element', state.el);
      //     state.el.style.backgroundImage = 'url("data:image/svg+xml;utf8,' + checkout.cardNumberToTypeSvg(state.value) + '")';
      //   break;
      // }
      model[target](state.value);
    };
  };
  var content = m('form.form.add-payment-dialog[autocomplete="on"]', {
    // XXX: onsubmit wouldn't fire no matter what. hacky workaround that gives us autocomplete
    // for whatever reason triggering submit via js fails.  putting a regular submit button succeeds
    // this exists just-in-case
    config: function(el, isInit) {
      if (!isInit) {
        el.onsubmit = function(event) {
          event.preventDefault();
          global.logger.debug('form submit prevented');
          return false;
        };
      }
    }
  }, [
    m(textfield, {
      label: 'Recipient Name',
      name: 'name',
      floatingLabel: true,
      required: true,
      error: 'Please enter a valid name',
      hideRequiredMark: true,
      value: model['addressee'],
      getState: updateState('addressee'),
      config: autocomplete.config('name'),
    }),
    m(textfield, {
      label: 'Street Address',
      name: 'address-line1',
      floatingLabel: true,
      error: 'Please enter a valid street address',
      required: true,
      hideRequiredMark: true,
      value: model['street1'],
      getState: updateState('street1'),
      config: autocomplete.config('address-line1'),
    }),
    m(textfield, {
      label: 'Street Address2',
      name: 'address-line2',
      floatingLabel: true,
      required: false,
      value: model['street2'],
      getState: updateState('street2'),
      config: autocomplete.config('address-line2'),
    }),
    !model.street3() ? null : m(textfield, {
      label: 'Street Address',
      name: 'address-line3',
      floatingLabel: true,
      required: false,
      value: model['street3'],
      getState: updateState('street3'),
      config: autocomplete.config('address-line3'),
    }),
    m('div.layout.horizontal', [
      m('div.block.flex', [
        m(textfield, {
          label: 'City',
          name: 'address-level2',
          floatingLabel: true,
          required: true,
          hideRequiredMark: true,
          value: model['city'],
          getState: updateState('city'),
          config: autocomplete.config('address-level2'),
        }),
      ]),
      m('div.block.flex', [
        m(textfield, {
          label: 'State',
          name: 'address-level1',
          floatingLabel: true,
          required: true,
          hideRequiredMark: true,
          value: model['state'],
          getState: updateState('state'),
          config: autocomplete.config('address-level1'),
        }),
      ]),
      m('div.block.flex', [
        m(textfield, {
          label: 'Postal Code',
          name: 'postal-code',
          floatingLabel: true,
          required: true,
          hideRequiredMark: true,
          value: model['postal'],
          getState: updateState('postal'),
          config: autocomplete.config('postal-code'),
        }),
      ]),
    ]),
    m(textfield, {
      label: 'Country',
      name: 'country-name',
      floatingLabel: true,
      required: false,
      value: model['country'],
      getState: updateState('country'),
      config: autocomplete.config('country-name'),
    }),
  ]);
  return {
    title: 'Edit address',
    body: content,
    fullscreen: {
      icon: {
        msvg: iconClear,
        events: {
          onclick: function() {
            callback();
          },
        },
      },
      action: {
        label: 'Done',
        events: {
          onclick: function() {
            callback(null, model);
          },
        },
      },
    },
  };
};
