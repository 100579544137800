'use strict';

var m = require('mithril');
var moment = global.moment;

var fab = require('polythene/fab/fab');
var button = require('polythene/button/button');
var icon = require('polythene/icon/icon');
var iconButton = require('polythene/icon-button/icon-button');
var card = require('polythene/card/card');
var menu = require('polythene/menu/menu');
var list = require('polythene/list/list');
var listTile = require('polythene/list-tile/list-tile');

var Pickup = require('../models/pickup.js');
var Payment = require('../models/payment.js');
var Dispatch = require('../models/dispatch.js');
var Shipment = require('../models/shipment.js');

var screen = require('../shared/screen.js');
var dialogs = require('../shared/dialogs.js');
var pageLayout = require('../layouts/page.js');
// var dispatchMap = require('./dispatch-map.js');
var dispatchMap = require('./dispatch-map-swiftwrapper.js');
var message = require('./message.js');
var asyncButton = require('./async-button.js');

var iconAdd = require('mmsvg/google/msvg/content/add');
var iconClear = require('mmsvg/google/msvg/content/clear');
var iconMoreVert = require('mmsvg/google/msvg/navigation/more-vert');
var iconCommunicationMessage = require('mmsvg/google/msvg/communication/message');
var iconCommunicationCall = require('mmsvg/google/msvg/communication/call');

var shipmentOptions = require('../shared/shipment-options.js');

var promiseErrorHandler = require('../shared/promise-error-handler.js');
var filter = require('../shared/filter.js');


var shipmentsGrandTotal = function(shipments) {
  return Payment.amountToLocale(shipments.reduce(function(prev, curr) {
    return prev + curr.amount();
  }, 0), 'usd');
};

var createShipmentCard = function(shipment, vm) {
  var destination_address = shipment.destination_address() || {};
  var to_address1 = destination_address.addressee;
  var to_address2 = destination_address.street1;
  var extras = shipment.extras();
  var hasOptions = !shipment.isDefaultShippingHandling();
  var hasExtraInsurance = extras.insurance && extras.insurance !== '100';
  var hasExtraGiftWrap = extras.gift_wrap && extras.gift_wrap !== 'no_gift_wrap';
  var hasExtras = hasExtraInsurance || hasExtraGiftWrap;
  var hasActions = vm.actions() && vm.actions().length;
  var isDefault = !hasOptions && !hasExtras;
  var wasPaid = shipment.paid();
  var imageThumbnail = shipment.image_thumbnail();
  if (!imageThumbnail || !imageThumbnail.length) { // prevent broken images
    imageThumbnail = 'apple-touch-icon.png';
  }
  if (global.StamprEnv.ENV === 'development') {
    if (imageThumbnail && imageThumbnail.indexOf('shipments/') === 0) {
      imageThumbnail = 'https://testing-passenger.stam.pr/' + imageThumbnail;
    }
  }
  var pricing = vm.usePaymentDetails() ? shipment.payment_details() : {
    packing: shipmentOptions.getPrice('packing', shipment.packing(), shipment.size()),
    handling: shipmentOptions.getPrice('handling', shipment.handling(), shipment.size()),
    shipping: shipmentOptions.getPrice('shipping', shipment.shipping(), shipment.size()),
    insurance: shipmentOptions.getPrice('insurance', extras.insurance),
    gift_wrap: shipmentOptions.getPrice('gift_wrap', extras.gift_wrap),
  };
  var editUrl = {
    href: '/edit-shipment/' + shipment.itemId(),
    config: m.route,
  };
  var content = [];
  content.push({
    header: {
      class: 'pe-card__primary--tight',
      url: !hasActions || vm.disableEdit() ? null : editUrl,
      title: shipment.description() ? shipment.description() : to_address1,
      subtitle: shipment.description() ? to_address1 : to_address2,
      icon: {
        type: 'large',
        src: imageThumbnail,
      },
      after: !vm.displayTotal() ? null : [
        m('div.dispatched-shipment-card-price', Payment.amountToLocale(shipment.amount(), 'usd')),
      ],
    },
  });
  var headerBorder = true;
  if (vm.displayStatus() || vm.displayTimestamp()) {
    headerBorder = false;
    content.push({
      text: {
        class: 'pe-cardtext--tight',
        content: [
          !shipment.status() || !vm.displayStatus() ? null : m('div.dispatched-shipment-card-status-type', { class: vm.statusTheme() }, shipment.status()),
          !vm.displayTimestamp() ? null : createStatusTimestampBlock('Updated', shipment.updated(), shipment.source(), vm.statusTheme()),
        ],
      },
    });
  }
  if (vm.verbose() || !isDefault) {
    content.push({
      text: {
        class: 'pe-cardtext--tight ' + (headerBorder ? 'pe-card__actions--borders' : ''),
        content: [
          !hasOptions && !vm.verbose() ? null : m(list, {
            header: {
              title: 'Details',
            },
            borders: true,
            tiles: [
              renderShipmentOption('Size', 'size', shipment.size()),
              renderShipmentOption('Packing', 'packing', shipment.packing()),
              renderShipmentOption('Handling', 'handling', shipment.handling()),
              renderShipmentOption('Shipping', 'shipping', shipment.shipping()),
            ],
          }),
          !hasExtras ? null : m(list, {
            header: {
              title: 'Services',
            },
            borders: true,
            tiles: [
              !hasExtraInsurance ? null : renderShipmentOption('Insurance', 'insurance', extras.insurance),
              !hasExtraGiftWrap ? null : renderShipmentOption('Gift Wrap', 'gift_wrap', extras.gift_wrap),
            ],
          }),
        ],
      },
    });
  }
  else {
    content.push({
      text: {
        class: 'pe-cardtext--tight ' + (headerBorder ? 'pe-card__actions--borders' : ''),
        content: [
          m('span', 'Packing, Shipping, and Handling for this shipment are Standard.'),
        ],
      },
    });
  }
  if (hasActions) {
    content.push({
      actions: {
        class: 'pe-card__actions--borders',
        content: vm.actions(),
      },
    });
  }
  return m(card, {
    class: 'block dispatched-shipment-card',
    content: content,
  });
};

var createStatusTimestampBlock = function(label, timestamp, source, themeClass) {
  return m('div.dispatched-shipment-card-status-timestamp', { class: themeClass || '' }, [
    label,
    ' ',
    moment(timestamp).fromNow(),
    !source ? null : ' from ' + source,
  ]);
};

var createAddressBlock = function(address, wrapperTag) {
  address = address || {};
  address.addressee = address.addressee || ' ';
  address.street1 = address.street1 || ' ';
  address.street2 = address.street2 || '';
  address.street3 = address.street3 || '';
  address.city = address.city || ' ';
  address.state = address.state || ' ';
  address.postal = address.postal || ' ';
  return m(wrapperTag || 'p', [
    [
      address.street1,
      ' ',
      [ address.street2, address.street3 ].join(' ').trim(),
    ],
    m.trust('<br>'),
    [
      address.city,
      ', ',
      address.state,
      ' ',
      address.postal,
    ],
  ]);
};

var renderShipmentOption = function(title, name, value) {
  return m(listTile, {
    title: title,
    compact: true,
    secondary: {
      content: m('div.list-label', shipmentOptions.getName(name, value)),
    },
  });
};


var ShipmentCard = module.exports = {
  vm: function(data) {
    data = data || {};
    var vm = {};

    vm.shipment = m.prop(data.shipment || {});
    data.extras = data.extras || (data.shipment ? data.shipment.extras() : {});
    vm.extras = {
      insurance: m.prop(data.extras.insurance || '100'),
      gift_wrap: m.prop(data.extras.gift_wrap || 'no_gift_wrap'),
    };

    // functionality flags
    vm.usePaymentDetails = m.prop(data.usePaymentDetails || false);
    vm.actions = m.prop(data.actions || []);
    vm.verbose = m.prop('verbose' in data ? data.verbose : false);
    vm.displayTotal = m.prop('displayTotal' in data ? data.displayTotal : false);
    vm.displayStatus = m.prop('displayStatus' in data ? data.displayStatus : false);
    vm.displayTimestamp = m.prop('displayTimestamp' in data ? data.displayTimestamp : true);
    vm.disableEdit = m.prop('disableEdit' in data ? data.disableEdit : false);

    vm.statusTheme = m.prop(data.statusTheme || '');

    return vm;
  },
  controller: function(vm) {
  },
  view: function(ctrl, vm) {
    var shipment = vm.shipment ? vm.shipment() : {};
    return createShipmentCard(shipment, vm);
  },
};
