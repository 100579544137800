'use strict';

var async = require('async');
var Auth0Lock = global.Auth0Lock;
var AuthenticationClient = require('./authentication-client.js');
var Session = AuthenticationClient.Session;

var auth = module.exports = {
  client: null,
  lock: null,

  _hashLogin: function(lock, callback) {
    var hash = lock.parseHash();
    if (hash) {
      if (hash.error) {
        callback(new Error('Hash Error: ' + hash.error));
        return;
      }
      else {
        lock.getProfile(hash.id_token, function(err, profile) {
          if (err) {
            return callback(err);
          }
          var session = new Session(hash.id_token, profile);
          callback(null, session);
        });
        return;
      }
    }
    else {
      callback(null, null);
      return;
    }
  },

  _resume: function(env_cognito, session, callback) {
    var authClient = auth.client = AuthenticationClient.resumeSession({
      identityPoolId: env_cognito.identityPoolId,
      authRoleArn: env_cognito.authRoleArn,
      providerEndpoint: env_cognito.providerEndpoint,
      emitReadyEvent: false,
    });
    callback(null, authClient);
  },

  init: function(env_auth0, env_cognito, callback) {
    var lock = auth.lock = new Auth0Lock(env_auth0.clientId, env_auth0.hostname);
    async.waterfall([
      async.apply(auth._hashLogin, lock),
      function(session, next) {
        auth._resume(env_cognito, session, next);
      },
    ], function(err, authClient) {
      if (err) {
        return callback(err);
      }
      callback(null, {
        client: authClient,
        lock: lock,
      });
    });
  },

  prompt: function(opts, callback) {
    opts = opts || {};
    opts.authParams = opts.authParams || {};
    opts.authParams.scope = 'openid stampr_stripe';
    opts.responseType = 'token';
    opts.container = 'container' in opts ? opts.container : null; //'login-container';
    opts.closable = 'closable' in opts ? opts.closable : false;
    opts.focusInput = 'focusInput' in opts ? opts.focusInput : true;
    opts.primaryColor = 'primaryColor' in opts ? opts.primaryColor : '#cd3724';
    opts.scope = 'primaryColor' in opts ? opts.primaryColor : '#cd3724';
    opts.icon = 'icon' in opts ? opts.icon : 'https://www.stam.pr/assets/img/airplane.png';
    opts.rememberLastLogin = true;
    opts.sso = true;
    global.logger.debug('lock prompt with opts', opts);
    auth.lock.show(opts, function(err, profile, token) {
      if (err) {
        auth.client.close();
        alert('Error:\n\n' + err.message);
        return;
      }
      auth.client.session.profile = profile;
      auth.client.open(token);
    });
  },
};
