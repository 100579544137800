'use strict';

var m = require('mithril');
var db = require('../lib/db.js');
var Base = require('./base.js');

var shipmentOptions = require('../shared/shipment-options.js');

var DEFAULT_SHIPMENT_SIZE = 'sm';
var DEFAULT_SHIPMENT_PACKING = 'standard';
var DEFAULT_SHIPMENT_HANDLING = 'standard';
var DEFAULT_SHIPMENT_SHIPPING = 'standard';

function Shipment(data) {
  data = data || {};
  Base.call(this, data);
  this.itemId = m.prop(data.itemId || null);
  this.pickupId = m.prop(data.pickupId || null);
  this.source = m.prop(data.source || null);
  this.origin_address = m.prop(data.origin_address || {});
  this.destination_address = m.prop(data.destination_address || {});
  this.authorization = m.prop(data.authorization || null);
  this.amount = m.prop(data.amount || 0);
  this.shipping_price = m.prop('shipping_price' in data ? data.shipping_price : null);
  this.image = m.prop(data.image || null);
  this.image_thumbnail = m.prop(data.image_thumbnail || null);
  this.description = m.prop(data.description || null);
  this.packing_instructions = m.prop(data.packing_instructions || null);
  this.size = m.prop(data.size || DEFAULT_SHIPMENT_SIZE);
  this.packing = m.prop(data.packing || DEFAULT_SHIPMENT_PACKING);
  this.handling = m.prop(data.handling || DEFAULT_SHIPMENT_HANDLING);
  this.shipping = m.prop(data.shipping || DEFAULT_SHIPMENT_SHIPPING);
  this.extras = m.prop(data.extras || {});
  this.multis = m.prop('multis' in data ? data.multis : null);
  this.multi_items = m.prop(data.multi_items || null);
  this.packing_instructions = m.prop(data.packing_instructions || null);
  this.paid = m.prop('paid' in data ? data.paid : false);
  this.payment_details = m.prop(data.payment_details || {});
  this.complete = m.prop('complete' in data ? data.complete : false);

  if (!this.paid()) { // don't overwrite amount if paid (amount is amount paid in that case)
    this.refreshTotal();
  }
}

Base.inheritedBy(Shipment);

Shipment.prototype.cancel = function() {
  var _this = this;
  var data = db.createPayload('cancel-item', {
    pickupId: this.pickupId(),
    itemId: this.itemId(),
  });
  var changedShipment = db.request({ function: config.lambda.orders, data: data, type: Shipment });
  changedShipment.then(function(shipment) {
    _this.import(shipment);
    return shipment;
  });
  return changedShipment;
};

Shipment.prototype.save = function() {
  var _this = this;
  var changedShipment = Shipment.update(this.toJSON());
  changedShipment.then(function(shipment) {
    _this.import(shipment);
    return shipment;
  });
  return changedShipment;
};

Shipment.prototype.refreshTotal = function() {
  var size = this.size();
  var total = 0;
  var packingCost = shipmentOptions.getPrice('packing', this.packing(), size);
  total += packingCost;
  var handlingCost = shipmentOptions.getPrice('handling', this.handling(), size);
  total += handlingCost;
  // TODO: need to implement quote
  // total += shipmentOptions.getPrice('shipping', shipment.shipping(), size);
  var extraInsuranceCost = !this.extras().insurance ? 0 : shipmentOptions.getPrice('insurance', this.extras().insurance, size);
  total += extraInsuranceCost;
  var extraGiftWrapCost = !this.extras().gift_wrap ? 0 : shipmentOptions.getPrice('gift_wrap', this.extras().gift_wrap, size);
  total += extraGiftWrapCost;
  global.logger.debug('Shipment.refreshTotal costs = %s', total, {
    meta: this.meta(),
    packing: packingCost,
    handling: handlingCost,
    extras: {
      insurance: extraInsuranceCost,
      gift_wrap: extraGiftWrapCost,
    },
  });
  this.amount(total);
};

Shipment.prototype.isDefaultShippingHandling = function() {
  return this.packing() === DEFAULT_SHIPMENT_PACKING || this.handling() === DEFAULT_SHIPMENT_HANDLING || this.shipping() === DEFAULT_SHIPMENT_SHIPPING;
};

Shipment.prototype.import = function(model) {
  Base.prototype.import.call(this, model);
  this.itemId(model.itemId());
  this.pickupId(model.pickupId());
  this.source(model.source());
  this.origin_address(model.origin_address());
  this.destination_address(model.destination_address());
  this.authorization(model.authorization());
  this.amount(model.amount());
  this.shipping_price(model.shipping_price());
  this.image(model.image());
  this.image_thumbnail(model.image_thumbnail());
  this.description(model.description());
  this.packing_instructions(model.packing_instructions());
  this.size(model.size());
  this.packing(model.packing());
  this.handling(model.handling());
  this.shipping(model.shipping());
  this.extras(model.extras());
  this.multis(model.multis());
  this.multi_items(model.multi_items());
  this.packing_instructions(model.packing_instructions());
  this.paid(model.paid());
  this.payment_details(model.payment_details());
  this.complete(model.complete());
  this.refreshTotal();
};

Shipment.prototype.toJSON = function() {
  var data = Base.prototype.toJSON.call(this);
  data.itemId = this.itemId();
  data.pickupId = this.pickupId();
  data.source = this.source();
  data.origin_address = this.origin_address();
  data.destination_address = this.destination_address();
  data.authorization = this.authorization();
  data.amount = this.amount();
  data.shipping_price = this.shipping_price();
  data.image = this.image();
  data.image_thumbnail = this.image_thumbnail();
  data.description = this.description();
  data.packing_instructions = this.packing_instructions();
  data.size = this.size();
  data.packing = this.packing();
  data.handling = this.handling();
  data.shipping = this.shipping();
  data.extras = this.extras();
  data.multis = this.multis();
  data.multi_items = this.multi_items();
  data.packing_instructions = this.packing_instructions();
  data.paid = this.paid();
  data.payment_details = this.payment_details();
  data.complete = this.complete();
  return data;
};

Shipment.create = function(data) {
  var data = db.createPayload('create-item', data);
  return db.request({ function: config.lambda.orders, data: data, type: Shipment });
};

Shipment.read = function(pickupId, itemId) {
  var data = db.createPayload('read-item', {
    pickupId: pickupId,
    itemId: itemId,
  });
  return db.request({ function: config.lambda.orders, data: data, type: Shipment });
};

Shipment.update = function(partialData) {
  var data = db.createPayload('update-item', {
    itemId: partialData.itemId,
    pickupId: partialData.pickupId,
    amount: partialData.amount || 0,
    origin_address: partialData.origin_address || {},
    destination_address: partialData.destination_address || {},
    description: partialData.description || null,
    packing_instructions: partialData.packing_instructions || null,
    shipping_price: partialData.shipping_price || null,
    size: partialData.size || null,
    packing: partialData.packing || null,
    handling: partialData.handling || null,
    shipping: partialData.shipping || null,
    extras: partialData.extras || {},
    multis: 'multis' in partialData ? partialData.multis : null,
    multi_items: partialData.multi_items || null,
    packing_instructions: partialData.packing_instructions || null,
    payment_details: partialData.payment_details || null,
  });
  return db.request({ function: config.lambda.orders, data: data, type: Shipment });
};

Shipment.list = function(pickupId) {
  var data = db.createPayload('pickup-items', {
    pickupId: pickupId,
  });
  return db.request({ function: config.lambda.orders, data: data, type: Shipment });
};

Shipment.find = function(status) {
  var data = db.createPayload('filter-items', {
    status: status,
  });
  return db.request({ function: config.lambda.orders, data: data, type: Shipment });
};

module.exports = Shipment;
