'use strict';

var addresses = module.exports = {
  findPropertyByType: function(addressRecord, type) {
    addressRecord = addressRecord || {};
    addressRecord.address_components = addressRecord.address_components || [];
    for (var i=0; i < addressRecord.address_components.length; i++) {
      var component = addressRecord.address_components[i];
      var types = component.types || [];
      if (types.indexOf(type) > -1) {
        return component;
      }
    }
    return null;
  },

  formatComponent: function(component) {
    component = component || {};
    return component.short_name || component.long_name || '';
  },

  getStreet: function(addressRecord) {
    var postalCode = addresses.getPostalCode(addressRecord);
    var formatted = addresses.getFormattedStreet(addressRecord);
    if (formatted && formatted.indexOf(postalCode) > -1) {
      return addresses.getActualStreet(addressRecord);
    }
    else {
      return formatted;
    }
  },

  getFormattedStreet: function(addressRecord) {
    return addressRecord.formatted_address;
  },

  getActualStreet: function(addressRecord) {
    var s1 = addresses.findPropertyByType(addressRecord, 'street_number');
    var s2 = addresses.findPropertyByType(addressRecord, 'route');
    return addresses.formatComponent(s1) + ' ' + addresses.formatComponent(s2);
  },

  getCity: function(addressRecord) {
    var city = addresses.findPropertyByType(addressRecord, 'locality');
    return addresses.formatComponent(city);
  },

  getState: function(addressRecord) {
    var state = addresses.findPropertyByType(addressRecord, 'administrative_area_level_1');
    return addresses.formatComponent(state);
  },

  getPostalCode: function(addressRecord) {
    var postalCode = addresses.findPropertyByType(addressRecord, 'postal_code');
    return addresses.formatComponent(postalCode);
  },
};
