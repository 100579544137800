'use strict';

var m = require('mithril');
var AWS = global.AWS;

var fab = require('polythene/fab/fab');
var button = require('polythene/button/button');
var iconButton = require('polythene/icon-button/icon-button');

var Pickup = require('../models/pickup.js');
var Shipment = require('../models/shipment.js');
var Subscription = require('../models/subscription.js');

var screen = require('../shared/screen.js');
var addresses = require('../shared/addresses.js');
var pageLayout = require('../layouts/page.js');
var dialogs = require('../shared/dialogs.js');

var JobDetails = require('../components/job-details.js');
var selectLocation = require('../components/select-location.js');
var message = require('../components/message.js');
var asyncButton = require('../components/async-button.js');

var iconAdd = require('mmsvg/google/msvg/content/add');
var iconArrowBack = require('mmsvg/google/msvg/navigation/arrow-back');
var iconClear = require('mmsvg/google/msvg/content/clear');

var promiseErrorHandler = require('../shared/promise-error-handler.js');

var Create = module.exports = screen.create({
  vm: function(data) {
    data = data || {};
    var vm = {};
    vm.pickup = m.prop(data.pickup || new Pickup());
    vm.shipment = m.prop(data.shipment || new Shipment());
    vm.shipmentBlob = m.prop(null);
    vm.temporaryUrl = m.prop(null);
    vm.jobDetails = JobDetails.vm({
      editable: true,
      shipment: vm.shipment(),
      dialogs: dialogs,
      shipmentBlob: vm.shipmentBlob,
      temporaryUrl: vm.temporaryUrl,
    });
    return vm;
  },

  controller: function(args) {
    var ctrl = this;
    var vm = ctrl.vm;
    ctrl.icon = m(iconButton, {
      icon: {
        msvg: iconArrowBack,
      },
      url: {
        href: '/dispatched',
        config: m.route,
      },
    });
    ctrl.action = m(asyncButton, {
      label: 'Save',
      action: function(deferred, eventArgs) {
        var data = {
          pickupId: vm.shipment().pickupId(),
          source: vm.shipment().source(),
          authorization: null,
          amount: 0,
          image: vm.temporaryUrl(),
          image_thumbnail: vm.temporaryUrl(),
          description: null,
          packing_instructions: null,
          payment_details: vm.shipment().payment_details() || null,
          shipping_price: null,
          multis: null,
          multi_items: null,
          origin_address: vm.shipment().origin_address(),
          destination_address: vm.shipment().destination_address(),
          size: vm.shipment().size(),
          packing: vm.shipment().packing(),
          handling: vm.shipment().handling(),
          shipping: vm.shipment().shipping(),
          extras: vm.shipment().extras(),
        };
        Shipment.create(data).then(function(shipment) {
          setTimeout(m.route, 0, '/dispatched');
          ctrl.vmApp.refreshPickups();
          return shipment;
        }, promiseErrorHandler(deferred.reject));
      },
    });
    var pickup = ctrl.vmApp.openPickup ? ctrl.vmApp.openPickup() : null;
    if (pickup) {
      vm.pickup(pickup);
      var pickupAddress = pickup.location().address || {};
      var origin = vm.jobDetails.shipment().origin_address ? vm.jobDetails.shipment().origin_address() : {};
      origin.addressee = ctrl.vmApp.session.profile.name !== ctrl.vmApp.session.profile.email ? ctrl.vmApp.session.profile.name : ctrl.vmApp.session.profile.nickname;
      origin.street1 = addresses.getStreet(pickupAddress);
      origin.city = addresses.getCity(pickupAddress);
      origin.state = addresses.getState(pickupAddress);
      origin.postal = addresses.getCity(pickupAddress);
      vm.shipment().pickupId(pickup.pickupId());
    }
  },
  view: pageLayout('Add shipment', function(ctrl, args) {
    var vmApp = ctrl.vmApp;
    if (!vmApp.isPickupActive()) {
      return m(message, {
        type: 'info',
        outline: true,
        content: [
          m('h5', 'No pickup scheduled'),
          m('p', 'You do not currently have a pickup scheduled.  You must schedule a pickup before you can add shipments to be picked up.'),
          m('div', { style: 'text-align:right;' }, [
            m(button, {
              raised: true,
              label: 'Schedule Pickup',
              url: {
                href: '/pickup',
                config: m.route,
              },
            }),
          ]),
        ],
      });
    }
    return m(JobDetails, ctrl.vm.jobDetails);
  }),
});
