'use strict';

var paymentUtils = module.exports = {
  findDefaultSource: function(sources, defaultSourceId) {
    global.logger.group('paymentUtils.findDefaultSource');
    var found = null;
    if (Array.isArray(sources)) {
      for (var i=0; i < sources.length; i++) {
        var source = sources[i];
        global.logger.debug('source', source);
        var sourceId = source.id();
        global.logger.debug('checking source.id (%s) against default %s', sourceId, defaultSourceId)
        if (defaultSourceId === sourceId) {
          global.logger.info('source match found', source);
          found = source;
          break;
        }
      }
    }
    global.logger.debug('found', found);
    global.logger.groupEnd();
    return found;
  },
};
