'use strict';

var m = require('mithril');

var button = require('polythene/button/button');
var iconButton = require('polythene/icon-button/icon-button');
var card = require('polythene/card/card');
var list = require('polythene/list/list');
var listTile = require('polythene/list-tile/list-tile');
var icon = require('polythene/icon/icon');
var iconWhatsHot = require('mmsvg/google/msvg/social/whatshot');
var iconLocalShipping = require('mmsvg/google/msvg/maps/local-shipping');
var iconContactMail = require('mmsvg/google/msvg/communication/contact-mail');
var iconImage = require('mmsvg/google/msvg/image/photo');
var iconStarOutline = require('mmsvg/templarian/msvg/star-outline');
var iconHome = require('mmsvg/google/msvg/action/home');

var Payment = require('../models/payment.js');
var shipmentOptions = require('../shared/shipment-options.js');


var handleEditAddress = function(ctrl, args, source) {
  var model = {
    addressee: m.prop(source.addressee),
    street1: m.prop(source.street1),
    street2: m.prop(source.street2),
    street3: m.prop(source.street3),
    city: m.prop(source.city),
    state: m.prop(source.state),
    postal: m.prop(source.postal),
    country: m.prop(source.country || 'US'),
  };
  args.dialogs.show('createAddress', ctrl, args, { model: model }, function(err, model) {
    global.logger.debug('createAddress dialog', err, model);
    if (model) {
      source.addressee = model.addressee();
      if (!source.addressee || !source.addressee.length) source.addressee = null;
      source.street1 = model.street1();
      if (!source.street1 || !source.street1.length) source.street1 = null;
      source.street2 = model.street2();
      if (!source.street2 || !source.street2.length) source.street2 = null;
      source.street3 = model.street3();
      if (!source.street3 || !source.street3.length) source.street3 = null;
      source.city = model.city();
      if (!source.city || !source.city.length) source.city = null;
      source.state = model.state();
      if (!source.state || !source.state.length) source.state = null;
      source.postal = model.postal();
      if (!source.postal || !source.postal.length) source.postal = null;
      source.country = model.country();
      if (!source.country || !source.country.length) source.country = null;
    }
  });
};

var handleOptionChange = function(ctrl, args, source, items, displayPrices, size, after) {
  global.logger.debug('handleOptionChange', source(), items);
  var opts = {
    items: m.prop(items),
    size: size,
    selectedValue: m.prop(source()),
    displayPrices: displayPrices,
  };
  args.dialogs.show('listPicker', ctrl, args, opts, function(err, model) {
    if (model) {
      source(model.selectedValue());
      global.logger.debug('handleOptionChange; result: ', model.selectedValue());
      args.refresh();
      after && after();
    }
  });
};

var createAddressTile = function(address, msvg) {
  var data = {
    addressee: address.addressee || m('i', 'Name'),
    street1: address.street1 || m('i', 'Street'),
    street2: address.street2 || null,
    street3: address.street3 || null,
    city: address.city || m('i', 'City'),
    state: address.state || m('i', 'State'),
    postal: address.postal || m('i', 'Zip'),
    country: address.country || null,
  };
  return m(listTile, {
    title: data.addressee,
    subtitle: [
      data.street1,
      !data.street2 ? null : ' ' + data.street2,
      !data.street3 ? null : ' ' + data.street3,
    ],
    highSubtitle: [
      data.city,
      ', ',
      data.state,
      ' ',
      data.postal,
      !data.country || data.country === 'USA' || data.country === 'US' ? null : ', ' + data.country,
    ],
    front: m(icon, {
      type: 'medium',
      msvg: msvg,
    }),
  });
};

var JobDetails = module.exports = {
  vm: function(data) {
    data = data || {};
    var vm = {};

    vm.shipment = m.prop(data.shipment || {});
    data.extras = data.extras || (data.shipment ? data.shipment.extras() : {});
    vm.extras = {
      insurance: m.prop(data.extras.insurance || '100'),
      gift_wrap: m.prop(data.extras.gift_wrap || 'no_gift_wrap'),
    };

    // functionality flags
    vm.usePaymentDetails = m.prop(data.usePaymentDetails || false);
    vm.editable = m.prop(data.editable || false);
    vm.recipient = m.prop('recipient' in data ? data.recipient : true);
    vm.origin = m.prop('origin' in data ? data.origin : true);
    vm.items = m.prop('items' in data ? data.items : true);
    vm.description = m.prop('description' in data ? data.description : true);
    vm.options = m.prop('options' in data ? data.options : true);

    vm.temporaryUrl = data.temporaryUrl || m.prop(null);
    vm.shipmentBlob = data.shipmentBlob || m.prop(null);

    // FIXME: for some reason including dialogs from job-details fails. probably a circular reference somewhere?
    vm.dialogs = data.dialogs;

    vm.refresh = function() {
      m.startComputation();
      vm.shipment().extras().insurance = vm.extras.insurance();
      vm.shipment().extras().gift_wrap = vm.extras.gift_wrap();
      vm.shipment().refreshTotal();
      m.endComputation();
    };

    return vm;
  },
  controller: function(vm) {
  },
  view: function(ctrl, vm) {
    var shipment = vm.shipment ? vm.shipment() : {};
    var origin = shipment.origin_address ? shipment.origin_address() : {};
    var destination = shipment.destination_address ? shipment.destination_address() : {};
    var pricing = vm.usePaymentDetails() ? shipment.payment_details() : {
      packing: shipmentOptions.getPrice('packing', shipment.packing(), shipment.size()),
      handling: shipmentOptions.getPrice('handling', shipment.handling(), shipment.size()),
      shipping: shipmentOptions.getPrice('shipping', shipment.shipping(), shipment.size()),
      // shipping: shipmentOptions.getShippingPrice(shipment.toJSON()),
      insurance: shipmentOptions.getPrice('insurance', vm.extras.insurance(), shipment.size()),
      gift_wrap: shipmentOptions.getPrice('gift_wrap', vm.extras.gift_wrap(), shipment.size()),
    };
    return m('div', [
      // TODO: add support for shipment.multis
      // !vm.description() ? null : m('div.layout.vertical', [
      //   m('div.block', { style: 'height:250px;background-color:#efe8dc;position:relative;' }, [
      //     m('div#image-preview-image', { style:'background-position: center center; background-size: cover; height:100%; max-width:600px; margin:0 auto; background-image:url("' + (shipment.image() || '') + '");' }),
      //     m('div.layout.pe-fit.center-center', { style: 'position:absolute;' }, [
      //       m(button, {
      //         class: 'job-details-add-photo',
      //         raised: true,
      //         label: 'Add Photo',
      //         before: [
      //           m('input[type="file"][accept="image/*"][capture="camera"]', {
      //             onchange: function() {
      //               if (this.files && this.files.length) {
      //                 var blobUrl = URL.createObjectURL(this.files[0]);
      //                 vm.temporaryUrl(blobUrl); // store the blob: url so that it is immediately available on other screens
      //                 // var reader = new FileReader();
      //                 // reader.onload = function(event) {
      //                 //   $('#image-preview-image').css('background-image', 'url("' + event.target.result + '")');
      //                 // };
      //                 // reader.readAsDataURL(this.files[0]);
      //                 // $('#image-preview-image').attr('src', blobUrl);
      //                 vm.shipmentBlob(this.files[0]);
      //                 document.getElementById('image-preview-image').style.backgroundImage = 'url("' + blobUrl + '")';
      //               }
      //             },
      //           }),
      //         ],
      //       }),
      //     ]),
      //   ]),
      // ]),

      // !vm.origin() ? null : m(list, {
      //   header: {
      //     title: 'From Address',
      //   },
      //   borders: false,
      //   tiles: [
      //     createAddressTile(origin, iconHome),
      //   ],
      //   after: [
      //     m('div', { style:'padding-left:72px' }, [
      //       m(button, {
      //         raised: true,
      //         label: 'Edit Address',
      //         events: {
      //           onclick: function() {
      //             handleEditAddress(ctrl, vm, origin);
      //           },
      //         },
      //       }),
      //     ]),
      //   ],
      // }),

      !vm.recipient() ? null : m(list, {
        header: {
          title: 'Recipient address',
        },
        borders: false,
        tiles: [
          createAddressTile(destination, iconContactMail),
        ],
        after: [
          m('div', { style:'padding-left:72px' }, [
            m(button, {
              raised: true,
              label: 'Edit Address',
              events: {
                onclick: function() {
                  handleEditAddress(ctrl, vm, destination);
                },
              },
            }),
          ]),
        ],
      }),

      !vm.options() ? null : [
        m(list, {
          header: {
            title: 'Options',
          },
          borders: false,
          tiles: [
            m(listTile, {
              title: 'How much stuff are you shipping?',
              highSubtitle: 'The size of your shipment impacts other costs.',
              ink: true,
              events: {
                onclick: function() {
                  handleOptionChange(ctrl, vm, shipment.size, shipmentOptions.size, false, shipment.size());
                },
              },
              secondary: {
                content: [
                  m('div.list-label', shipmentOptions.getName('size', shipment.size())),
                ],
              },
            }),
            m(listTile, {
              title: 'Fragile?',
              highSubtitle: 'We professionally pack every shipment, but some need extra care.',
              ink: true,
              events: {
                onclick: function() {
                  handleOptionChange(ctrl, vm, shipment.packing, shipmentOptions.packing, true, shipment.size());
                },
              },
              secondary: {
                content: [
                  m('div.job-details-list-label-price', Payment.amountToLocale(pricing.packing, 'usd')),
                  m('div.list-label', shipmentOptions.getName('packing', shipment.packing())),
                ],
              },
            }),
            // m(listTile, {
            //   title: 'Handling',
            //   highSubtitle: 'How quickly we pack your items, and get them shipped.',
            //   ink: true,
            //   events: {
            //     onclick: function() {
            //       handleOptionChange(ctrl, vm, shipment.handling, shipmentOptions.handling, true, shipment.size());
            //     },
            //   },
            //   secondary: {
            //     content: [
            //       m('div.job-details-list-label-price', Payment.amountToLocale(pricing.handling, 'usd')),
            //       m('div.list-label', shipmentOptions.getName('handling', shipment.handling())),
            //     ],
            //   },
            // }),
            m(listTile, {
              title: 'Delivery speed',
              highSubtitle: 'We offer flat-rate shipping* at a variety of delivery speeds.',
              ink: true,
              events: {
                onclick: function() {
                  handleOptionChange(ctrl, vm, shipment.shipping, shipmentOptions.shipping, false, shipment.size());
                },
              },
              secondary: {
                content: [
                  m('div.job-details-list-label-price', isNaN(pricing.shipping) ? pricing.shipping : Payment.amountToLocale(pricing.shipping, 'usd')),
                  m('div.list-label', shipmentOptions.getName('shipping', shipment.shipping())),
                ],
              },
            }),
          ],
        }),
      ],

      !vm.options() ? null : m(list, {
        header: {
          title: 'Extras',
        },
        borders: false,
        tiles: [
          m(listTile, {
            title: 'Insurance',
            highSubtitle: 'For that extra layer of protection.',
            ink: true,
            events: {
              onclick: function() {
                handleOptionChange(ctrl, vm, vm.extras.insurance, shipmentOptions.insurance, true, shipment.size(), vm.updateExtras);
              },
            },
            secondary: {
              content: [
                m('div.job-details-list-label-price', Payment.amountToLocale(pricing.insurance, 'usd')),
                m('div.list-label', shipmentOptions.getName('insurance', vm.extras.insurance())),
              ],
            },
          }),
          // m(listTile, {
          //   title: 'Gift Wrap',
          //   highSubtitle: 'Ship a gift.  Not a box.',
          //   ink: true,
          //   events: {
          //     onclick: function() {
          //       handleOptionChange(ctrl, vm, vm.extras.gift_wrap, shipmentOptions.gift_wrap, true, shipment.size(), vm.updateExtras);
          //     },
          //   },
          //   secondary: {
          //     content: [
          //       m('div.job-details-list-label-price', Payment.amountToLocale(pricing.gift_wrap, 'usd')),
          //       m('div.list-label', shipmentOptions.getName('gift_wrap', vm.extras.gift_wrap())),
          //     ],
          //   },
          // }),
        ],
      }),

      !vm.options() ? null : m('p', { style: 'padding:15px;font-size:85%;margin-top:15px;color:rgba(0,0,0,.65);border-top:1px solid rgba(0,0,0,.15);' }, m('i', '* Oversized, heavy, shipments outside continental US, or any other items requiring special handling will result in additional shipping charges that vary depending on the destination.  These will be billed to your account at the time of shipping.')),
    ]);
  },
};
