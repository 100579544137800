'use strict';

var m = require('mithril');

var DispatchMap = module.exports = {
  vm: function(data) {
    data = data || {};
    var vm = {};
    vm.dispatch = null;
    // map,pickup,courier below all exist for forward compat
    vm.map = null;
    vm.pickup = m.prop(data.pickup);
    vm.courier = m.prop(data.courier);
    return vm;
  },

  controller: function(vm) {
  },

  view: function(ctrl, vm) {
    var dispatch = vm.dispatch ? vm.dispatch() : null;
    return m('div.pe-fit', { style:'overflow:hidden;' }, [
      !dispatch ? null : m('iframe', {
        src: dispatch.trackingMapUrl(),
        style: [
          'border:none;',
          'width:100%;',
          'height:380px;',
          'margin-top:-60px;',
        ].join(''),
      }),
    ]);
  },
};
