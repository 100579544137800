'use strict';

var m = require('mithril');
var button = require('polythene/button/button');
var spinner = require('polythene/spinner/indeterminate-spinner');

var asyncButton = module.exports = {
  controller: function(args) {
    var ctrl = this;
    ctrl.asyncActive = m.prop(false);
    ctrl.active = function() {
      m.startComputation();
      ctrl.asyncActive(true);
      m.endComputation();
    };
    ctrl.inactive = function() {
      m.startComputation();
      setTimeout(function() {
        ctrl.asyncActive(false);
        m.endComputation();
      }, 75); // add a slight delay to prevent flash-before-route situation
    };
  },
  view: function(ctrl, args) {
    return m('div.async-button', { class: ctrl.asyncActive() ? 'async-button-active' : 'async-button-inactive' }, [
      m(button, {
        class: args.class || '',
        label: args.label,
        raised: 'raised' in args ? args.raised : false,
        inactive: ctrl.asyncActive(),
        disabled: args.disabled,
        events: {
          onclick: function() {
            var deferred = m.deferred();
            ctrl.active();
            deferred.promise.then(function() {
              global.logger.debug('async promise resolved; success');
              ctrl.inactive();
            }, function() {
              global.logger.debug('async promise resolved; error');
              ctrl.inactive();
            });
            args.action.call(this, deferred, arguments);
          },
        },
        after: [
          m('div.async-button-spinner-container', [
            m(spinner, {
              singleColor: args.singleColor || true,
              type: args.type || 'small',
              show: ctrl.asyncActive(),
            }),
          ]),
        ],
      }),
    ]);
  },
};
