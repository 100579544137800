'use strict';

var m = require('mithril');
var list = require('polythene/list/list');
var listTile = require('polythene/list-tile/list-tile');
var radioButton = require('polythene/radio-button/radio-button');
var button = require('polythene/button/button');

var iconClear = require('mmsvg/google/msvg/content/clear');

var message = require('../components/message.js');

var renderListContent = function(ctrl, args, opts) {
  return opts.payments.sources.map(function(payment) {
    return m(listTile, {
      title: [
        payment.brand(),
        payment.exp_month() + '/' + payment.exp_year(),
        '**** ' + payment.last4(),
      ].join(' '),
      front: m(radioButton, {
        name: 'selected-payment-type',
        checked: payment.id() === opts.payments.default.id(),
      }),
    });
  });
};

var renderEmptyContent = function(ctrl, args, opts) {
  return m(message, {
    outline: true,
    content: [
      m('h5', 'No Payment Methods'),
      m('p', 'Please go to Settings and add a payment method now.'),
      m('div', { style: 'text-align:right;' }, [
        m(button, {
          raised: true,
          label: 'Add Payment Method',
          events: {
            onclick: function() {
              dialogs.show('addPayment', ctrl, args, opts);
            },
          },
        }),
      ]),
    ],
  });
};

module.exports = function(ctrl, args, opts, callback) {
  var content = opts.payments.sources.length ? renderListContent(ctrl, args, opts) : renderEmptyContent(ctrl, args, opts);
  return {
    title: 'Select payment method',
    body: content,
    fullscreen: {
      icon: {
        msvg: iconClear,
        events: {
          onclick: function() {
            callback();
          },
        },
      },
      action: {
        label: 'Done',
        events: {
          onclick: function() {
            callback();
          },
        },
      },
    },
  };
};
