'use strict';

var m = require('mithril');
var headerPanel = require('polythene/header-panel/header-panel');
var tabs = require('polythene/tabs/tabs');
var svg = require('polythene/svg/svg');
var icon = require('polythene/icon/icon');
var button = require('polythene/button/button');
var iconButton = require('polythene/icon-button/icon-button');
var dialog = require('polythene/dialog/dialog');

var stamprSvg = require('../../common/svg.js');
var logoPassenger = stamprSvg.passenger_logo_horizontal_white;
var logoAirplane = stamprSvg.stampr_logo_airplane_flat;

const toolbarRow = (ctrl, args, opts) => ([
  ctrl.icon || m.trust('&nbsp;&nbsp;'),
  m('div.flex.brand.page-layout-title', opts.title),
  ctrl.action || null,
  // m(iconButton, {
  //   url: {
  //     href: '/cart',
  //     config: m.route,
  //   },
  //   content: [
  //     m('div.pe-icon.pe-icon--regular', {
  //       class: 'mdl-badge mdl-badge--overlap',
  //       'data-badge': 3,
  //     }, [
  //       m(svg, {
  //         content: iconShoppingCart,
  //       }),
  //     ]),
  //   ],
  // }),
]);

module.exports = function(title, content, opts) {
  opts = opts || {};
  opts.title = title;
  return function(ctrl, args) {
    var vmApp = ctrl.vmApp;
    var currentRoute = m.route();
    return [
      m(headerPanel, {
        mode: opts.headerMode || 'seamed',
        class: 'header-panel--fit passenger-theme passenger-page-layout' + (opts.disableHeader ? ' hide-header-bar' : ''),
        fixed: true,
        header:  {
          toolbar: {
            topBar: opts.toolbarMiddleBar || null,
            middleBar: opts.toolbarMiddleBar || toolbarRow(ctrl, args, opts),
            bottomBar: opts.toolbarBottomBar || null,
          }
        },
        content: [
          content(ctrl, args),
        ],
      }),
      m(dialog),
    ];
  };
};
