'use strict';

var m = require('mithril');
var AWS = global.AWS;

var fab = require('polythene/fab/fab');
var button = require('polythene/button/button');
var iconButton = require('polythene/icon-button/icon-button');

var Pickup = require('../models/pickup.js');
var Shipment = require('../models/shipment.js');

var screen = require('../shared/screen.js');
var addresses = require('../shared/addresses.js');
var pageLayout = require('../layouts/page.js');
var dialogs = require('../shared/dialogs.js');

var JobDetails = require('../components/job-details.js');
var selectLocation = require('../components/select-location.js');
var message = require('../components/message.js');
var asyncButton = require('../components/async-button.js');

var iconAdd = require('mmsvg/google/msvg/content/add');
var iconArrowBack = require('mmsvg/google/msvg/navigation/arrow-back');
var iconClear = require('mmsvg/google/msvg/content/clear');

var promiseErrorHandler = require('../shared/promise-error-handler.js');
var shipmentOptions = require('../shared/shipment-options.js');

var Edit = module.exports = screen.create({
  vm: function(data) {
    data = data || {};
    var vm = {};
    vm.pickup = m.prop(data.pickup || new Pickup());
    vm.shipment = m.prop(data.shipment || new Shipment());
    vm.shipmentBlob = m.prop(null);
    vm.temporaryUrl = m.prop(null);
    vm.jobDetails = JobDetails.vm({
      editable: true,
      shipment: vm.shipment(),
      dialogs: dialogs,
      shipmentBlob: vm.shipmentBlob,
      temporaryUrl: vm.temporaryUrl,
    });
    return vm;
  },

  controller: function(args) {
    var ctrl = this;
    var vm = ctrl.vm;
    ctrl.icon = m(iconButton, {
      icon: {
        msvg: iconArrowBack,
      },
      url: {
        href: '/dispatched',
        config: m.route,
      },
    });
    ctrl.action = m(asyncButton, {
      label: 'Save',
      action: function(deferred, eventArgs) {
        var shipment = vm.jobDetails.shipment();
        var extras = shipment.extras();
        shipment.payment_details({
          packing: shipmentOptions.getPrice('packing', shipment.packing(), shipment.size()),
          handling: shipmentOptions.getPrice('handling', shipment.handling(), shipment.size()),
          shipping: shipmentOptions.getPrice('shipping', shipment.shipping(), shipment.size()),
          insurance: shipmentOptions.getPrice('insurance', extras.insurance),
          gift_wrap: shipmentOptions.getPrice('gift_wrap', extras.gift_wrap),
        });
        shipment.save().then(function(shipment) {
          var done = function() {
            setTimeout(m.route, 0, '/dispatched');
          };
          // if (vm.shipmentBlob()) {
          //   var s3 = new AWS.S3({
          //     region: global.StamprEnv.PASSENGER_SERVICE_REGION,
          //     params: {
          //       Bucket: global.StamprEnv.PASSENGER_SHIPMENTS_OBJECT_STORAGE,
          //     },
          //   });
          //   var params = {
          //     Key: 'incoming/' + shipment.itemId() + '-' + Math.random(),
          //     Body: vm.shipmentBlob(),
          //     Metadata: {
          //       passenger_item: shipment.itemId(),
          //       passenger_pickup: shipment.pickupId(),
          //       passenger_user: shipment.owner(),
          //     },
          //   };
          //   s3.upload(params, function(err, res) {
          //     deferred.resolve();
          //     if (err) {
          //       global.logger.debug('s3.upload error', err);
          //       alert('There was a problem uploading your image:\n\n' + err.message);
          //       // TODO: reset upload form
          //       return;
          //     }
          //     global.logger.debug('s3.upload done', res);
          //     done();
          //   });
          // }
          // else {
            done();
          // }
          return shipment;
        }, promiseErrorHandler(deferred.reject));
      },
    });
    vm.jobDetails.shipment = Shipment.read(ctrl.vmApp.openPickup().pickupId(), m.route.param('itemId')).then(function(shipment) {
      setTimeout(vm.jobDetails.refresh, 0);
      return shipment;
    });
  },
  view: pageLayout('Edit shipment', function(ctrl, args) {
    var vmApp = ctrl.vmApp;
    if (!vmApp.isPickupActive()) {
      return m(message, {
        type: 'info',
        outline: true,
        content: [
          m('h5', 'No pickup scheduled'),
          m('p', 'You do not currently have a pickup scheduled.  You must schedule a pickup before you can add shipments to be picked up.'),
          m('div', { style: 'text-align:right;' }, [
            m(button, {
              raised: true,
              label: 'Schedule Pickup',
              url: {
                href: '/pickup',
                config: m.route,
              },
            }),
          ]),
        ],
      });
    }
    return m(JobDetails, ctrl.vm.jobDetails);
  }),
});
