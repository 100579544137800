'use strict';

var m = require('mithril');
var message = require('./message.js');

var FullMessage = module.exports = {
  vm: function(data) {
    data = data || {};
    var vm = {};
    return vm;
  },

  controller: function(args) {
  },
  view: function(ctrl, args) {
    return m(message, {
      class: 'passenger-alert-full block flex layout vertical center-center ' + (args.class || ''),
      outline: true,
      content: [
        m('div.flex', ' '),
        m('div.block', [
          args.content,
        ]),
        m('div.flex', ' '),
        !args.image ? null : m('img.block.passenger-alert-full-image', { style: args.imageStyle || '', src: args.image }),
        m('div.flex', ' '),
      ],
    });
  },
};

