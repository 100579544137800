'use strict';

var m = require('mithril');

var Upload = module.exports = {
  vm: function(data) {
    data = data || {};
    var vm = {};
    return vm;
  },

  // config: function(vm) {
  //   return function(el, isInit) {
  //     if(typeof $ !== 'undefined' && typeof $.fn.fileupload !== 'undefined') {
  //       if (!isInit) {
  //         $(el).cloudinary_upload_widget({ cloud_name: 'demo', upload_preset: 'a5vxnzbp',
  //     cropping: 'server', 'folder': 'user_photos' },
  //   function(error, result) { global.logger.debug(error, result) });
  //       }
  //     }
  //   };
  // },

  closeCloudinary: function() {
    if (Upload.openCloudinary._instance) {
      try {
        Upload.openCloudinary._instance.close();
      }
      catch (err) {
        global.logger.warn('problem closing existing widget instance', err);
      }
      Upload.openCloudinary._instance = null;
    }
  },

  openCloudinary: function(vm) {
    return function(evt) {
      Upload.closeCloudinary();
      Upload.openCloudinary._instance = global.cloudinary.openUploadWidget({
        cloud_name: 'demo',
        upload_preset: 'a5vxnzbp',
      },
      function(error, result) {
        global.logger.debug(error, result);
      });
    };
  },

  controller: function(vm) {
    var ctrl = this;
    ctrl.onunload = function() {
      Upload.closeCloudinary();
    };
    return ctrl;
  },

  view: function(ctrl, vm) {
    return m('div', [
      m('div', 'file upload'),
      m('button', { onclick: Upload.openCloudinary(vm) }, 'upload'),
    ]);
  },
};
