'use strict';

var m = require('mithril');
var AWS = global.AWS;

var db = module.exports = {
  _lambdaClient: null,
  authorization: null,

  createPayload: function(method, body) {
    return {
      method: method,
      authorization: db.authorization,
      body: body || {},
    };
  },

  request: function lambdaInvoke(options) {
    if (!db._lambdaClient) {
      db._lambdaClient = new AWS.Lambda({
        region: global.config.lambda.region,
      });
    }
    options = options || {};
    options.data = options.data || {};
    var params = {
      FunctionName: options.function,
      InvocationType: 'RequestResponse',
      // LogType: 'Tail',
      Payload: options.data instanceof Buffer ? options.data : JSON.stringify(options.data),
    };
    var successStatusCode;
    switch (params.InvocationType) {
      case 'RequestResponse':
        successStatusCode = 200;
      break;
      case 'DryRun':
        successStatusCode = 204;
      break;
      default:
        throw new Error('invalid InvocationType');
    }
    if (!options.background) {
      m.startComputation();
    }
    var deferred = m.deferred();
    if (!db.authorization && !options.unauthorized) {
      deferred.reject(new Error('missing authorization'));
    }
    else {
      db._lambdaClient.invoke(params, function(err, res) {
        if (err) {
          deferred.reject(err);
        }
        else if (res.StatusCode === successStatusCode) {
          try {
            var deserialized = JSON.parse(res.Payload);
            var payload = deserialized;
            if (deserialized !== null && typeof deserialized === 'object' && 'errorMessage' in deserialized) {
              var rejectErrMessage = options.unwrapError ? options.unwrapError(payload) : payload.errorMessage;
              console.error('error received from api', payload);
              throw new Error('Error received from API: ' + rejectErrMessage);
            }
            if (options.unwrapSuccess) {
              payload = options.unwrapSuccess(payload);
            }
            if (options.transform) {
              payload = options.transform(payload);
            }
            var response;
            if (Array.isArray(payload) && options.type) {
              response = payload.map(function(item) {
                if (options.reviver) {
                  item = options.reviver(item);
                }
                return new options.type(item);
              });
            }
            else if (options.type) {
              if (options.reviver) {
                payload = options.reviver(payload);
              }
              response = new options.type(payload);
            }
            else {
              response = payload;
            }
            global.logger.debug('db -> %s (%s)', params.FunctionName, ((options || {}).data || {}).method || '[none]', response, { params: params, response: res, data: deserialized});
            deferred.resolve(response);
          }
          catch (err) {
            deferred.reject(err);
          }
        }
        else {
          deferred.reject(new Error('invalid status code in response ' + res.StatusCode + ' (' + res.FunctionError + ')'));
        }
        if (!options.background) {
          m.endComputation();
        }
      });
    }
    return deferred.promise;
  },
};
