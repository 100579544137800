'use strict';

var m = require('mithril');
var list = require('polythene/list/list');
var listTile = require('polythene/list-tile/list-tile');
var radioButton = require('polythene/radio-button/radio-button');
var button = require('polythene/button/button');

var iconClear = require('mmsvg/google/msvg/content/clear');

var message = require('../components/message.js');
var JobDetails = require('../components/job-details.js');

module.exports = function(ctrl, args, opts, callback) {
  var content = m(JobDetails, JobDetails.vm());
  return {
    title: 'Create new shipment',
    body: content,
    fullscreen: {
      icon: {
        msvg: iconClear,
        events: {
          onclick: function() {
            callback();
          },
        },
      },
      action: {
        label: 'Save',
        events: {
          onclick: function() {
            callback();
          },
        },
      },
    },
  };
};
