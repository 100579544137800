'use strict';

var util = require('util');
var m = require('mithril');
var moment = global.moment;

function Base(data) {
  data = data || {};
  var now = moment();
  this.owner = m.prop(data.owner || null);
  this.meta = m.prop(data.meta || {});
  this.status = m.prop(data.status || null);
  this.created = m.prop(data.created ? moment(data.created) : now);
  this.updated = m.prop(data.updated ? moment(data.updated) : this.created());
}

Base.prototype.import = function(model) {
  this.owner(model.owner());
  this.meta(model.meta());
  this.status(model.status());
  this.created(model.created());
  this.updated(model.updated());
};

Base.prototype.toJSON = function() {
  return {
    owner: this.owner(),
    meta: this.meta(),
    status: this.status(),
    created: this.created().format(),
    updated: this.updated().format(),
  };
};

Base.inheritedBy = function(Cls) {
  util.inherits(Cls, Base);
  Cls.from = function genericModelFactory(data) {
    return new Cls(data);
  };
};

module.exports = Base;
