'use strict';

var m = require('mithril');
var db = require('./db.js');

var warmup = module.exports = {
  _log: function(data) {
    global.logger.debug('ping response received for "%s"', this, data);
    return data;
  },

  payments: function() {
    var data = db.createPayload('ping');
    return db.request({ unauthorized: true, function: global.StamprEnv.PASSENGER_SERVICE_PAYMENTS, data: data });
  },

  dispatch: function() {
    var data = db.createPayload('ping');
    return db.request({ unauthorized: true, function: global.StamprEnv.PASSENGER_SERVICE_DISPATCH, data: data });
  },

  orders: function() {
    var data = db.createPayload('ping');
    return db.request({ unauthorized: true, function: global.StamprEnv.PASSENGER_SERVICE_ORDERS, data: data });
  },

  all: function(callback) {
    m.sync([
      warmup.payments(),
      // warmup.dispatch(),
      warmup.orders(),
    ]).then(callback.bind(null, null), callback);
  },
};
