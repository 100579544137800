'use strict';

var m = require('mithril');
var db = require('../lib/db.js');
var Base = require('./base.js');

function Payment(data) {
  data = data || {};
  Base.call(this, data);
  this.id = m.prop(data.id || null);
  this.name = m.prop(data.name || null);
  this.address_city = m.prop(data.address_city || null);
  this.address_line1 = m.prop(data.address_line1 || null);
  this.address_line1_check = m.prop(data.address_line1_check || null);
  this.address_line2 = m.prop(data.address_line2 || null);
  this.address_state = m.prop(data.address_state || null);
  this.address_zip = m.prop(data.address_zip || null);
  this.address_zip_check = m.prop(data.address_zip_check || null);
  this.brand = m.prop(data.brand || null);
  this.country = m.prop(data.country || null);
  this.cvc_check = m.prop(data.cvc_check || null);
  this.exp_month = m.prop(data.exp_month || null);
  this.exp_year = m.prop(data.exp_year || null);
  this.funding = m.prop(data.funding || null);
  this.last4 = m.prop(data.last4 || null);
  this.metadata = m.prop(data.metadata || {});
}

Base.inheritedBy(Payment);

Payment.prototype.charge = function(amount, description, reference) {
  var body = {
    source: this.id(),
    amount: amount,
  };
  if (description) {
    body.description = description;
  }
  if (reference) {
    body.reference_name = reference.name;
    body.reference_id = reference.id;
  }
  var data = db.createPayload('charge-source', body);
  return db.request({ function: config.lambda.payments, data: data });
};

Payment.prototype.import = function(model) {
  Base.prototype.import.call(this, model);
  this.id(model.id());
  this.name(model.name());
  this.address_city(model.address_city());
  this.address_line1(model.address_line1());
  this.address_line1_check(model.address_line1_check());
  this.address_line2(model.address_line2());
  this.address_state(model.address_state());
  this.address_zip(model.address_zip());
  this.address_zip_check(model.address_zip_check());
  this.brand(model.brand());
  this.country(model.country());
  this.cvc_check(model.cvc_check());
  this.exp_month(model.exp_month());
  this.exp_year(model.exp_year());
  this.funding(model.funding());
  this.last4(model.last4());
  this.metadata(model.metadata());
};

Payment.prototype.toJSON = function() {
  var data = Base.prototype.toJSON.call(this);
  data.id = this.id();
  data.name = this.name();
  data.address_city = this.address_city();
  data.address_line1 = this.address_line1();
  data.address_line1_check = this.address_line1_check();
  data.address_line2 = this.address_line2();
  data.address_state = this.address_state();
  data.address_zip = this.address_zip();
  data.address_zip_check = this.address_zip_check();
  data.brand = this.brand();
  data.country = this.country();
  data.cvc_check = this.cvc_check();
  data.exp_month = this.exp_month();
  data.exp_year = this.exp_year();
  data.funding = this.funding();
  data.last4 = this.last4();
  data.metadata = this.metadata();
  return data;
};


Payment.create = function(sourceToken) {
  var data = db.createPayload('create-source', {
    token: sourceToken,
  });
  return db.request({ function: config.lambda.payments, data: data, type: Payment });
};

Payment.read = function(sourceId) {
  var data = db.createPayload('read-source', {
    source: sourceId,
  });
  return db.request({ function: config.lambda.payments, data: data, type: Payment });
};

// TODO: Payment.update

Payment.remove = function(sourceId) {
  var data = db.createPayload('remove-source', {
    source: sourceId,
  });
  return db.request({ function: config.lambda.payments, data: data, type: Payment });
};

Payment.list = function() {
  var data = db.createPayload('list-sources');
  return db.request({ function: config.lambda.payments, data: data, type: Payment });
};

Payment.findDefault = function() {
  var data = db.createPayload('get-default-source');
  return db.request({ function: config.lambda.payments, data: data, type: Payment });
};

Payment.setDefault = function(source) {
  var data = db.createPayload('set-default-source', {
    source: source
  });
  return db.request({ function: config.lambda.payments, data: data });
};

Payment.amountToLocale = function(amount, currency) {
  if (typeof amount !== 'number' || amount !== Math.floor(amount)) {
    global.logger.warn('Payment.amountToLocale was passed a non-int value.  Only ints allowed.', typeof amount, amount);
    amount = 0;
  }
  switch (currency) {
    default:
    case 'usd':
      return '$' + (amount / 100).toFixed(2);
  }
}

module.exports = Payment;
