'use strict';

var StamprEnv = global.StamprEnv || {};
var logger = global.logger = require('loglevel');
if (StamprEnv.ENV === 'development') {
  logger.setLevel(logger.levels.TRACE);
}
else {
  logger.setLevel(logger.levels.SILENT);
}

var m = global.m = require('mithril');
var async = require('async');
var Cookies = require('js-cookie');

var StamprApp = require('../common/app.js');

var Cart = require('./models/cart.js');
var Shipment = require('./models/shipment.js');
var Pickup = require('./models/pickup.js');
var Dispatch = require('./models/dispatch.js');
var Subscription = require('./models/subscription.js');
var Payment = require('./models/payment.js');

var db = require('./lib/db.js');
var location = require('./lib/location.js');
var warmup = require('./lib/warmup.js');
var visibility = require('./lib/visibility.js');
var tour = require('./lib/tour.js');
var screen = require('./shared/screen.js');
var paymentUtils = require('./shared/payment-utils.js');
var shipmentOptions = require('./shared/shipment-options.js');

var promiseErrorHandler = require('./shared/promise-error-handler.js');

var config = global.config = {
  auth0: {
    clientId: StamprEnv.AUTH_ID,
    hostname: StamprEnv.AUTH_ENDPOINT,
  },

  cognito: {
    identityPoolId: StamprEnv.COGNITO_POOL,
    authRoleArn: StamprEnv.COGNITO_ROLE,
    providerEndpoint: StamprEnv.AUTH_ENDPOINT,
  },

  lambda: {
    region: StamprEnv.PASSENGER_SERVICE_REGION,
    orders: StamprEnv.PASSENGER_SERVICE_ORDERS,
    payments: StamprEnv.PASSENGER_SERVICE_PAYMENTS,
    dispatch: StamprEnv.PASSENGER_SERVICE_DISPATCH,
  },
};

// TODO: abstract
global.Stripe.setPublishableKey(StamprEnv.STRIPE_PUBLISHABLE_KEY);

m.route.mode = 'hash';

var passenger = global.StamprPassenger = new StamprApp(document.getElementById('app'), config);
passenger.vm = screen.vm;

screen.vm.hoursOfOperation = m.prop({
  '0': null, //{ start: 12, end: 20 },
  '1': { start: 10, end: 16 },
  '2': { start: 10, end: 16 },
  '3': { start: 10, end: 16 },
  '4': { start: 10, end: 16 },
  '5': { start: 10, end: 16 },
  '6': null, //{ start: 12, end: 20 },
});

screen.vm.regionZipCodes = m.prop([
  // '92037', // la jolla, torrey pines
  '92101', // gas lamp
  '92102', // south park
  '92103', // mission hills
  '92104', // north park
  // '92105', // city heights
  '92106', // point loma; liberty station/south
  '92107', // point loma; ob
  '92108', // mission valley
  '92109', // pb
  '92110', // freeway interchange
  // '92111', // clairemont/miramar
  // '92116', // normal heights
  // '92117', // north clairemont
  // '92122', // university city
  '92121' // sorento valley
]);

// TODO: store should be split up into component parts e.g. cart, pickups, session
var userEnv = [];

var execEnv = function(type, callback) {
  var tasks = {};
  var bootStart = new Date().getTime();
  logger.debug('exec env starting', type);
  userEnv.forEach(function(step) {
    tasks[step.name] = function(done) {
      var start = new Date().getTime();
      logger.debug('booting %s: start', step.name);
      step[type](function() {
        logger.debug('booting %s: end (%ss)', step.name, ((new Date().getTime() - start) / 1000).toFixed(2));
        done.apply(this, arguments);
      });
    };
  });
  async.parallel(tasks, function(err) {
    logger.debug('exec env done %s: took %ss', type, ((new Date().getTime() - bootStart) / 1000).toFixed(2));
    callback && callback.apply(this, arguments);
  });
}

var routes = {
  '/': require('./screens/home.js'),
  '/pickup': require('./screens/pickup.js'),
  '/add-shipment': require('./screens/create.js'),
  '/edit-shipment/:itemId': require('./screens/edit.js'),
  '/shipments': require('./screens/shipments.js'),
  '/cart': require('./screens/cart.js'),
  '/dispatched': require('./screens/dispatched.js'),
  '/settings': require('./screens/settings.js'),
};

var initialRoute = (global.location.hash || '#/').substr(1);
if (initialRoute === '/add-shipment') { // FIXME: override bc buggy
  initialRoute = '/';
}

var setLoggedOutRoute = function() {
  var currentRoute = m.route() || initialRoute;
  var unauthRoutes = {};
  unauthRoutes[currentRoute] = require('./screens/welcome.js');
  logger.info('init route. currently at %s', currentRoute, unauthRoutes);
  m.route(document.getElementById('app'), currentRoute, unauthRoutes);
};

userEnv.push({
  name: 'analytics',
  setup: function(callback) {
    // global.analytics.identify(passenger.auth.client.session.profile.email, {
    //   auth0: passenger.auth.client.session.profile.user_id,
    //   name: passenger.auth.client.session.profile.email !== passenger.auth.client.session.profile.name ? passenger.auth.client.session.profile.name : passenger.auth.client.session.profile.nickname,
    //   email: passenger.auth.client.session.profile.email,
    //   passenger: {
    //     id: passenger.auth.client.session.profile.user_id,
    //   },
    // }, {
    //   integrations: {
    //     Intercom: {
    //       user_hash: passenger.auth.client.session.profile.intercom_secure_mode,
    //     },
    //   },
    // });
    var loginCount = passenger.auth.client.session.getItem('settings:logincount') || 0;
    passenger.auth.client.session.setItem('settings:logincount', loginCount + 1);
    callback();
  },
  teardown: function(callback) {
    // global.analytics.reset();
    callback();
  },
});

userEnv.push({
  name: 'db',
  setup: function(callback) {
    db.authorization = passenger.auth.client.session.authToken;
    callback();
  },
  teardown: function(callback) {
    db.authorization = null;
    callback();
  },
});

userEnv.push({
  name: 'store',
  setup: function(callback) {
    screen.vm.session = passenger.auth.client.session;
    async.parallel([
      function(done) {
        // HACK: booting doesn't support dependencies.  this task is dependent on analytics finishing
        setTimeout(function() {
          var loginCount = passenger.auth.client.session.getItem('settings:logincount') || 0;
          var userIsNoob = loginCount < 3;
          screen.vm.userIsNoob(userIsNoob);
          done();
        }, 100);
      },
      function(done) {
        // var connected = global.connected = require('./lib/connected-accounts.js')(StamprEnv.CONNECTEDACCOUNTS_ENDPOINT, passenger.auth.client.session.authToken);
        // connected.list(function(err, data) {
        //   if (err) {
        //     done(null, err); // non-fatal error.  return it as data
        //   }
        //   else {
        //     screen.vm.integrations = data || {};
        //     var integrationState = {};
        //     Object.keys(data).forEach(service => {
        //       var driver = integrations.getDriver('stampr-' + service);
        //       var search = 'CA-' + driver.name + '-';
        //       integrationState[service] = m.prop(!!integrations.getIdentity(search, true));
        //     });
        //     screen.vm.integrationState(integrationState);
        //     done();
        //   }
        // });
        screen.vm.integrations = {};
        done();
      },
      function(done) {
        screen.vm.refreshPickups((err, result) => done(null, err || result));
      },
      function(done) {
        screen.vm.refreshCart((err, result) => done(null, err || result));
      },
      // function(done) {
      //   Subscription.find(screen.vm.SUBSCRIPTION_PREFIX).then(function(subscription) {
      //     var activeSubscription = subscription;
      //     var activePlan = activeSubscription && activeSubscription.plan() ? activeSubscription.plan() : null;
      //     var planMetadata = ((activePlan || {}).metadata || {});
      //     var isBusinessAccount = true; // subscriptions removed.  always business account
      //     // var isBusinessAccount = 'BUSINESS' in planMetadata;
      //     shipmentOptions.isFreeShippingEligible = isBusinessAccount;
      //     done();
      //   }, err => done(null, err));
      // },
    ], callback);
  },
  teardown: function(callback) {
    screen.vm.reset();
    shipmentOptions.isFreeShippingEligible = false;
    callback();
  },
});

userEnv.push({
  name: 'tour',
  setup: function(callback) {
    tour.init(localStorage);
    callback();
  },
  teardown: function(callback) {
    tour.destroy();
    callback();
  },
});

userEnv.push({
  name: 'location',
  setup: function(callback) {
    location.handler = function(position) {
      passenger.auth.client.session.setItem('meta:lastknownposition', position);
    };
    callback();
  },
  teardown: function(callback) {
    location.handler = function(position) {}; // noop
    callback();
  },
});

userEnv.push({
  name: 'visibility',
  setup: function(callback) {
    visibility.handler = function(visible) {
      screen.vm.visible(visible);
    };
    callback();
  },
  teardown: function(callback) {
    visibility.handler = function(visible) {}; // noop
    callback();
  },
});

userEnv.push({
  name: 'profile',
  setup: function(callback) {
    passenger.auth.lock.getProfile(passenger.auth.client.session.authToken, function(err, profile) {
      if (err) return callback(err);
      passenger.auth.client.session.profile = profile;
      callback(null);
    });
  },
  teardown: function(callback) {
    callback();
  },
});

userEnv.push({
  name: 'warmup',
  setup: function(callback) {
    // warmup all services to be more responsive
    warmup.all(callback);
  },
  teardown: function(callback) { callback() },
});

var locationAlreadyInit = false;

passenger.init(function(err) {
  logger.info('booting', new Date().getTime());
  if (err) {
    global.logger.error('fatal', err);
    alert('A fatal error occurred while loading the application.  You can try refreshing your browser to fix this problem.');
    global.localStorage.removeItem('session');
    return;
  }

  // FIXME: because this uses cognito user must log in again
  // var session = Cookies.getJSON('www_session') || {};
  // if (session.authToken) {
  //   console.info('www_session cookie detected');
  //   console.log('www_session cookie', session);
  //   if (!passenger.auth.client.session.authToken) {
  //     console.log('no authToken; importing from www_session cookie');
  //     passenger.auth.client.session.authToken = 'value';
  //   }
  //   if (!passenger.auth.client.session.profile || Object.keys(passenger.auth.client.session.profile).length === 0) {
  //     console.log('no profile; importing from www_session cookie');
  //     passenger.auth.client.session.profile = session.profile;
  //   }
  // }

  visibility.init();

  var refreshCredentialsEvery = 600000; // 10m
  setInterval(function() {
    if (passenger.auth.client.isAuthenticated()) {
      logger.debug('refreshing credentials');
      passenger.auth.client.credentials.refresh(function(err) {
        if (err) {
          logger.warn('problem refreshing credentials', err);
          passenger.auth.client.close();
        }
        else {
          passenger.auth.client._persistTokensToSession();
        }
      });
    }
  }, refreshCredentialsEvery);

  passenger.auth.client.on('authenticated', function() {
    if (!locationAlreadyInit) location.init();
    locationAlreadyInit = true;
    execEnv('setup');
  });
  passenger.auth.client.on('deauthenticated', function() {
    execEnv('teardown');
  });
});

userEnv.push({
  name: 'route',
  setup: function(callback) {
    var defaultRoute = '/';
    var currentRoute = m.route() || initialRoute;
    m.route(document.getElementById('app'), defaultRoute, routes);
    setTimeout(function() {
      if (currentRoute !== defaultRoute) {
        m.route(currentRoute);
      }
      else {
        m.route(m.route()); // refresh
      }
      logger.info('init route. currently at %s', currentRoute, routes);
      callback();
    }, 100);
  },
  teardown: function(callback) {
    setLoggedOutRoute();
    callback();
  },
});

setLoggedOutRoute();

if (StamprEnv.ENV !== 'production') {
  global.document.body.className += ' development-mode';
}
