'use strict';

var autocomplete = module.exports = {
  config: function(val) {
    return function(el, isInit) {
      if (!isInit) {
        el.setAttribute && el.setAttribute('autocomplete', val);
      }
    };
  },
};
