'use strict';

// rules:
// object 2d
// underscores; not hyphens
// svg naming = [id]_[classifier]_[variation]

module.exports = {
  // stampr_logo_airplane_color: require('./svgs/stampr_logo_airplane_color.js'),
  stampr_logo_airplane_flat: require('./svgs/stampr_logo_airplane_flat.js'),
  // stampr_logo_airplane_bw: require('./svgs/stampr_logo_airplane_bw.js'),

  // stampr_logo_horizontal_white: require('./svgs/stampr_logo_horizontal_white.js'),
  // stampr_logo_horizontal_black: require('./svgs/stampr_logo_horizontal_black.js'),
  // stampr_logo_horizontal_dark: require('./svgs/stampr_logo_horizontal_dark.js'),

  passenger_logo_horizontal_white: require('./svgs/passenger_logo_horizontal_white.js'),
  // passenger_logo_horizontal_black: require('./svgs/passenger_logo_horizontal_black.js'),
  // passenger_logo_horizontal_dark: require('./svgs/passenger_logo_horizontal_dark.js'),

  // outbox_logo_horizontal_white: require('./svgs/outbox_logo_horizontal_white.js'),
  // outbox_logo_horizontal_black: require('./svgs/outbox_logo_horizontal_black.js'),
  // outbox_logo_horizontal_dark: require('./svgs/outbox_logo_horizontal_dark.js'),

  stripe_logo_poweredby_outline: require('./svgs/stripe_logo_poweredby_outline.js'),

  visa_logo_card_flat: require('./svgs/visa_logo_card_flat.js'),
  mastercard_logo_card_flat: require('./svgs/mastercard_logo_card_flat.js'),
  amex_logo_card_flat: require('./svgs/amex_logo_card_flat.js'),

  // loading_hearts: require('./svgs/loading_hearts.js'),
  // loading_puff: require('./svgs/loading_puff.js'),
  // loading_three_dots: require('./svgs/loading_three_dots.js'),

  // animal_chick_dark: require('./svgs/animal_chick_dark.js'),
  // animal_cat_dark: require('./svgs/animal_cat_dark.js'),
  // animal_pig_dark: require('./svgs/animal_pig_dark.js'),
  // animal_horse_dark: require('./svgs/animal_horse_dark.js'),
};
