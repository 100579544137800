'use strict';

var m = require('mithril');

var button = require('polythene/button/button');
var textfield = require('polythene/textfield/textfield');
var iconClear = require('mmsvg/google/msvg/content/clear');

var dialogs = require('../shared/dialogs.js');

module.exports = function(ctrl, args, opts, callback) {
  var model = {
    fee_paid: m.prop(opts.fee_paid || false),
  };
  return {
    backdrop: true,
    title: 'Are you sure?',
    body: [
      m('p', 'Please confirm that you would like to immediately cancel this pickup.'),
      !model.fee_paid() ? null : m('p', 'Your pickup fee is non-refundable.'),
      m('div', { style:'text-align:right;' }, [
        m(button, {
          raised: false,
          label: 'Don\'t Cancel',
          events: {
            onclick: function() {
              callback(null, null);
            },
          },
        }),
        m('span.pe-dark-theme', [
          m(button, {
            raised: true,
            label: 'Cancel Pickup',
            events: {
              onclick: function() {
                callback(null, model);
              },
            },
          }),
        ]),
      ]),
    ],
  };
};
