'use strict';

var m = require('mithril');
var button = require('polythene/button/button');
var list = require('polythene/list/list');
var listTile = require('polythene/list-tile/list-tile');
var card = require('polythene/card/card');
var iconButton = require('polythene/icon-button/icon-button');
var iconClear = require('mmsvg/google/msvg/content/clear');
var spinner = require('polythene/spinner/indeterminate-spinner');
var Cart = require('../models/cart.js');
var Subscription = require('../models/subscription.js');
var Shipment = require('../models/shipment.js');
var screen = require('../shared/screen.js');
var pageLayout = require('../layouts/page.js');
var shipmentCard = require('../components/shipment-card.js');
var message = require('../components/message.js');
var asyncButton = require('../components/async-button.js');

var shipmentOptions = require('../shared/shipment-options.js');
var promiseErrorHandler = require('../shared/promise-error-handler.js');

var Cart = module.exports = screen.create({
  controller: function(args) {
    var ctrl = this;
    ctrl.icon = m(iconButton, {
      icon: {
        msvg: iconClear,
      },
      events: {
        onclick: () => m.route('/'),
      },
    });
    ctrl.activeSubscription = m.prop(null);
    // process.nextTick(function() { Subscription.find(ctrl.vmApp.SUBSCRIPTION_PREFIX).then(ctrl.activeSubscription); }); // HACK: don't block

    ctrl.cartItemsEmpty = m.prop(false); //

    ctrl.removeCartItem = function(cartItem, deferred) {
      m.startComputation();
      ctrl.vmApp.cart().remove(cartItem.itemId);
      ctrl.vmApp.cart().save().then(function(cart) {
        m.endComputation();
        if (!cart.items().length) {
          ctrl.cartItemsEmpty(true);
        }
        deferred.resolve();
        return cart;
      }, promiseErrorHandler(function(err) {
        m.endComputation();
        deferred.reject(err);
      }));
    };

    ctrl.addCartItemToPickup = function(cartItem, deferred) {
      var data = {
        pickupId: ctrl.vmApp.openPickup().pickupId(),
        source: cartItem.source,
        authorization: null,
        amount: 0,
        image: cartItem.image || null,
        image_thumbnail: cartItem.image_thumbnail || null,
        description: cartItem.description || null,
        packing_instructions: cartItem.packing_instructions || null,
        payment_details: null,
        shipping_price: cartItem.shipping_price || null,
        multis: cartItem.multis,
        multi_items: cartItem.multi_items || null,
        origin_address: cartItem.origin_address || {},
        destination_address: cartItem.destination_address || {},
        size: cartItem.size || null,
        packing: cartItem.packing || null,
        handling: cartItem.handling || null,
        shipping: cartItem.shipping || null,
        extras: cartItem.extras || {},
      };
      // FIXME: work around cart item schema not matching shipment
      if (Array.isArray(cartItem.extras)) {
        var bugExtras = cartItem.extras;
        var extras = {};
        if (bugExtras.insurance) extras.insurance = bugExtras.insurance;
        if (bugExtras.gift_wrap) extras.gift_wrap = bugExtras.gift_wrap;
        data.extras = extras;
      }
      Shipment.create(data).then(function(shipment) {
        ctrl.removeCartItem(cartItem, deferred);
        return shipment;
      }, promiseErrorHandler(deferred.reject));
    };
  },
  view: pageLayout('Incoming orders', function(ctrl, args) {
    var cartItems = ctrl.vmApp.cart && ctrl.vmApp.cart() ? ctrl.vmApp.cart().items() : [];
    var content = [];
    var activeSubscription = ctrl.activeSubscription();
    var activePlan = activeSubscription && activeSubscription.plan() ? activeSubscription.plan() : null;
    var planMetadata = ((activePlan || {}).metadata || {});
    var isBusinessAccount = true; // subscriptions removed.  always business account
    // var isBusinessAccount = 'BUSINESS' in planMetadata;
    if (!ctrl.vmApp.isPickupActive() && cartItems && cartItems.length > 0) {
      content.push(m(message, {
        type: 'info',
        outline: true,
        content: [
          m('h5', 'No pickup scheduled'),
          m('p', 'To have cart items picked up and shipped, you must first create a pick up and add items to it.'),
          m('div', { style: 'text-align:right;' }, [
            m(button, {
              raised: true,
              label: 'Schedule Pickup',
              url: {
                href: '/pickup',
                config: m.route,
              },
            }),
          ]),
        ],
      }));
    }
    /*
    if (!activePlan) {
      content.push(m('div.pe-fit.layout.center-center', [
        m('div.block', [
          m(spinner, {
            type: 'large',
            show: true,
          }),
        ]),
      ]));
    }
    else */ if (isBusinessAccount) {
      var pickupShipments;
      if (cartItems && cartItems.length) {
        console.log('sorting cartItems', cartItems);
        cartItems.sort(function(a, b) {
          console.log('sorting cartItems; comparing', a, b);
          var keyA = new Date(a.updated),
              keyB = new Date(b.updated);
          // Compare the 2 dates
          if(keyA > keyB) return -1;
          if(keyA < keyB) return 1;
          return 0;
        });
        pickupShipments = [
          m('h6.layout.horizontal', { style: 'margin: 25px 15px 5px;' }, [
            m('div.block', 'Orders (' + cartItems.length + ')'),
          ]),
          m('div.layout.wrap.dispatched-shipment-cards', cartItems.map(function(cartItem) {
            var actions = [
              m(asyncButton, {
                label: 'Add to Pickup',
                action: ctrl.addCartItemToPickup.bind(ctrl, cartItem),
                disabled: !ctrl.vmApp.isPickupActive(),
              }),
              m('div.flex', ' '),
              m(asyncButton, {
                class: 'button-danger-text',
                label: 'Remove',
                action: ctrl.removeCartItem.bind(ctrl, cartItem),
              }),
            ];
            var cardArgs = shipmentCard.vm({
              shipment: new Shipment(cartItem),
              actions: actions,
              verbose: true,
              displayTotal: false,
              displayStatus: true,
              displayTimestamp: true,
              disableEdit: true,
              statusTheme: 'dark-theme',
            });
            return m(shipmentCard, cardArgs);
          })),
        ];
      }
      else if (ctrl.cartItemsEmpty()) {
        pickupShipments = m(message, {
          outline: true,
          type: 'info',
          content: [
            m('h5', 'All done!'),
            m('p', 'There are no more incoming orders at this time.'),
            !ctrl.vmApp.isPickupActive() ? null : m('div', { style: 'text-align:right;' }, [
              m(button, {
                raised: true,
                label: 'View open pickup',
                url: {
                  href: '/dispatched',
                  config: m.route,
                },
              }),
            ]),
          ],
        });
      }
      else {
        pickupShipments = m(message, {
          outline: true,
          type: 'info',
          content: [
            m('h5', 'No incoming orders'),
            m('p', 'To automatically import orders, create a new passenger shipment in the fulfillment dashboard.'),
            m('p', 'This will automatically import your orders into Passenger and they will display here when available.'),
          ],
        });
      }
      content.push(m('div.block', pickupShipments));
    }
    else {
      content.push(m('div.block', [
        m(message, {
          outline: false,
          type: 'warn',
          content: [
            m('h5', 'Business subscription required'),
            m('p', 'Connect Stampr Passenger to other services like Etsy or Shopify and orders can be automatically imported into Stampr Passenger.'),
            m('p', 'To use this feature, a Business-level subscription is required.'),
            m('div', { style: 'text-align:right;' }, [
              m(button, {
                raised: true,
                label: 'Edit Subscription',
                url: {
                  href: '/settings',
                  config: m.route,
                },
              }),
            ]),
          ],
        }),
      ]));
    }
    return m('div.layout.vertical.pe-fit', content);
  }),
});
