'use strict';

var m = require('mithril');
var async = require('async');

var fab = require('polythene/fab/fab');
var button = require('polythene/button/button');
var icon = require('polythene/icon/icon');
var iconButton = require('polythene/icon-button/icon-button');
var card = require('polythene/card/card');
var menu = require('polythene/menu/menu');
var list = require('polythene/list/list');
var listTile = require('polythene/list-tile/list-tile');

var Pickup = require('../models/pickup.js');
var Payment = require('../models/payment.js');
var Dispatch = require('../models/dispatch.js');
var Shipment = require('../models/shipment.js');

var screen = require('../shared/screen.js');
var dialogs = require('../shared/dialogs.js');
var pageLayout = require('../layouts/page.js');
// var dispatchMap = require('../components/dispatch-map.js');
var dispatchMap = require('../components/dispatch-map-swiftwrapper.js');
var message = require('../components/message.js');
var asyncButton = require('../components/async-button.js');
var shipmentCard = require('../components/shipment-card.js');

var iconAdd = require('mmsvg/google/msvg/content/add');
var iconClear = require('mmsvg/google/msvg/content/clear');
var iconMoreVert = require('mmsvg/google/msvg/navigation/more-vert');
var iconCommunicationMessage = require('mmsvg/google/msvg/communication/message');
var iconCommunicationCall = require('mmsvg/google/msvg/communication/call');
var iconArrowBack = require('mmsvg/google/msvg/navigation/arrow-back');

var shipmentOptions = require('../shared/shipment-options.js');

var promiseErrorHandler = require('../shared/promise-error-handler.js');
var filter = require('../shared/filter.js');

var sortByUpdate = require('../lib/sort.js').sortByUpdate;

var shipmentsGrandTotal = function(shipments) {
  return Payment.amountToLocale(shipments.reduce(function(prev, curr) {
    return prev + curr.amount();
  }, 0), 'usd');
};

var handleCancelPickup = function(ctrl, args, deferredAsyncPromise) {
  var pickup = ctrl.vmApp.openPickup();
  var fee_paid = (pickup ? pickup.amount() : 0) > 0;
  dialogs.show('confirmCancelPickup', ctrl, args, {
    fee_paid: fee_paid,
  }, function(err, result) {
    if (err) {
      global.logger.warn(err);
      deferredAsyncPromise.reject(err);
      return;
    }
    else if (!result) {
      global.logger.debug('not cancelled');
      deferredAsyncPromise.resolve();
      return;
    }
    ctrl.cancelPickup(deferredAsyncPromise);
  });
};

var Dispatched = module.exports = screen.create({
  vm: function(ctrl, args) {
    var vm = {};
    vm.location = args.location || dispatchMap.vm();
    return vm;
  },

  controller: function(args) {
    var ctrl = this;
    var vm = ctrl.vm;
    ctrl.icon = m(iconButton, {
      icon: {
        msvg: iconArrowBack,
      },
      url: {
        href: '/',
        config: m.route,
      },
    });
    vm.location.dispatch = m.prop(null);
    setTimeout(function() { vm.location.dispatch = ctrl.vmApp.openDispatch; }, 0);

    // ctrl.createDispatch = function(deferredAsyncPromise) {
    //   m.startComputation();
    //   var pickup = ctrl.vmApp.openPickup();
    //   async.waterfall([
    //     function step_createDispatch(next) {
    //       var customerName = ctrl.vmApp.session.profile.name;
    //       var customerPhone = ctrl.vmApp.session.profile.phone;
    //       var formatted_address = ((pickup.location().address || {}).formatted_address || '');
    //       var dispatch = Dispatch.create(pickup.pickupId(), customerName, customerPhone, formatted_address, pickup.pickup_instructions());
    //       dispatch.then(function(dispatch) {
    //         global.logger.info('Dispatch created', dispatch);
    //         setTimeout(next, 0, null, dispatch);
    //         return dispatch;
    //       }, next);
    //     },
    //     function step_bindDispatchToPickup(dispatch, next) {
    //       pickup.dispatch(dispatch.dispatchId()).then(function(data) {
    //         global.logger.info('Dispatch bound to pickup', dispatch);
    //         setTimeout(next, 0, null, dispatch);
    //         return data;
    //       }, next);
    //     },
    //   ], function(err, dispatch) {
    //     deferredAsyncPromise.resolve();
    //     ctrl.vm.location.dispatch = m.prop(dispatch);
    //     ctrl.vmApp.refreshPickups();
    //     m.endComputation();
    //     if (err) {
    //       alert('Error: ' + err.message);
    //       return;
    //     }
    //   });
    // };

    ctrl.cancelPickup = function(deferredAsyncPromise) {
      m.startComputation();
      m.sync(filter.nulls([
        ctrl.vmApp.openPickup() ? ctrl.vmApp.openPickup().cancel() : null,
        ctrl.vmApp.openDispatch() ? ctrl.vmApp.openDispatch().cancel() : null,
      ])).then(function(args) {
        deferredAsyncPromise.resolve();
        ctrl.vmApp.openPickup(null);
        ctrl.vmApp.openDispatch(null);
        ctrl.vmApp.pickups = Pickup.list(); // refresh pickups list
        m.endComputation();
        global.logger.info('Pickup and dispatch cancelled');
        // TODO: display some kind of toast message or something notifying of success
        m.route('/');
      }, promiseErrorHandler(function() {
        deferredAsyncPromise.reject();
        m.endComputation();
      }));
    };

    ctrl.cancelShipment = function(shipment, deferredAsyncPromise) {
      m.startComputation();
      shipment.cancel().then(function(shipment) {
          deferredAsyncPromise.resolve();
          setTimeout(m.endComputation, 0);
        return shipment;
      }, promiseErrorHandler(function() {
        m.endComputation();
        deferredAsyncPromise.reject();
      }));
    };

    ctrl.menuStates = {};
  },

  view: pageLayout('Pick up progress', function(ctrl, args) {
    var vmApp = ctrl.vmApp;
    var pickup = vmApp.openPickup ? vmApp.openPickup() : null;
    var shipments = pickup ? vmApp.pickupShipments().slice(0) : [];
    if (!pickup) {
      return m(message, {
        type: 'info',
        outline: true,
        content: [
          m('h5', 'No pickup scheduled'),
          m('p', 'You do not currently have a pickup scheduled.'),
          m('div', { style: 'text-align:right;' }, [
            m(button, {
              raised: true,
              label: 'Schedule Pickup',
              url: {
                href: '/pickup',
                config: m.route,
              },
            }),
            m(button, {
              raised: true,
              label: 'Return to Home',
              url: {
                href: '/',
                config: m.route,
              },
            }),
          ]),
        ],
      });
    }
    var pickupShipments;
    if (shipments && shipments.length) {
      sortByUpdate(shipments);
      pickupShipments = [
        m('h6.layout.horizontal', { style: 'margin: 25px 15px 5px;' }, [
          m('div.block', 'Shipments (' + shipments.length + ')'),
          m('div.block.flex', ' '),
          m('div.block', 'Total: ' + shipmentsGrandTotal(shipments)),
        ]),
        m('div.layout.wrap.dispatched-shipment-cards', shipments.map(function(shipment) {
          var actions = [
            m(button, {
              label: 'Edit',
              url: {
                href: '/edit-shipment/' + shipment.itemId(),
                config: m.route,
              },
            }),
            m('div.flex', ' '),
            m(asyncButton, {
              class: 'button-danger-text',
              label: 'Remove',
              action: ctrl.cancelShipment.bind(ctrl, shipment),
            }),
          ];
          var cardArgs = shipmentCard.vm({
            shipment: shipment,
            actions: actions,
            displayTotal: true,
          });
          return m(shipmentCard, cardArgs);
        })),
      ];
    }
    else {
      pickupShipments = m(message, {
        outline: true,
        content: [
          m('h5', 'What are we picking up?'),
          m('p', 'A courier will be there soon, but we still need to know what we\'re picking up.'),
          m('p', 'Every box we\'ll be sending for you is called a "shipment".  Please add your shipments now.'),
          // m('div', { style: 'text-align:right;' }, [
          //   m(button, {
          //     raised: true,
          //     label: 'Add Shipment',
          //     url: {
          //       href: '/add-shipment',
          //       config: m.route,
          //     },
          //   }),
          // ]),
        ],
      });
    }
    var pickup = ctrl.vmApp.openPickup();
    var dispatch = ctrl.vmApp.openDispatch();
    var driver = dispatch ? dispatch.driver() : {};
    var driverShortName = '';
    var driverNameParts = (driver.name || '').trim().split(/\s+/);
    if (driverNameParts.length) {
      driverShortName = driverNameParts[0] + (driverNameParts[1] ? ' ' + driverNameParts[1][0] + '.' : '');
    }
    // var dispatchMissing = m(message, {
    //   type: 'warn',
    //   outline: false,
    //   content: [
    //     m('h5', 'Pickup not scheduled'),
    //     m('p', 'You have created your pickup but have not yet scheduled it which means no courier will be dispatched.'),
    //     m('p', 'Would you like to schedule the pickup now?'),
    //     m('div', { style: 'text-align:right;' }, [
    //       m(asyncButton, {
    //         label: 'Schedule Pickup for Now',
    //         raised: true,
    //         action: function(deferred, eventArgs) {
    //           ctrl.createDispatch(deferred);
    //         },
    //       }),
    //       global.StamprEnv.ENV === 'production' ? null : m(button, {
    //         label: 'Dev Ignore',
    //         events: {
    //           onclick: function() {
    //             m.startComputation();
    //             ctrl.vmApp.openDispatch(new Dispatch());
    //             m.endComputation();
    //           },
    //         },
    //       }),
    //     ]),
    //   ],
    // });

    var content = [];

    // if (dispatch) {
      // content.push(m('div.block', { style:'background-color:#FDFAF4;' }, [
      //   m('div', { style: 'position:relative;height:260px;' }, [
      //     m(dispatchMap, ctrl.vm.location),
      //     m('div.block.layout.vertical.dispatched-major-actions', { style: 'position:absolute;top:15px;right:15px;bottom:83px;width:50px;', }, [
      //       m('div.flex', ' '),
      //       m('div.dispatched-driver-contact-button.dispatched-driver-contact-button-call', [
      //         m(iconButton, {
      //           icon: {
      //             msvg: iconCommunicationCall,
      //           },
      //           url: {
      //             href: 'tel:' + driver.phone,
      //           },
      //         }),
      //       ]),
      //       m('div.dispatched-driver-contact-button.dispatched-driver-contact-button-text', [
      //         m(iconButton, {
      //           icon: {
      //             msvg: iconCommunicationMessage,
      //           },
      //           url: {
      //             href: 'sms:' + driver.phone,
      //           },
      //         }),
      //       ]),
      //       m('div.flex', ' '),
      //     ]),
      //   ]),
      // ]));
      content.push(m('div.block', { style: 'margin:15px 0;' }, [
        m(button, {
          class: 'create-shipment-fab button-block',
          label: 'Add Shipment to this Pickup',
          raised: true,
          url: {
            href: '/add-shipment',
            config: m.route,
          },
        }),
      ])),
      content.push(m('div.block', pickupShipments));
      content.push(m('div.block', { style: 'margin:15px 0 85px;' }, [
        m(asyncButton, {
          class: 'button-block button-danger-background button-outline',
          label: 'Cancel pickup',
          // raised: true,
          action: function(deferred, eventArgs) {
            handleCancelPickup(ctrl, args, deferred);
          },
        }),
      ]));
    // }
    // else {
    //   content.push(dispatchMissing);
    // }
    return m('div.layout.vertical.pe-fit.dispatched-shipments-container', content);
  }),
});
