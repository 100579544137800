'use strict';

var m = require('mithril');
var db = require('../lib/db.js');
var Base = require('./base.js');

function Dispatch(data) {
  data = data || {};
  Base.call(this, data);
  this.dispatchId = m.prop(data.dispatchId || null);
  this.pickupId = m.prop(data.pickupId || null);
  this.test = m.prop('test' in data ? data.test : false);
  this.driver = m.prop(data.driver || {});
  this.trackingMapUrl = m.prop(data.trackingMapUrl || null);
  this.location = m.prop(data.location || { address: '' }); // { address, name, phone, notes }; address required
}

Base.inheritedBy(Dispatch);

Dispatch.prototype.cancel = function() {
  throw new Error('dispatch (cancel) has been disabled');
  var _this = this;
  var data = db.createPayload('cancel-dispatch', {
    job: this.dispatchId(),
  });
  var changedDispatch = db.request({ function: config.lambda.dispatch, data: data, type: Dispatch });
  changedDispatch.then(function(dispatch) {
    _this.import(dispatch);
    return dispatch;
  });
  return changedDispatch;
};

Dispatch.prototype.import = function(model) {
  Base.prototype.import.call(this, model);
  this.dispatchId(model.dispatchId());
  this.pickupId(model.pickupId());
  this.test(model.test());
  this.driver(model.driver());
  this.location(model.location());
  this.trackingMapUrl(model.trackingMapUrl());
};

Dispatch.prototype.toJSON = function() {
  var data = Base.prototype.toJSON.call(this);
  data.dispatchId = this.dispatchId();
  data.pickupId = this.pickupId();
  data.test = this.test();
  data.driver = this.driver();
  data.location = this.location();
  data.trackingMapUrl = this.trackingMapUrl();
  return data;
};

Dispatch.estimate = function(address) {
  throw new Error('dispatch (estimate) has been disabled');
  // var data = db.createPayload('estimate-dispatch', {
  //   street: address.street,
  //   city: address.city,
  //   state: address.state,
  //   postal: address.postal,
  // });
  var data = db.createPayload('estimate-dispatch', {
    address: address,
  });
  return db.request({ function: config.lambda.dispatch, data: data });
};

Dispatch.create = function(pickupId, customerName, customerPhone, address, deliveryInstructions) {
  throw new Error('dispatch (create) has been disabled');
  var data = db.createPayload('create-dispatch', {
    pickupId: pickupId,
    name: customerName,
    phone: customerPhone,
    address: address,
    // address: {
    //   street: address.street,
    //   city: address.city,
    //   state: address.state,
    //   postal: address.postal,
    // },
    instructions: deliveryInstructions,
  });
  return db.request({ function: config.lambda.dispatch, data: data, type: Dispatch });
};

Dispatch.read = function(dispatchId) {
  throw new Error('dispatch (read) has been disabled');
  var data = db.createPayload('read-dispatch', {
    job: dispatchId,
  });
  return db.request({ function: config.lambda.dispatch, data: data, type: Dispatch });
};

module.exports = Dispatch;
