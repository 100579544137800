'use strict';

var m = require('mithril');

var button = require('polythene/button/button');
var textfield = require('polythene/textfield/textfield');
var iconClear = require('mmsvg/google/msvg/content/clear');

var dialogs = require('../shared/dialogs.js');
var autocomplete = require('../shared/autocomplete.js');

module.exports = function(ctrl, args, opts, callback) {
  var model = {
    formatted_address: m.prop(opts.formatted_address || ''),
    pickup_instructions: m.prop(opts.pickup_instructions || ''),
  };

  var updateState = function(target) {
    return function(state) {
      // FIXME: since icons are wrapped in m() they cannot be used like this.  need access to direct icon
      // switch (target) {
      //   case 'number':
      //     global.logger.debug('number element', state.el);
      //     state.el.style.backgroundImage = 'url("data:image/svg+xml;utf8,' + checkout.cardNumberToTypeSvg(state.value) + '")';
      //   break;
      // }
      model[target](state.value);
    };
  };
  var content = m('form.form.add-payment-dialog[autocomplete="on"]', {
    // XXX: onsubmit wouldn't fire no matter what. hacky workaround that gives us autocomplete
    // for whatever reason triggering submit via js fails.  putting a regular submit button succeeds
    // this exists just-in-case
    config: function(el, isInit) {
      if (!isInit) {
        el.onsubmit = function(event) {
          event.preventDefault();
          global.logger.debug('form submit prevented');
          return false;
        };
      }
    }
  }, [
    m(textfield, {
      label: 'Pickup Street Address',
      name: 'street-address',
      floatingLabel: true,
      required: true,
      error: 'Please enter a valid street address',
      hideRequiredMark: true,
      value: model['formatted_address'],
      getState: updateState('formatted_address'),
      config: autocomplete.config('street-address'),
    }),
    m(textfield, {
      label: 'Pickup instructions',
      help: 'e.g. gate code, anything the driver needs to know',
      name: 'pickup-instructions',
      floatingLabel: true,
      required: false,
      hideRequiredMark: true,
      multiLine: true,
      value: model['pickup_instructions'],
      getState: updateState('pickup_instructions'),
    }),
  ]);
  return {
    backdrop: true,
    title: 'Edit pickup address',
    body: [
      content,
      m('div', { style:'text-align:right;' }, [
        m(button, {
          raised: true,
          label: 'Cancel',
          events: {
            onclick: function() {
              callback(null, null);
            },
          },
        }),
        m('span.pe-dark-theme', [
          m(button, {
            raised: true,
            label: 'Use Address',
            events: {
              onclick: function() {
                callback(null, model);
              },
            },
          }),
        ]),
      ]),
    ],
  };
};
