'use strict';

var m = require('mithril');
var throttle = require('throttleit');

var location = module.exports = {
  _init: false,
  handler: function(){},

  init: function initLocation(throttleInterval) {
    throttleInterval = throttleInterval || 10000;
    if (location._init) {
      global.logger.debug('location already init');
      return;
    }
    location._init = true;
    if (global.navigator.geolocation) {
      var geolocationCompletedSuccessfully = false;
      global.navigator.geolocation.watchPosition(throttle(function(p) {
        // don't update geo position while viewing map selection.  why? it
        // clobbers any user entered address and can cause jumpy map
        if (0 !== m.route().indexOf('/pickup') || !geolocationCompletedSuccessfully) {
          geolocationCompletedSuccessfully = true;
          m.startComputation();
          var position = {
            coords: {
              accuracy: p.coords.accuracy,
              altitude: p.coords.altitude,
              altitudeAccuracy: p.coords.altitudeAccuracy,
              heading: p.coords.heading,
              latitude: p.coords.latitude,
              longitude: p.coords.longitude,
              speed: p.coords.speed,
            },
            timestamp: p.timestamp,
          };
          global.logger.debug('geolocation info updated', position);
          location.handler(position);
          m.endComputation();
        }
        else {
          console.log('skipping geo update on pickup page');
        }
      }, throttleInterval), function(err) {
        global.logger.warn('error retrieving geolocation', err);
        // do nothing for now
        // if (!geolocationCompletedSuccessfully) {
        //   alert([
        //     'There was an error retrieving your current location.  Did you give access to location information?',
        //     'We need this information to be able to correctly route couriers to your location.',
        //     'If you did not deny access to your location, there may be a problem obtaining your infor',
        //   ].join('\n\n'));
        // }
      }, {
        maximumAge: Infinity,
      });
    }
  },
};
