'use strict';

var m = require('mithril');
var throttle = require('throttleit');

var getStreetAddress = function(results) {
  for (var i=0; i < results.length; i++) {
    var result = results[i];
    if (result.types.indexOf('street_address') > -1) {
      return result;
    }
  }
};

var SelectLocation = module.exports = {
  vm: function(data) {
    data = data || {};
    var vm = {};
    vm.onMapChanged = null;
    vm.map = null;
    vm.zoomLevel = m.prop(18);
    vm.static = m.prop(data.static || false);
    vm.address = m.prop(data.address || null);
    vm.value = m.prop(data.value || {});
    vm.setValue = function(position) {
      var latLng = new global.google.maps.LatLng(position.lat, position.lng);
      vm.value(position);
      if (vm.map) {
        vm.map.setCenter(latLng);
      }
      vm.refreshAddress();
    };
    vm.refreshAddress = function() {
      global.logger.debug('refreshing address', vm.value());
      m.startComputation();
      var geocoder = new global.google.maps.Geocoder();
      var latLng = new global.google.maps.LatLng(vm.value().lat, vm.value().lng);
      geocoder.geocode({
        latLng: latLng
      }, function (results, status) {
        if (status === global.google.maps.GeocoderStatus.OK) {
          if (results[1]) {
            global.logger.debug('all results: ', results);
            vm.address(getStreetAddress(results));
          } else {
            global.logger.warn('No results found for', latLng, results);
          }
        } else {
          global.logger.error('Geocoder failed due to: ', status, latLng, results);
        }
        m.endComputation();
      });
    };
    return vm;
  },

  config: function(vm) {
    return function(el, isInit) {
      if(global.google) {
        if (!isInit) {
          var center = new global.google.maps.LatLng(vm.value().lat, vm.value().lng);

          var mapOptions = {
            center: center,
            zoom: vm.zoomLevel(),
            zoomControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            rotateControl: false,
            scaleControl: false,
            fullscreenControl: false,
            // disableDefaultUI: true
            styles: [
              {
                featureType: 'poi',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'transit',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
            ],
          };

          var map;
          try {
            map = vm.map = new global.google.maps.Map(el, mapOptions);
          }
          catch (err) {
            global.logger.error('maps init err', err);
          }

          global.google.maps.event.addListener(map, 'center_changed', throttle(function() {
            center = map.getCenter();
            updateLocation(center);
            vm.refreshAddress();
          }, 1500));

          function updateLocation(position) {
            var latLng = new global.google.maps.LatLng(position.lat(), position.lng());
            var lat = latLng.lat();
            var lng = latLng.lng();
            global.logger.debug(latLng);
            vm.value({ lat: lat, lng: lng });
            vm.onMapChanged && vm.onMapChanged();
          }
        }
        else {
          if (vm.static()) {
            vm.map.setOptions({
              draggable: false,
              zoomControl: false,
              scrollwheel: false,
              disableDoubleClickZoom: true,
            });
          }
          else {
            vm.map.setOptions({
              draggable: true,
              zoomControl: true,
              scrollwheel: true,
              disableDoubleClickZoom: false,
            });
          }
        }
      }
    };
  },

  controller: function(vm) {
  },

  view: function(ctrl, vm) {
    return m('div.pe-fit', { config: SelectLocation.config(vm) });
  },
};
