'use strict';

var m = require('mithril');

var linkButton = module.exports = {
  controller: function() {

  },
  view: function(ctrl, args) {
    args.class = args.class || '';
    args.class = 'passenger-link-button ' + args.class;
    return m('a[href="javascript:;"]', args, args.label);
  },
};
