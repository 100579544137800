'use strict';

var assert = require('assert');
var moment = global.moment;
var m = require('mithril');

var Cart = require('../models/cart.js');
var Pickup = require('../models/pickup.js');
var Dispatch = require('../models/dispatch.js');
var Shipment = require('../models/shipment.js');

var promiseErrorHandler = require('./promise-error-handler.js');
var sortByCreate = require('../lib/sort.js').sortByCreate;

var vm = {
  SUBSCRIPTION_PREFIX: 'SP-',

  DAY_NAMES: {
    '0': 'Sunday',
    '1': 'Monday',
    '2': 'Tuesday',
    '3': 'Wednesday',
    '4': 'Thursday',
    '5': 'Friday',
    '6': 'Saturday',
  },

  hoursOfOperation: m.prop({}),
  regionZipCodes: m.prop([]),

  session: {}, // auth.session,
  cart: m.prop(null),
  pickups: m.prop([]),
  pickupShipments: m.prop([]),
  openPickup: m.prop(null),
  openDispatch: m.prop(null),

  userIsNoob: m.prop(false),

  visible: m.prop(true),

  integrations: {},
  integrationState: m.prop({}),

  cartItemCount: () => {
    return vm.cart && vm.cart() && vm.cart().items ? vm.cart().items().length : 0;
  },
  isCartEmpty: () => {
    return vm.cartItemCount() === 0;
  },
  isPickupActive: () => {
    return vm.openPickup && vm.openPickup();
  },
  isOpenForBusiness: () => {
    var dayOfWeek = moment().day().toString();
    var hoursToday = (vm.hoursOfOperation() || {})[dayOfWeek];
    if (hoursToday) {
      var hourOfDay = moment().hour();
      return hourOfDay >= hoursToday.start && hourOfDay < hoursToday.end;
    }
    else {
      return false;
    }
  },

  reset: function() {
    m.startComputation();
    vm.session = {};
    vm.cart(null);
    vm.pickups([]);
    vm.pickupShipments([]);
    vm.openPickup(null);
    vm.openDispatch(null);
    m.endComputation();
  },

  refreshPickups: function(callback) {
    callback = callback || function(){};
    vm.openPickup(null);
    vm.pickupShipments([]);
    m.startComputation();
    var pickups = vm.pickups = Pickup.list().then(function(pickups) {
      sortByCreate(pickups);
      if (pickups && pickups.length) {
        var pickup = pickups[0];
        global.logger.debug('setting open pickup', pickup);
        vm.openPickup(pickup);
        vm.pickupShipments = Shipment.list(pickup.pickupId()).then(function(_shipments) {
          return _shipments.filter(function(shp) {
            return !shp.complete();
          });
        }, promiseErrorHandler.fatal(m.endComputation));
        // if (pickup.dispatchId()) {
        //   m.startComputation();
        //   vm.openDispatch = Dispatch.read(pickup.dispatchId());
        //   vm.openDispatch.then(function(dispatch) {
        //     m.endComputation();
        //     return dispatch;
        //   }, promiseErrorHandler.fatal(m.endComputation));
        // }
        // else {
        //   // TODO: if dispatch not set, then prompt do some verification it's actually scheduled?
        // }
      }
      else {
        global.logger.debug('no open pickup found');
      }
      m.endComputation();
      return pickups;
    }, promiseErrorHandler.fatal(m.endComputation)).then(function(pickups) {
      callback(null, pickups);
      return pickups;
    }, callback);
  },

  refreshCart: function(callback) {
    callback = callback || function(){};
    m.startComputation();
    var cart = vm.cart = Cart.read().then(function(cart) {
      var cartItems = cart.items || [];
      if (cartItems.length) {
        vm.cart(cart)
      }
      else {
        global.logger.debug('no open pickup found');
      }
      m.endComputation();
      return cart;
    }, promiseErrorHandler.fatal(m.endComputation)).then(function(cart) {
      callback(null, cart);
      return cart;
    }, callback);
  },
};

var screen = module.exports = {
  vm: vm,

  create: function(def) {
    var state = null;
    return {
      controller: function(args) {
        args = args || {};
        var ctrl = this;
        // global app state
        ctrl.vmApp = screen.vm;
        // create screen state
        if (!state) {
          state = def.vm ? def.vm(ctrl, args) : {};
        }
        ctrl.vm = state;
        return def.controller.call(ctrl, args);
      },
      view: def.view,
    };
  },
};
